import { 
	AUTH_SIGNING_UP, AUTH_SIGNUP_ERROR,
	AUTH_LOGGING_IN, AUTH_LOGGED_IN, AUTH_LOGIN_ERROR,
	AUTH_VALIDATING, AUTH_VALIDATED, AUTH_LOGGED_OUT
} from '../constants';
import { UPDATE_SERVER_COMPLETED } from '../../user/reducers';
import { checkIfPremium } from '../api/utils';

const initialState = {
	user: {},
	token: {},
	errors: [],
	isPremium: false,
	isLoggedIn: false,
	isLoggingIn: false,
	isValidating: true,
	isSigningUp: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTH_LOGGING_IN:
			return {
				...state,
				isLoggingIn: true
			};

		case AUTH_SIGNING_UP:
			return {
				...state,
				isSigningUp: true
			};

		case AUTH_LOGGED_IN:
			return {
				...state,
				errors: [],
				user: action.data.user,
				token: action.data.token,
				isLoggingIn: false,
				isLoggedIn: !!action.data,
				isPremium: checkIfPremium(action.data.user),
			};

		case AUTH_LOGIN_ERROR:
			return {
				...state,
				errors: action.data,
				token: {}, user: {},
				isValidating: false,
				isLoggingIn: false,
				isLoggedIn: false
			};

		case AUTH_SIGNUP_ERROR:
			return {
				...state,
				errors: action.data,
				isSigningUp: false
			};

		case AUTH_VALIDATING:
			return {
				...state,
				isValidating: true
			};

		case AUTH_VALIDATED:
			return {
				...state,
				isValidating: false,
				user: action.data.user,
				token: action.data.token,
				isLoggedIn: !!action.data,
				isPremium: checkIfPremium(action.data.user),
			};

		case AUTH_LOGGED_OUT:
			return {...initialState, isValidating: false};

		case UPDATE_SERVER_COMPLETED:
			return { ...state, user: action.data };

		default:
			return state;
	}
};