import { getAllColors } from '../api';

export const COLORS = 'memories/COLORS';
export const COLORS_REQUESTED = 'memories/COLORS_REQUESTED';

const initialState = {
	data: [],
	isLoading: true
};

export default (state = initialState, action) => {
	switch (action.type) {
		case COLORS_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case COLORS:
			return {
				...state,
				data: action.data,
				isLoading: false
			};

		default:
			return state;
	}
};

export const fetchColors = () => {
	return dispatch => {
		dispatch({
			type: COLORS_REQUESTED
		});

		return getAllColors().then((colors) => {
			dispatch({
				type: COLORS,
				data: colors
			});
		});
	};
};
