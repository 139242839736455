import React from 'react';
import { get } from 'lodash';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';

import { logout } from '../../modules/auth/actions';
import Account from '../../containers/setting/account';
import Premium from '../../containers/setting/premium';
import { updateLocal, update } from '../../modules/user/reducers';
import NotificationList from '../../containers/notification/list';

class Settings extends React.Component {

	state = {
		showSignoutConfirmation: false
	};

	settingsItems = [{
		title: 'Account',
		url: 'account',
	}, {
		title: 'Premium',
		url: 'premium',
	}, {
		title: 'Notifications',
		url: 'notification',
	}, {
		url: '#',
		title: 'Sign Out',
		icon: 'sign-out',
		onClick: () => this.logout()
	}];

	logout() {
		this.setState({showSignoutConfirmation: true});
	};

	getCurrentSection () {
		return get(this.props, 'match.params.section');
	};

	renderSection () {
		const section = this.getCurrentSection();
		const comps = {
			'account': Account,
			'premium': Premium,
			'notification': NotificationList,
		};
	
		let Section = (!section) ? comps['account'] : comps[section];
		return (
			<Section { ...this.props } />
		);
	};

	componentDidMount () {
		this.props.updateLocal(this.props.user);
	};

	render () {
		const { history } = this.props;

		return (
			<Row>
				<Col md={5} className='primary-container'>
					<div className="settings-list padded">
						{ this.settingsItems.map((s) => (
							<Row 
								key={s.title}
								className={`padded ${s.disabled ? 'opacity-50' : 'clickable'}`}
								onClick={ s.onClick ? s.onClick : () => history.push(`/settings/${s.url}`) }>
								<Col xs={8}>
									{ s.title }
								</Col>
								<Col xs={4} className="text-right">
									<Icon name={s.icon || "chevron-right"}></Icon>
								</Col>
							</Row>
						)) }
					</div>
				</Col>
				
				<Col 
					md={7}
					className={this.getCurrentSection() === 'notification' ? '' : 'secondary-bg'}>
					{ this.renderSection() }
				</Col>

				<SweetAlert
					show={this.state.showSignoutConfirmation}
					showCancelButton
					cancelButtonText="Stay"
					confirmButtonText="Sign Out"
					title="Sure you want to sign out?"
					text="Once you sign out, you will have to sign in with your email and password again."
					onConfirm={ () => {
						this.setState({ showSignoutConfirmation: false });
						this.props.logout();
					} }
					onCancel={ () => this.setState({showSignoutConfirmation: false}) }
				/>
			</Row>
		);
	};
};

const mapStateToProps = state => ({
	data: state.user.data,
	isLoading: state.user.isLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
	updateLocal: (user) => dispatch(updateLocal(user)),
	update, logout
}, dispatch);

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Settings));