import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import Signup from '../../containers/auth/signup';
import Login from '../../containers/auth/login';
import JarImage from '../../images/jar.png';

class LoginPage extends React.Component {
	componentWillMount() {
		if (this.props.isLoggedIn)
			return this.props.history.push('/');
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.isLoggedIn)
			return this.props.history.push('/');
	};

	getPage = () => {
		return get(this.props, 'match.params.page') || 'signin';
	};

	render () {
		const current = this.getPage();

		return (
			<Row className='auth-page-row'>
				<Col md={6} lg={5} className='secondary-bg signin-col'>
					<Row middle="xs" className="full-height">
						<Col md={12} className="sp-25">
							<div className="lp-10">
								<a href="https://jarmemori.es">
									<img src={JarImage} className="auth-page-logo bp-20" alt="jarme logo" />
								</a>
								<p className="auth-page-links hp-20">
									<Link 
										to="/auth/signin" 
										className={`hp-5 rp-20 ${current === 'signin' && 'favorite-text'}`}>
										Sign In
									</Link>
									<Link 
										to="/auth/signup" 
										className={`hp-5 lp-20 ${current === 'signup' && 'favorite-text'}`}>
										Sign Up
									</Link>
								</p>
							</div>
							{current === 'signin' ? <Login /> : <Signup />}
						</Col>
					</Row>
				</Col>

				<Col md={6} className=''>
					<Row middle="xs" className="full-height">
						<Col md={12} className="text-right auth-page-quotes">
							<div className="bp-20">"My <span className="text-bold">memory</span> is so bad"</div>
							<div className="bp-20"><i>"How bad is it?"</i></div>
							<div className="bp-20">"How bad is what?"</div>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};
};

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(LoginPage);