import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';

import { fetchPictures, setPicturesKeyword } from '../../modules/picture/reducers';
import FileBase64 from './uploader';

class InternetPictures extends React.Component {
	imageUploaded = (file) => {
		this.props.onUpdate({ pictures: [{ style: 1, image: file.base64 }]});
	};

	render () {
		const { 
			data, keyword, isLoading,
			fetchPictures, setPicturesKeyword 
		} = this.props;

		console.log('picture data', data);
		return (
			<div className="pictures-internet-container">
				<h4 className="lp-10">
					Set A Picture
					<span className="fr text-right">
						{ keyword.length > 2 && (<Icon 
							name={isLoading ? `spinner fa-spin` : `search`} 
							className="sp-10 clickable"
							onClick={ () => (isLoading) ? null : fetchPictures(keyword) } />) }
						
						<FileBase64
							ref={(btn) => this._fileUploadBtn = btn}
							multiple={false}
							onDone={this.imageUploaded}
						/>

						<Icon 
							name='camera'
							className="sp-10 clickable"
							onClick={ () => this._fileUploadBtn.inputBtn.click() } />
					</span>
				</h4>

				<div className="input-row sp-5 bm-10">
					<input 
						type="text" 
						name="keyword"
						value={ keyword }
						placeholder="type a keyword: rain, bike etc."
						onKeyPress={ (target) => {
							if (target.charCode === 13 && keyword.length > 2) {
								fetchPictures(keyword);
							}
						} }
						onChange={ (e) => setPicturesKeyword(e.target.value) }/>
				</div>
				
				<div className="sp-5">
					{ this.props.pictures.map((picture) => (
						<div 
							className="card picture-card" 
							key={picture.url}
							onClick={ () => this.props.onUpdate({pictures: [picture]}) }>
							<Row>
								<Col xs={12}>
									<img src={ picture.url } alt=""/>
								</Col>
							</Row>
						</div>
					)) }
				</div>
			</div>
		);
	};
};

const mapStateToProps = state => ({
	pictures: state.pictures.data,
	keyword: state.pictures.keyword,
	isLoading: state.pictures.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPictures, setPicturesKeyword
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InternetPictures);