import TimePicker from 'react-times';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { saveMood, changeMood } from '../../modules/mood/reducers/editor';
import { showPremiumPopup } from '../../modules/popup/reducers';
import MoodImage from '../../components/mood/image';

import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';

class Creator extends Component {
    state = {
        timeFocused: false,
        showNotePopup: false,
    };
    
    handleTimeFocusChange = () => {
        const { timeFocused } = this.state;
        this.setState({ timeFocused: !timeFocused });
    };

    onTimeFocusChange(timeFocused) {
        // console.log(`onFocusChange: ${timeFocused}`);
        this.setState({ timeFocused });
    };

    renderTimePicker () {
        return (
            <span 
                onClick={this.handleTimeFocusChange}
                className="primary-bg-bg clickable sp-10 hp-10">
                {this.props.current.created_at.format("DD MMM, YY [at] HH:mm")}
            </span>
        );
    };

    handleMoodConfirm = (note) => {
        if (!this.props.isPremium) {
            return this.props.showPremiumPopup();
        }

        this.props.saveMood({ ...this.props.current, note })
            .then(() => this.setState({ showNotePopup: false }));
    };

    handleMoodSelection = (name, color) => {
        if (!this.props.isPremium) {
            return this.props.showPremiumPopup();
        }

        this.props.changeMood({ name, color });
        this.setState({ showNotePopup: true });
    };

    imgClasses (mood={}) {
        const { current } = this.props,
            classes = ["text-capitalize text-center clickable", `${mood.color}-text`];

        if (!current || current.name !== mood.name)
            classes.push('opacity-50');

        return classes.join(" ");
    };

    render() {
        const { availableMoods, current, isSaving, changeMood } = this.props;

        return (
            <Row className="mood-creator secondary-bg card tp-10 bp-20">
                <SweetAlert
                    type="input"
                    inputType="text"
                    showCancelButton
                    cancelButtonText="Cancel"
                    showLoaderOnConfirm={true}
                    confirmButtonText="Save Mood"
                    show={this.state.showNotePopup}
                    onConfirm={this.handleMoodConfirm}
                    title={`Save Mood: ${current.name}`}
                    onCancel={() => this.setState({showNotePopup: false})}
                    inputPlaceholder={`Describe why you're feeling ${current.name}`}
                    text="You can add a shortnote using the input below with your mood before saving it. Mood won't be saved/updated unless you click the 'Save Mood' button"
                />
                
                <Col md={12}>
                    {isSaving ? (
                        <h4 className="text-center sp-15 bp-10">Saving your mood...</h4>
                    ) : (
                        <h4 className="text-center sp-15 bp-10">
                            <TimePicker 
                                onTimeChange={({ hour, minute }) => changeMood({ created_at: current.created_at.minutes(minute).hours(hour) })}
                                time={current.created_at.format("HH:mm")}
                                trigger={this.renderTimePicker()}
                                focused={this.state.timeFocused}
                                timeFormat='HH:mm'
                                draggable={true} />
                        </h4>
                    )}
                </Col>
                <Col md={1} />
                {availableMoods.map((mood) => (
                    <Col 
                        md={2} 
                        key={mood.name}
                        className={this.imgClasses(mood)}
                        onClick={() => this.handleMoodSelection(mood.name, mood.color)} >
                        <img 
                            src={MoodImage[mood.name]}
                            className="sm-5 bm-10"
                            style={{ maxWidth: '50px' }}
                            alt={mood.name}/>

                        <div>{ mood.name }</div>
                    </Col>
                ))}
            </Row>
        );
    };
};


const mapStateToProps = state => ({
    user: state.auth.user,
    isPremium: state.auth.isPremium,
    current: state.moodEditor.current,
    isSaving: state.moodEditor.isSaving,
    availableMoods: state.moods.availableMoods,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    saveMood, showPremiumPopup, changeMood,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Creator);
