import React from 'react';
import { get } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';

import MemoryList from '../../containers/memory/list';
import MemorySingle from '../../containers/memory/single';

export default class Home extends React.Component {

	render () {
		const isSingle = !!get(this.props, 'match.params.id');

		return (
			<Row>
				<Col md={6} className='primary-container'>
					<MemoryList />
				</Col>
				
				{ isSingle && (
					<Col md={6} className='secondary-bg'>
						<MemorySingle {...this.props}/>
					</Col>
				) }
			</Row>
		);
	};
};