import React, { Component } from 'react';

class Picker extends Component {

	render () {
		return (
			<div className="card hp-15 sp-15">
                <h4>This is not available on web ... yet.</h4>
                <p>You can share your memory jars with your beloved ones from our mobile app. We will soon make this available on web too.</p>  
            </div>
		);
	};
};

export default Picker;