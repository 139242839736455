import React from 'react';
import moment from 'moment';
import { Icon } from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';

const colors = ["l", "j", "r", "i", "b", "n", "p", "primary", "danger"];

function getColorClass(location, i, type = null) {
	const colorName = colors[i % colors.length];
	if (type)
		return `${colorName}-${type}`;

	return colorName;
};

export default ({locations, onSelect}) => {
	return (
		<div className="location-list">
			{ locations.map((location, i) => {
				const colorClass = getColorClass(location, i),
					createdAt = moment(location.created_at).fromNow();

				return (
					<div 
						onClick={ () => onSelect({location}) }
						className={`tp-15 bp-20 location-card ${colorClass}-border`}
						key={location.id}>
						<Row className="bp-5">
							<Col xs={1} className="text-center">
								<Icon 
									name='map-marker' 
									className={`${colorClass}-text`}>
								</Icon>
							</Col>
							
							<Col xs={11}>
								<div className="text-capitalize bp-5 text-bold">{ location.title }</div>
								<div className="item-desc-text location-desc">{ location.place_name }</div>
							</Col>
						</Row>

						<Row>
							<Col xs={1}></Col>
							<Col xs={8}>
								<small className="text-bold"> Created { createdAt }</small>
							</Col>
							<Col xs={2} className="text-right">
								<small>
									<Icon 
										name='bookmark'
										className={`${colorClass}-text`}>
									</Icon>
									&nbsp; &nbsp;{ location.events_count }
								</small>
							</Col>
						</Row>
					</div>
				);
			}) }
		</div>
	);
};