import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import Jar from '../../components/jar/single';
import Loader from '../../components/utils/loader';
import MemoryCard from '../../components/memory/card';
import EmptyJarImage from '../../images/jar_empty.png';
import { isOwner, isMuted } from '../../modules/jar/api';
import UserProfileCard from '../../components/user/profile-card';
import { fetchJars, searchJars, clearSearch, deleteJar, muteJar, leaveJar } from '../../modules/jar/reducers/list';

class JarListPage extends React.Component {
    state = {
        isMutedSelected: null,
        isOwnerSelected: null,
        confirming: null,
        selected: null,
        tab: 0,
    };

    confirmAction (action) {
        const actions = {
            'delete': {
                confirmButtonText: 'Delete It!',
                text: "Once you delete the jar, all your shared memories will be put back in your default jar and people will not be able to see it any more",
                onCancel: () => this.setState({confirming: null}),
                onConfirm: () => {
                    const { selected } = this.state;
                    setTimeout(() => this.props.deleteJar(selected.id), 100);
                    this.setState({confirming: null, selected: null});
                }
            },
            'mute': {
                confirmButtonText: this.state.isMutedSelected ? 'Unmute' : 'Mute',
                text: this.state.isMutedSelected ? 'If unmuted, any memory posted by any member of this jar will show up on your memory feed' : `If muted, you will not see any memory from this jar on your memory feed.`,
                onCancel: () => this.setState({confirming: null}),
                onConfirm: () => {
                    this.setState({confirming: null, isMutedSelected: !this.state.isMutedSelected});
                    this.props.muteJar(this.state.selected.id, this.state.isMutedSelected);
                }
            },
            'leave': {
                confirmButtonText: 'Leave!',
                text: `Once you leave the jar, all your shared memories will be put back in your default jar and you won't be able to see any memories from this jar.`,
                onCancel: () => this.setState({confirming: null}),
                onConfirm: () => {
                    const { selected } = this.state;
                    setTimeout(() => this.props.leaveJar(selected.id), 100);
                    this.setState({confirming: null, selected: null});
                }
            },
        };

        this.setState({confirming: actions[action]});
    };

    selectJar (jar) {
        this.setState({ 
            selected: jar,
            isMutedSelected: isMuted(jar, this.props.user),
            isOwnerSelected: isOwner(jar, this.props.user),
        });
    };

    componentWillMount() {
        this.props.fetchJars();
    };

    componentDidMount() {
        if (!this.state.selected && this.props.jars.length > 0) {
            this.selectJar(this.props.jars[0]);
        }
    };

    render() {
        const { jars, isLoading, keyword } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        let { selected, tab } = this.state;
        
        return (
            <Row>
                {jars.length > 0 ? (
                    <Col md={6} className='primary-container'>
                        <Row className="sp-15 hp-20">
                            <Col xs={ 10 }>
                                <div className="input-row">
                                    <input
                                        type="text"
                                        name="keyword"
                                        value={ keyword }
                                        placeholder="type a keyword: Home, Work etc."
                                        onChange={ (e) => this.props.searchJars(e.target.value) } />
                                </div>
                            </Col>

                            <Col xs={ 1 } className="text-right">
                                { keyword.length > 2 ? (
                                    <Icon 
                                        name="remove"
                                        className="clickable tm-10" 
                                        onClick={ this.props.clearSearch }/>
                                ) : (
                                    <Icon
                                        name="remove"
                                        className="tm-10 opacity-50"
                                        title="Needs at least 2 letters for searching" />  
                                ) }
                            </Col>

                            <Col xs={ 1 } className="text-right">
                                <Link to="/jars/create" className="light-text tm-10 dib">
                                    <Icon name="plus" />
                                </Link>
                            </Col>
                        </Row>

                        <Scrollbars
                            style={{ height: '80vh' }} 
                            renderTrackHorizontal={props => <div style={{ display: 'none' }}/>}>
                            {jars.map(jar => ( 
                                <Jar
                                    onClick={this.selectJar.bind(this, jar)}
                                    isSelected={selected && selected.id === jar.id}
                                    key={jar.id}
                                    jar={jar} />
                            ))}
                        </Scrollbars>
                    </Col>
                ) : (
                    <Col md={6} className='primary-container'>
                        <div className="text-center jar-list-empty">
                            <img className="jar-list-empty-image" src={EmptyJarImage} alt="jarme logo" />
                            <h2>Your have no custom jar.</h2>
                            <h3>
                                <Link to="/jars/create" className="favorite-text">
                                    Create Jar
                                </Link>
                            </h3>
                        </div> 
                    </Col>
                )}

                {selected && (
                    <Col md={6}>
                        <Row className='top-actions tp-30 bp-25 sp-10 text-center' end="xs">
                            <Col
                                xs={1}
                                className={`clickable ${tab === 0 ? 'primary-text' : ''}`}
                                onClick={() => tab !== 0 && this.setState({tab: 0})}>
                                <Icon name="book" />
                            </Col>
                            <Col
                                xs={1}
                                className={`clickable ${tab === 1 ? 'primary-text' : ''}`}
                                onClick={() => tab !== 1 && this.setState({tab: 1})}>
                                <Icon name="user" />
                            </Col>

                            {this.state.isOwnerSelected && (
                            <Col
                                xs={1}
                                className='clickable'>
                                <Link to={`/jars/edit/${selected.id}`} className="light-text">
                                    <Icon name="edit" />
                                </Link>
                            </Col>
                            ) }
                            
                            { this.state.isOwnerSelected && (
                            <Col
                                xs={1}
                                className='clickable'
                                onClick={ this.confirmAction.bind(this, 'delete') }>
                                <Icon name="trash" />
                            </Col>
                            ) }
                            
                            { !this.state.isOwnerSelected && (
                            <Col
                                xs={1}
                                className='clickable'
                                onClick={ this.confirmAction.bind(this, 'leave') }>
                                <Icon name="sign-out" />
                            </Col>
                            ) }
                            
                            { !this.state.isOwnerSelected && (
                            <Col
                                xs={1}
                                className='clickable'
                                onClick={ this.confirmAction.bind(this, 'mute') }>
                                <Icon name={this.state.isMutedSelected ? "volume-off" : "volume-up"} />
                            </Col>
                            ) }
                        </Row>

                        <Scrollbars style={{ height: '80vh' }}>
                            {tab === 0 ? selected.events.length > 0 ? (
                                <div className="memory-list">
                                    {selected && selected.events.map(memory => (
                                        <MemoryCard
                                            onClick={() =>this.props.showMemory(memory.id)}
                                            key={memory.id}
                                            memory={memory}>
                                        </MemoryCard>
                                    ))}
                                </div>
                            ) : (
                                <div className="card sp-15 hp-15">
                                    <h4>No memories in this jar.</h4>
                                        <p>You have not put any memories here yet. Would you like to <Link to="/memories/create" className="favorite-text">create one?</Link></p>
                                </div>
                            ) : null}

                            {tab === 1 ? selected.members.length > 0 ? selected.members.map(member => (
                                <UserProfileCard user={member} key={member.id}/>
                            )) : (
                                <div className="card sp-15 hp-15">
                                    <h4>No outsider in this jar.</h4>
                                    <p>You have not added any member. Only you can see the memories in this jar.</p>
                                </div>
                            ) : null}
                        </Scrollbars>
                    </Col>
                )}

                <SweetAlert
                    show={!!this.state.confirming && selected}
                    showCancelButton
                    cancelButtonText="Never Mind"
                    confirmButtonText={this.state.confirming && this.state.confirming.confirmButtonText}
                    title="Are you sure?"
                    text={this.state.confirming && this.state.confirming.text}
                    onConfirm={this.state.confirming && this.state.confirming.onConfirm}
                    onCancel={this.state.confirming && this.state.confirming.onCancel}
                />
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    user: state.auth.user,
    jars: state.jars.data,
    keyword: state.jars.keyword,
    isLoading: state.jars.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchJars, searchJars, clearSearch, deleteJar, muteJar, leaveJar,
    showMemory: (id) => push(`/memories/show/${id}`)
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JarListPage);