export default [
  {
    name: 'Glass',
    id: 'glass',
    unicode: 'f000',
    created: 1,
    filter: ['martini', 'drink', 'bar', 'alcohol', 'liquor'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Music',
    id: 'music',
    unicode: 'f001',
    created: 1,
    filter: ['note', 'sound'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Search',
    id: 'search',
    unicode: 'f002',
    created: 1,
    filter: ['magnify', 'zoom', 'enlarge', 'bigger'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Envelope Outlined',
    id: 'envelope-o',
    unicode: 'f003',
    created: 1,
    filter: ['email', 'support', 'e-mail', 'letter', 'mail', 'notification'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Heart',
    id: 'heart',
    unicode: 'f004',
    created: 1,
    filter: ['love', 'like', 'favorite'],
    categories: ['Web Application Icons', 'Medical Icons']
  },
  {
    name: 'Star',
    id: 'star',
    unicode: 'f005',
    created: 1,
    filter: ['award', 'achievement', 'night', 'rating', 'score'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Star Outlined',
    id: 'star-o',
    unicode: 'f006',
    created: 1,
    filter: ['award', 'achievement', 'night', 'rating', 'score'],
    categories: ['Web Application Icons']
  },
  {
    name: 'User',
    id: 'user',
    unicode: 'f007',
    created: 1,
    filter: ['person', 'man', 'head', 'profile'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Film',
    id: 'film',
    unicode: 'f008',
    created: 1,
    filter: ['movie'],
    categories: ['Web Application Icons']
  },
  {
    name: 'th-large',
    id: 'th-large',
    unicode: 'f009',
    created: 1,
    filter: ['blocks', 'squares', 'boxes', 'grid'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'th',
    id: 'th',
    unicode: 'f00a',
    created: 1,
    filter: ['blocks', 'squares', 'boxes', 'grid'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'th-list',
    id: 'th-list',
    unicode: 'f00b',
    created: 1,
    filter: ['ul', 'ol', 'checklist', 'finished', 'completed', 'done', 'todo'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Check',
    id: 'check',
    unicode: 'f00c',
    created: 1,
    filter: ['checkmark',
      'done',
      'todo',
      'agree',
      'accept',
      'confirm',
      'tick',
      'ok'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Times',
    id: 'times',
    unicode: 'f00d',
    created: 1,
    aliases: ['remove', 'close'],
    filter: ['close', 'exit', 'x', 'cross'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Search Plus',
    id: 'search-plus',
    unicode: 'f00e',
    created: 1,
    filter: ['magnify', 'zoom', 'enlarge', 'bigger'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Search Minus',
    id: 'search-minus',
    unicode: 'f010',
    created: 1,
    filter: ['magnify', 'minify', 'zoom', 'smaller'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Power Off',
    id: 'power-off',
    unicode: 'f011',
    created: 1,
    filter: ['on'],
    categories: ['Web Application Icons']
  },
  {
    name: 'signal',
    id: 'signal',
    unicode: 'f012',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'cog',
    id: 'cog',
    unicode: 'f013',
    created: 1,
    filter: ['settings'],
    aliases: ['gear'],
    categories: ['Web Application Icons', 'Spinner Icons']
  },
  {
    name: 'Trash Outlined',
    id: 'trash-o',
    unicode: 'f014',
    created: 1,
    filter: ['garbage', 'delete', 'remove', 'trash', 'hide'],
    categories: ['Web Application Icons']
  },
  {
    name: 'home',
    id: 'home',
    unicode: 'f015',
    created: 1,
    filter: ['main', 'house'],
    categories: ['Web Application Icons']
  },
  {
    name: 'File Outlined',
    id: 'file-o',
    unicode: 'f016',
    created: 1,
    filter: ['new', 'page', 'pdf', 'document'],
    categories: ['Text Editor Icons', 'File Type Icons']
  },
  {
    name: 'Clock Outlined',
    id: 'clock-o',
    unicode: 'f017',
    created: 1,
    filter: ['watch', 'timer', 'late', 'timestamp'],
    categories: ['Web Application Icons']
  },
  {
    name: 'road',
    id: 'road',
    unicode: 'f018',
    created: 1,
    filter: ['street'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Download',
    id: 'download',
    unicode: 'f019',
    created: 1,
    filter: ['import'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Arrow Circle Outlined Down',
    id: 'arrow-circle-o-down',
    unicode: 'f01a',
    created: 1,
    filter: ['download'],
    categories: ['Directional Icons']
  },
  {
    name: 'Arrow Circle Outlined Up',
    id: 'arrow-circle-o-up',
    unicode: 'f01b',
    created: 1,
    categories: ['Directional Icons']
  },
  {
    name: 'inbox',
    id: 'inbox',
    unicode: 'f01c',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Play Circle Outlined',
    id: 'play-circle-o',
    unicode: 'f01d',
    created: 1,
    categories: ['Video Player Icons']
  },
  {
    name: 'Repeat',
    id: 'repeat',
    unicode: 'f01e',
    created: 1,
    filter: ['redo', 'forward'],
    aliases: ['rotate-right'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'refresh',
    id: 'refresh',
    unicode: 'f021',
    created: 1,
    filter: ['reload', 'sync'],
    categories: ['Web Application Icons', 'Spinner Icons']
  },
  {
    name: 'list-alt',
    id: 'list-alt',
    unicode: 'f022',
    created: 1,
    filter: ['ul', 'ol', 'checklist', 'finished', 'completed', 'done', 'todo'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'lock',
    id: 'lock',
    unicode: 'f023',
    created: 1,
    filter: ['protect', 'admin'],
    categories: ['Web Application Icons']
  },
  {
    name: 'flag',
    id: 'flag',
    unicode: 'f024',
    created: 1,
    filter: ['report', 'notification', 'notify'],
    categories: ['Web Application Icons']
  },
  {
    name: 'headphones',
    id: 'headphones',
    unicode: 'f025',
    created: 1,
    filter: ['sound', 'listen', 'music'],
    categories: ['Web Application Icons']
  },
  {
    name: 'volume-off',
    id: 'volume-off',
    unicode: 'f026',
    created: 1,
    filter: ['mute', 'sound', 'music'],
    categories: ['Web Application Icons']
  },
  {
    name: 'volume-down',
    id: 'volume-down',
    unicode: 'f027',
    created: 1,
    filter: ['lower', 'quieter', 'sound', 'music'],
    categories: ['Web Application Icons']
  },
  {
    name: 'volume-up',
    id: 'volume-up',
    unicode: 'f028',
    created: 1,
    filter: ['higher', 'louder', 'sound', 'music'],
    categories: ['Web Application Icons']
  },
  {
    name: 'qrcode',
    id: 'qrcode',
    unicode: 'f029',
    created: 1,
    filter: ['scan'],
    categories: ['Web Application Icons']
  },
  {
    name: 'barcode',
    id: 'barcode',
    unicode: 'f02a',
    created: 1,
    filter: ['scan'],
    categories: ['Web Application Icons']
  },
  {
    name: 'tag',
    id: 'tag',
    unicode: 'f02b',
    created: 1,
    filter: ['label'],
    categories: ['Web Application Icons']
  },
  {
    name: 'tags',
    id: 'tags',
    unicode: 'f02c',
    created: 1,
    filter: ['labels'],
    categories: ['Web Application Icons']
  },
  {
    name: 'book',
    id: 'book',
    unicode: 'f02d',
    created: 1,
    filter: ['read', 'documentation'],
    categories: ['Web Application Icons']
  },
  {
    name: 'bookmark',
    id: 'bookmark',
    unicode: 'f02e',
    created: 1,
    filter: ['save'],
    categories: ['Web Application Icons']
  },
  {
    name: 'print',
    id: 'print',
    unicode: 'f02f',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'camera',
    id: 'camera',
    unicode: 'f030',
    created: 1,
    filter: ['photo', 'picture', 'record'],
    categories: ['Web Application Icons']
  },
  {
    name: 'font',
    id: 'font',
    unicode: 'f031',
    created: 1,
    filter: ['text'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'bold',
    id: 'bold',
    unicode: 'f032',
    created: 1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'italic',
    id: 'italic',
    unicode: 'f033',
    created: 1,
    filter: ['italics'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'text-height',
    id: 'text-height',
    unicode: 'f034',
    created: 1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'text-width',
    id: 'text-width',
    unicode: 'f035',
    created: 1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'align-left',
    id: 'align-left',
    unicode: 'f036',
    created: 1,
    filter: ['text'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'align-center',
    id: 'align-center',
    unicode: 'f037',
    created: 1,
    filter: ['middle', 'text'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'align-right',
    id: 'align-right',
    unicode: 'f038',
    created: 1,
    filter: ['text'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'align-justify',
    id: 'align-justify',
    unicode: 'f039',
    created: 1,
    filter: ['text'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'list',
    id: 'list',
    unicode: 'f03a',
    created: 1,
    filter: ['ul', 'ol', 'checklist', 'finished', 'completed', 'done', 'todo'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Outdent',
    id: 'outdent',
    unicode: 'f03b',
    created: 1,
    aliases: ['dedent'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Indent',
    id: 'indent',
    unicode: 'f03c',
    created: 1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'Video Camera',
    id: 'video-camera',
    unicode: 'f03d',
    created: 1,
    filter: ['film', 'movie', 'record'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Picture Outlined',
    id: 'picture-o',
    unicode: 'f03e',
    created: 1,
    aliases: ['photo', 'image'],
    categories: ['Web Application Icons']
  },
  {
    name: 'pencil',
    id: 'pencil',
    unicode: 'f040',
    created: 1,
    filter: ['write', 'edit', 'update'],
    categories: ['Web Application Icons']
  },
  {
    name: 'map-marker',
    id: 'map-marker',
    unicode: 'f041',
    created: 1,
    filter: ['map',
      'pin',
      'location',
      'coordinates',
      'localize',
      'address',
      'travel',
      'where',
      'place'],
    categories: ['Web Application Icons']
  },
  {
    name: 'adjust',
    id: 'adjust',
    unicode: 'f042',
    created: 1,
    filter: ['contrast'],
    categories: ['Web Application Icons']
  },
  {
    name: 'tint',
    id: 'tint',
    unicode: 'f043',
    created: 1,
    filter: ['raindrop', 'waterdrop', 'drop', 'droplet'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Pencil Square Outlined',
    id: 'pencil-square-o',
    unicode: 'f044',
    created: 1,
    filter: ['write', 'edit', 'update'],
    aliases: ['edit'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Share Square Outlined',
    id: 'share-square-o',
    unicode: 'f045',
    created: 1,
    filter: ['social', 'send'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Check Square Outlined',
    id: 'check-square-o',
    unicode: 'f046',
    created: 1,
    filter: ['todo', 'done', 'agree', 'accept', 'confirm', 'ok'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Arrows',
    id: 'arrows',
    unicode: 'f047',
    created: 1,
    filter: ['move', 'reorder', 'resize'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'step-backward',
    id: 'step-backward',
    unicode: 'f048',
    created: 1,
    filter: ['rewind', 'previous', 'beginning', 'start', 'first'],
    categories: ['Video Player Icons']
  },
  {
    name: 'fast-backward',
    id: 'fast-backward',
    unicode: 'f049',
    created: 1,
    filter: ['rewind', 'previous', 'beginning', 'start', 'first'],
    categories: ['Video Player Icons']
  },
  {
    name: 'backward',
    id: 'backward',
    unicode: 'f04a',
    created: 1,
    filter: ['rewind', 'previous'],
    categories: ['Video Player Icons']
  },
  {
    name: 'play',
    id: 'play',
    unicode: 'f04b',
    created: 1,
    filter: ['start', 'playing', 'music', 'sound'],
    categories: ['Video Player Icons']
  },
  {
    name: 'pause',
    id: 'pause',
    unicode: 'f04c',
    created: 1,
    filter: ['wait'],
    categories: ['Video Player Icons']
  },
  {
    name: 'stop',
    id: 'stop',
    unicode: 'f04d',
    created: 1,
    filter: ['block', 'box', 'square'],
    categories: ['Video Player Icons']
  },
  {
    name: 'forward',
    id: 'forward',
    unicode: 'f04e',
    created: 1,
    filter: ['forward', 'next'],
    categories: ['Video Player Icons']
  },
  {
    name: 'fast-forward',
    id: 'fast-forward',
    unicode: 'f050',
    created: 1,
    filter: ['next', 'end', 'last'],
    categories: ['Video Player Icons']
  },
  {
    name: 'step-forward',
    id: 'step-forward',
    unicode: 'f051',
    created: 1,
    filter: ['next', 'end', 'last'],
    categories: ['Video Player Icons']
  },
  {
    name: 'eject',
    id: 'eject',
    unicode: 'f052',
    created: 1,
    categories: ['Video Player Icons']
  },
  {
    name: 'chevron-left',
    id: 'chevron-left',
    unicode: 'f053',
    created: 1,
    filter: ['bracket', 'previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'chevron-right',
    id: 'chevron-right',
    unicode: 'f054',
    created: 1,
    filter: ['bracket', 'next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'Plus Circle',
    id: 'plus-circle',
    unicode: 'f055',
    created: 1,
    filter: ['add', 'new', 'create', 'expand'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Minus Circle',
    id: 'minus-circle',
    unicode: 'f056',
    created: 1,
    filter: ['delete', 'remove', 'trash', 'hide'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Times Circle',
    id: 'times-circle',
    unicode: 'f057',
    created: 1,
    filter: ['close', 'exit', 'x'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Check Circle',
    id: 'check-circle',
    unicode: 'f058',
    created: 1,
    filter: ['todo', 'done', 'agree', 'accept', 'confirm', 'ok'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Question Circle',
    id: 'question-circle',
    unicode: 'f059',
    filter: ['help', 'information', 'unknown', 'support'],
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Info Circle',
    id: 'info-circle',
    unicode: 'f05a',
    created: 1,
    filter: ['help', 'information', 'more', 'details'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Crosshairs',
    id: 'crosshairs',
    unicode: 'f05b',
    created: 1,
    filter: ['picker'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Times Circle Outlined',
    id: 'times-circle-o',
    unicode: 'f05c',
    created: 1,
    filter: ['close', 'exit', 'x'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Check Circle Outlined',
    id: 'check-circle-o',
    unicode: 'f05d',
    created: 1,
    filter: ['todo', 'done', 'agree', 'accept', 'confirm', 'ok'],
    categories: ['Web Application Icons']
  },
  {
    name: 'ban',
    id: 'ban',
    unicode: 'f05e',
    created: 1,
    filter: ['delete',
      'remove',
      'trash',
      'hide',
      'block',
      'stop',
      'abort',
      'cancel'],
    categories: ['Web Application Icons']
  },
  {
    name: 'arrow-left',
    id: 'arrow-left',
    unicode: 'f060',
    created: 1,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'arrow-right',
    id: 'arrow-right',
    unicode: 'f061',
    created: 1,
    filter: ['next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'arrow-up',
    id: 'arrow-up',
    unicode: 'f062',
    created: 1,
    categories: ['Directional Icons']
  },
  {
    name: 'arrow-down',
    id: 'arrow-down',
    unicode: 'f063',
    created: 1,
    filter: ['download'],
    categories: ['Directional Icons']
  },
  {
    name: 'Share',
    id: 'share',
    unicode: 'f064',
    created: 1,
    aliases: ['mail-forward'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Expand',
    id: 'expand',
    unicode: 'f065',
    created: 1,
    filter: ['enlarge', 'bigger', 'resize'],
    categories: ['Video Player Icons']
  },
  {
    name: 'Compress',
    id: 'compress',
    unicode: 'f066',
    created: 1,
    filter: ['collapse', 'combine', 'contract', 'merge', 'smaller'],
    categories: ['Video Player Icons']
  },
  {
    name: 'plus',
    id: 'plus',
    unicode: 'f067',
    created: 1,
    filter: ['add', 'new', 'create', 'expand'],
    categories: ['Web Application Icons']
  },
  {
    name: 'minus',
    id: 'minus',
    unicode: 'f068',
    created: 1,
    filter: ['hide', 'minify', 'delete', 'remove', 'trash', 'hide', 'collapse'],
    categories: ['Web Application Icons']
  },
  {
    name: 'asterisk',
    id: 'asterisk',
    unicode: 'f069',
    created: 1,
    filter: ['details'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Exclamation Circle',
    id: 'exclamation-circle',
    unicode: 'f06a',
    created: 1,
    filter: ['warning', 'error', 'problem', 'notification', 'alert'],
    categories: ['Web Application Icons']
  },
  {
    name: 'gift',
    id: 'gift',
    unicode: 'f06b',
    created: 1,
    filter: ['present'],
    categories: ['Web Application Icons']
  },
  {
    name: 'leaf',
    id: 'leaf',
    unicode: 'f06c',
    created: 1,
    filter: ['eco', 'nature'],
    categories: ['Web Application Icons']
  },
  {
    name: 'fire',
    id: 'fire',
    unicode: 'f06d',
    created: 1,
    filter: ['flame', 'hot', 'popular'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Eye',
    id: 'eye',
    unicode: 'f06e',
    created: 1,
    filter: ['show', 'visible', 'views'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Eye Slash',
    id: 'eye-slash',
    unicode: 'f070',
    created: 1,
    filter: ['toggle', 'show', 'hide', 'visible', 'visiblity', 'views'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Exclamation Triangle',
    id: 'exclamation-triangle',
    unicode: 'f071',
    created: 1,
    filter: ['warning', 'error', 'problem', 'notification', 'alert'],
    aliases: ['warning'],
    categories: ['Web Application Icons']
  },
  {
    name: 'plane',
    id: 'plane',
    unicode: 'f072',
    created: 1,
    filter: ['travel',
      'trip',
      'location',
      'destination',
      'airplane',
      'fly',
      'mode'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'calendar',
    id: 'calendar',
    unicode: 'f073',
    created: 1,
    filter: ['date', 'time', 'when', 'event'],
    categories: ['Web Application Icons']
  },
  {
    name: 'random',
    id: 'random',
    unicode: 'f074',
    created: 1,
    filter: ['sort', 'shuffle'],
    categories: ['Web Application Icons', 'Video Player Icons']
  },
  {
    name: 'comment',
    id: 'comment',
    unicode: 'f075',
    created: 1,
    filter: ['speech',
      'notification',
      'note',
      'chat',
      'bubble',
      'feedback',
      'message',
      'texting',
      'sms'],
    categories: ['Web Application Icons']
  },
  {
    name: 'magnet',
    id: 'magnet',
    unicode: 'f076',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'chevron-up',
    id: 'chevron-up',
    unicode: 'f077',
    created: 1,
    categories: ['Directional Icons']
  },
  {
    name: 'chevron-down',
    id: 'chevron-down',
    unicode: 'f078',
    created: 1,
    categories: ['Directional Icons']
  },
  {
    name: 'retweet',
    id: 'retweet',
    unicode: 'f079',
    created: 1,
    filter: ['refresh', 'reload', 'share'],
    categories: ['Web Application Icons']
  },
  {
    name: 'shopping-cart',
    id: 'shopping-cart',
    unicode: 'f07a',
    created: 1,
    filter: ['checkout', 'buy', 'purchase', 'payment'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Folder',
    id: 'folder',
    unicode: 'f07b',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Folder Open',
    id: 'folder-open',
    unicode: 'f07c',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Arrows Vertical',
    id: 'arrows-v',
    unicode: 'f07d',
    created: 1,
    filter: ['resize'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Arrows Horizontal',
    id: 'arrows-h',
    unicode: 'f07e',
    created: 1,
    filter: ['resize'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Bar Chart',
    id: 'bar-chart',
    unicode: 'f080',
    created: 1,
    aliases: ['bar-chart-o'],
    filter: ['graph', 'analytics'],
    categories: ['Web Application Icons', 'Chart Icons']
  },
  {
    name: 'Twitter Square',
    id: 'twitter-square',
    unicode: 'f081',
    created: 1,
    filter: ['tweet', 'social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'Facebook Square',
    id: 'facebook-square',
    unicode: 'f082',
    created: 1,
    filter: ['social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'camera-retro',
    id: 'camera-retro',
    unicode: 'f083',
    created: 1,
    filter: ['photo', 'picture', 'record'],
    categories: ['Web Application Icons']
  },
  {
    name: 'key',
    id: 'key',
    unicode: 'f084',
    created: 1,
    filter: ['unlock', 'password'],
    categories: ['Web Application Icons']
  },
  {
    name: 'cogs',
    id: 'cogs',
    unicode: 'f085',
    created: 1,
    aliases: ['gears'],
    filter: ['settings'],
    categories: ['Web Application Icons']
  },
  {
    name: 'comments',
    id: 'comments',
    unicode: 'f086',
    created: 1,
    filter: ['conversation',
      'notification',
      'notes',
      'message',
      'texting',
      'sms'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Thumbs Up Outlined',
    id: 'thumbs-o-up',
    unicode: 'f087',
    created: 1,
    filter: ['like', 'approve', 'favorite', 'agree', 'hand'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Thumbs Down Outlined',
    id: 'thumbs-o-down',
    unicode: 'f088',
    created: 1,
    filter: ['dislike', 'disapprove', 'disagree', 'hand'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'star-half',
    id: 'star-half',
    unicode: 'f089',
    created: 1,
    filter: ['award', 'achievement', 'rating', 'score'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Heart Outlined',
    id: 'heart-o',
    unicode: 'f08a',
    created: 1,
    filter: ['love', 'like', 'favorite'],
    categories: ['Web Application Icons', 'Medical Icons']
  },
  {
    name: 'Sign Out',
    id: 'sign-out',
    unicode: 'f08b',
    created: 1,
    filter: ['log out', 'logout', 'leave', 'exit', 'arrow'],
    categories: ['Web Application Icons']
  },
  {
    name: 'LinkedIn Square',
    id: 'linkedin-square',
    unicode: 'f08c',
    created: 1,
    categories: ['Brand Icons']
  },
  {
    name: 'Thumb Tack',
    id: 'thumb-tack',
    unicode: 'f08d',
    created: 1,
    filter: ['marker', 'pin', 'location', 'coordinates'],
    categories: ['Web Application Icons']
  },
  {
    name: 'External Link',
    id: 'external-link',
    unicode: 'f08e',
    created: 1,
    filter: ['open', 'new'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Sign In',
    id: 'sign-in',
    unicode: 'f090',
    created: 1,
    filter: ['enter',
      'join',
      'log in',
      'login',
      'sign up',
      'sign in',
      'signin',
      'signup',
      'arrow'],
    categories: ['Web Application Icons']
  },
  {
    name: 'trophy',
    id: 'trophy',
    unicode: 'f091',
    created: 1,
    filter: ['award', 'achievement', 'winner', 'game'],
    categories: ['Web Application Icons']
  },
  {
    name: 'GitHub Square',
    id: 'github-square',
    unicode: 'f092',
    created: 1,
    url: 'github.com/logos',
    filter: ['octocat'],
    categories: ['Brand Icons']
  },
  {
    name: 'Upload',
    id: 'upload',
    unicode: 'f093',
    created: 1,
    filter: ['import'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Lemon Outlined',
    id: 'lemon-o',
    unicode: 'f094',
    created: 1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Phone',
    id: 'phone',
    unicode: 'f095',
    created: 2,
    filter: ['call', 'voice', 'number', 'support', 'earphone'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Square Outlined',
    id: 'square-o',
    unicode: 'f096',
    created: 2,
    filter: ['block', 'square', 'box'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Bookmark Outlined',
    id: 'bookmark-o',
    unicode: 'f097',
    created: 2,
    filter: ['save'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Phone Square',
    id: 'phone-square',
    unicode: 'f098',
    created: 2,
    filter: ['call', 'voice', 'number', 'support'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Twitter',
    id: 'twitter',
    unicode: 'f099',
    created: 2,
    filter: ['tweet', 'social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'Facebook',
    id: 'facebook',
    unicode: 'f09a',
    created: 2,
    aliases: ['facebook-f'],
    filter: ['social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'GitHub',
    id: 'github',
    unicode: 'f09b',
    created: 2,
    url: 'github.com/logos',
    filter: ['octocat'],
    categories: ['Brand Icons']
  },
  {
    name: 'unlock',
    id: 'unlock',
    unicode: 'f09c',
    created: 2,
    filter: ['protect', 'admin', 'password', 'lock'],
    categories: ['Web Application Icons']
  },
  {
    name: 'credit-card',
    id: 'credit-card',
    unicode: 'f09d',
    created: 2,
    filter: ['money', 'buy', 'debit', 'checkout', 'purchase', 'payment'],
    categories: ['Web Application Icons', 'Payment Icons']
  },
  {
    name: 'rss',
    id: 'rss',
    unicode: 'f09e',
    created: 2,
    filter: ['blog'],
    aliases: ['feed'],
    categories: ['Web Application Icons']
  },
  {
    name: 'HDD',
    id: 'hdd-o',
    unicode: 'f0a0',
    created: 2,
    filter: ['harddrive', 'hard drive', 'storage', 'save'],
    categories: ['Web Application Icons']
  },
  {
    name: 'bullhorn',
    id: 'bullhorn',
    unicode: 'f0a1',
    created: 2,
    filter: ['announcement', 'share', 'broadcast', 'louder'],
    categories: ['Web Application Icons']
  },
  {
    name: 'bell',
    id: 'bell',
    unicode: 'f0f3',
    created: 2,
    filter: ['alert', 'reminder', 'notification'],
    categories: ['Web Application Icons']
  },
  {
    name: 'certificate',
    id: 'certificate',
    unicode: 'f0a3',
    created: 2,
    filter: ['badge', 'star'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Hand Outlined Right',
    id: 'hand-o-right',
    unicode: 'f0a4',
    created: 2,
    filter: ['point', 'right', 'next', 'forward'],
    categories: ['Directional Icons', 'Hand Icons']
  },
  {
    name: 'Hand Outlined Left',
    id: 'hand-o-left',
    unicode: 'f0a5',
    created: 2,
    filter: ['point', 'left', 'previous', 'back'],
    categories: ['Directional Icons', 'Hand Icons']
  },
  {
    name: 'Hand Outlined Up',
    id: 'hand-o-up',
    unicode: 'f0a6',
    created: 2,
    filter: ['point'],
    categories: ['Directional Icons', 'Hand Icons']
  },
  {
    name: 'Hand Outlined Down',
    id: 'hand-o-down',
    unicode: 'f0a7',
    created: 2,
    filter: ['point'],
    categories: ['Directional Icons', 'Hand Icons']
  },
  {
    name: 'Arrow Circle Left',
    id: 'arrow-circle-left',
    unicode: 'f0a8',
    created: 2,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'Arrow Circle Right',
    id: 'arrow-circle-right',
    unicode: 'f0a9',
    created: 2,
    filter: ['next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'Arrow Circle Up',
    id: 'arrow-circle-up',
    unicode: 'f0aa',
    created: 2,
    categories: ['Directional Icons']
  },
  {
    name: 'Arrow Circle Down',
    id: 'arrow-circle-down',
    unicode: 'f0ab',
    created: 2,
    filter: ['download'],
    categories: ['Directional Icons']
  },
  {
    name: 'Globe',
    id: 'globe',
    unicode: 'f0ac',
    created: 2,
    filter: ['world',
      'planet',
      'map',
      'place',
      'travel',
      'earth',
      'global',
      'translate',
      'all',
      'language',
      'localize',
      'location',
      'coordinates',
      'country'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Wrench',
    id: 'wrench',
    unicode: 'f0ad',
    created: 2,
    filter: ['settings', 'fix', 'update'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Tasks',
    id: 'tasks',
    unicode: 'f0ae',
    created: 2,
    filter: ['progress', 'loading', 'downloading', 'downloads', 'settings'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Filter',
    id: 'filter',
    unicode: 'f0b0',
    created: 2,
    filter: ['funnel', 'options'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Briefcase',
    id: 'briefcase',
    unicode: 'f0b1',
    created: 2,
    filter: ['work', 'business', 'office', 'luggage', 'bag'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Arrows Alt',
    id: 'arrows-alt',
    unicode: 'f0b2',
    created: 2,
    filter: ['expand',
      'enlarge',
      'fullscreen',
      'bigger',
      'move',
      'reorder',
      'resize'],
    categories: ['Video Player Icons', 'Directional Icons']
  },
  {
    name: 'Users',
    id: 'users',
    unicode: 'f0c0',
    created: 2,
    filter: ['people', 'profiles', 'persons'],
    aliases: ['group'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Link',
    id: 'link',
    unicode: 'f0c1',
    created: 2,
    filter: ['chain'],
    aliases: ['chain'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Cloud',
    id: 'cloud',
    filter: ['save'],
    unicode: 'f0c2',
    created: 2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Flask',
    id: 'flask',
    unicode: 'f0c3',
    created: 2,
    filter: ['science', 'beaker', 'experimental', 'labs'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Scissors',
    id: 'scissors',
    unicode: 'f0c4',
    created: 2,
    aliases: ['cut'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Files Outlined',
    id: 'files-o',
    unicode: 'f0c5',
    created: 2,
    filter: ['duplicate'],
    aliases: ['copy'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Paperclip',
    id: 'paperclip',
    unicode: 'f0c6',
    created: 2,
    filter: ['attachment'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Floppy Outlined',
    id: 'floppy-o',
    unicode: 'f0c7',
    created: 2,
    aliases: ['save'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Square',
    id: 'square',
    unicode: 'f0c8',
    created: 2,
    filter: ['block', 'box'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Bars',
    id: 'bars',
    unicode: 'f0c9',
    created: 2,
    aliases: ['navicon', 'reorder'],
    filter: ['menu',
      'drag',
      'reorder',
      'settings',
      'list',
      'ul',
      'ol',
      'checklist',
      'todo',
      'list',
      'hamburger'],
    categories: ['Web Application Icons']
  },
  {
    name: 'list-ul',
    id: 'list-ul',
    unicode: 'f0ca',
    created: 2,
    filter: ['ul', 'ol', 'checklist', 'todo', 'list'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'list-ol',
    id: 'list-ol',
    unicode: 'f0cb',
    created: 2,
    filter: ['ul', 'ol', 'checklist', 'list', 'todo', 'list', 'numbers'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Strikethrough',
    id: 'strikethrough',
    unicode: 'f0cc',
    created: 2,
    categories: ['Text Editor Icons']
  },
  {
    name: 'Underline',
    id: 'underline',
    unicode: 'f0cd',
    created: 2,
    categories: ['Text Editor Icons']
  },
  {
    name: 'table',
    id: 'table',
    unicode: 'f0ce',
    created: 2,
    filter: ['data', 'excel', 'spreadsheet'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'magic',
    id: 'magic',
    unicode: 'f0d0',
    created: 2,
    filter: ['wizard', 'automatic', 'autocomplete'],
    categories: ['Web Application Icons']
  },
  {
    name: 'truck',
    id: 'truck',
    unicode: 'f0d1',
    created: 2,
    filter: ['shipping'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Pinterest',
    id: 'pinterest',
    unicode: 'f0d2',
    created: 2,
    categories: ['Brand Icons']
  },
  {
    name: 'Pinterest Square',
    id: 'pinterest-square',
    unicode: 'f0d3',
    created: 2,
    categories: ['Brand Icons']
  },
  {
    name: 'Google Plus Square',
    id: 'google-plus-square',
    unicode: 'f0d4',
    created: 2,
    filter: ['social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'Google Plus',
    id: 'google-plus',
    unicode: 'f0d5',
    created: 2,
    filter: ['social network'],
    categories: ['Brand Icons']
  },
  {
    name: 'Money',
    id: 'money',
    unicode: 'f0d6',
    created: 2,
    filter: ['cash', 'money', 'buy', 'checkout', 'purchase', 'payment'],
    categories: ['Web Application Icons', 'Currency Icons']
  },
  {
    name: 'Caret Down',
    id: 'caret-down',
    unicode: 'f0d7',
    created: 2,
    filter: ['more', 'dropdown', 'menu', 'triangle down'],
    categories: ['Directional Icons']
  },
  {
    name: 'Caret Up',
    id: 'caret-up',
    unicode: 'f0d8',
    created: 2,
    filter: ['triangle up'],
    categories: ['Directional Icons']
  },
  {
    name: 'Caret Left',
    id: 'caret-left',
    unicode: 'f0d9',
    created: 2,
    filter: ['previous', 'back', 'triangle left'],
    categories: ['Directional Icons']
  },
  {
    name: 'Caret Right',
    id: 'caret-right',
    unicode: 'f0da',
    created: 2,
    filter: ['next', 'forward', 'triangle right'],
    categories: ['Directional Icons']
  },
  {
    name: 'Columns',
    id: 'columns',
    unicode: 'f0db',
    created: 2,
    filter: ['split', 'panes'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Sort',
    id: 'sort',
    unicode: 'f0dc',
    created: 2,
    filter: ['order'],
    aliases: ['unsorted'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Descending',
    id: 'sort-desc',
    unicode: 'f0dd',
    created: 2,
    filter: ['dropdown', 'more', 'menu'],
    aliases: ['sort-down'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Ascending',
    id: 'sort-asc',
    unicode: 'f0de',
    created: 2,
    aliases: ['sort-up'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Envelope',
    id: 'envelope',
    unicode: 'f0e0',
    created: 2,
    filter: ['email', 'e-mail', 'letter', 'support', 'mail', 'notification'],
    categories: ['Web Application Icons']
  },
  {
    name: 'LinkedIn',
    id: 'linkedin',
    unicode: 'f0e1',
    created: 2,
    categories: ['Brand Icons']
  },
  {
    name: 'Undo',
    id: 'undo',
    unicode: 'f0e2',
    created: 2,
    filter: ['back'],
    aliases: ['rotate-left'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Gavel',
    id: 'gavel',
    unicode: 'f0e3',
    created: 2,
    label: ['judge', 'lawyer', 'opinion'],
    aliases: ['legal'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Tachometer',
    id: 'tachometer',
    unicode: 'f0e4',
    created: 2,
    label: ['speedometer', 'fast'],
    aliases: ['dashboard'],
    categories: ['Web Application Icons']
  },
  {
    name: 'comment-o',
    id: 'comment-o',
    unicode: 'f0e5',
    created: 2,
    filter: ['speech',
      'notification',
      'note',
      'chat',
      'bubble',
      'feedback',
      'message',
      'texting',
      'sms'],
    categories: ['Web Application Icons']
  },
  {
    name: 'comments-o',
    id: 'comments-o',
    unicode: 'f0e6',
    created: 2,
    filter: ['conversation',
      'notification',
      'notes',
      'message',
      'texting',
      'sms'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Lightning Bolt',
    id: 'bolt',
    unicode: 'f0e7',
    created: 2,
    filter: ['lightning', 'weather'],
    aliases: ['flash'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Sitemap',
    id: 'sitemap',
    unicode: 'f0e8',
    created: 2,
    filter: ['directory', 'hierarchy', 'organization'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Umbrella',
    id: 'umbrella',
    unicode: 'f0e9',
    created: 2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Clipboard',
    id: 'clipboard',
    unicode: 'f0ea',
    created: 2,
    filter: ['copy'],
    aliases: ['paste'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Lightbulb Outlined',
    id: 'lightbulb-o',
    unicode: 'f0eb',
    created: 3,
    filter: ['idea', 'inspiration'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Exchange',
    id: 'exchange',
    unicode: 'f0ec',
    created: 3,
    filter: ['transfer', 'arrows', 'arrow'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Cloud Download',
    id: 'cloud-download',
    unicode: 'f0ed',
    created: 3,
    filter: ['import'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Cloud Upload',
    id: 'cloud-upload',
    unicode: 'f0ee',
    created: 3,
    filter: ['import'],
    categories: ['Web Application Icons']
  },
  {
    name: 'user-md',
    id: 'user-md',
    unicode: 'f0f0',
    created: 2,
    filter: ['doctor', 'profile', 'medical', 'nurse'],
    categories: ['Medical Icons']
  },
  {
    name: 'Stethoscope',
    id: 'stethoscope',
    unicode: 'f0f1',
    created: 3,
    categories: ['Medical Icons']
  },
  {
    name: 'Suitcase',
    id: 'suitcase',
    unicode: 'f0f2',
    created: 3,
    filter: ['trip', 'luggage', 'travel', 'move', 'baggage'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Bell Outlined',
    id: 'bell-o',
    unicode: 'f0a2',
    created: 3,
    filter: ['alert', 'reminder', 'notification'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Coffee',
    id: 'coffee',
    unicode: 'f0f4',
    created: 3,
    filter: ['morning', 'mug', 'breakfast', 'tea', 'drink', 'cafe'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Cutlery',
    id: 'cutlery',
    unicode: 'f0f5',
    created: 3,
    filter: ['food', 'restaurant', 'spoon', 'knife', 'dinner', 'eat'],
    categories: ['Web Application Icons']
  },
  {
    name: 'File Text Outlined',
    id: 'file-text-o',
    unicode: 'f0f6',
    created: 3,
    filter: ['new', 'page', 'pdf', 'document'],
    categories: ['Text Editor Icons', 'File Type Icons']
  },
  {
    name: 'Building Outlined',
    id: 'building-o',
    unicode: 'f0f7',
    created: 3,
    filter: ['work', 'business', 'apartment', 'office', 'company'],
    categories: ['Web Application Icons']
  },
  {
    name: 'hospital Outlined',
    id: 'hospital-o',
    unicode: 'f0f8',
    created: 3,
    filter: ['building'],
    categories: ['Medical Icons']
  },
  {
    name: 'ambulance',
    id: 'ambulance',
    unicode: 'f0f9',
    created: 3,
    filter: ['support', 'help'],
    categories: ['Medical Icons', 'Transportation Icons']
  },
  {
    name: 'medkit',
    id: 'medkit',
    unicode: 'f0fa',
    created: 3,
    filter: ['first aid', 'firstaid', 'help', 'support', 'health'],
    categories: ['Medical Icons']
  },
  {
    name: 'fighter-jet',
    id: 'fighter-jet',
    unicode: 'f0fb',
    created: 3,
    filter: ['fly', 'plane', 'airplane', 'quick', 'fast', 'travel'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'beer',
    id: 'beer',
    unicode: 'f0fc',
    created: 3,
    filter: ['alcohol', 'stein', 'drink', 'mug', 'bar', 'liquor'],
    categories: ['Web Application Icons']
  },
  {
    name: 'H Square',
    id: 'h-square',
    unicode: 'f0fd',
    created: 3,
    filter: ['hospital', 'hotel'],
    categories: ['Medical Icons']
  },
  {
    name: 'Plus Square',
    id: 'plus-square',
    unicode: 'f0fe',
    created: 3,
    filter: ['add', 'new', 'create', 'expand'],
    categories: ['Medical Icons',
      'Web Application Icons',
      'Form Control Icons']
  },
  {
    name: 'Angle Double Left',
    id: 'angle-double-left',
    unicode: 'f100',
    created: 3,
    filter: ['laquo', 'quote', 'previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'Angle Double Right',
    id: 'angle-double-right',
    unicode: 'f101',
    created: 3,
    filter: ['raquo', 'quote', 'next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'Angle Double Up',
    id: 'angle-double-up',
    unicode: 'f102',
    created: 3,
    categories: ['Directional Icons']
  },
  {
    name: 'Angle Double Down',
    id: 'angle-double-down',
    unicode: 'f103',
    created: 3,
    categories: ['Directional Icons']
  },
  {
    name: 'angle-left',
    id: 'angle-left',
    unicode: 'f104',
    created: 3,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'angle-right',
    id: 'angle-right',
    unicode: 'f105',
    created: 3,
    filter: ['next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'angle-up',
    id: 'angle-up',
    unicode: 'f106',
    created: 3,
    categories: ['Directional Icons']
  },
  {
    name: 'angle-down',
    id: 'angle-down',
    unicode: 'f107',
    created: 3,
    categories: ['Directional Icons']
  },
  {
    name: 'Desktop',
    id: 'desktop',
    unicode: 'f108',
    created: 3,
    filter: ['monitor', 'screen', 'desktop', 'computer', 'demo', 'device'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Laptop',
    id: 'laptop',
    unicode: 'f109',
    created: 3,
    filter: ['demo', 'computer', 'device'],
    categories: ['Web Application Icons']
  },
  {
    name: 'tablet',
    id: 'tablet',
    unicode: 'f10a',
    created: 3,
    filter: ['ipad', 'device'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Mobile Phone',
    id: 'mobile',
    unicode: 'f10b',
    created: 3,
    filter: ['cell phone', 'cellphone', 'text', 'call', 'iphone', 'number'],
    aliases: ['mobile-phone'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Circle Outlined',
    id: 'circle-o',
    unicode: 'f10c',
    created: 3,
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'quote-left',
    id: 'quote-left',
    unicode: 'f10d',
    created: 3,
    categories: ['Web Application Icons']
  },
  {
    name: 'quote-right',
    id: 'quote-right',
    unicode: 'f10e',
    created: 3,
    categories: ['Web Application Icons']
  },
  {
    name: 'Spinner',
    id: 'spinner',
    unicode: 'f110',
    created: 3,
    filter: ['loading', 'progress'],
    categories: ['Web Application Icons', 'Spinner Icons']
  },
  {
    name: 'Circle',
    id: 'circle',
    unicode: 'f111',
    created: 3,
    filter: ['dot', 'notification'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Reply',
    id: 'reply',
    unicode: 'f112',
    created: 3,
    aliases: ['mail-reply'],
    categories: ['Web Application Icons']
  },
  {
    name: 'GitHub Alt',
    id: 'github-alt',
    unicode: 'f113',
    created: 3,
    url: 'github.com/logos',
    filter: ['octocat'],
    categories: ['Brand Icons']
  },
  {
    name: 'Folder Outlined',
    id: 'folder-o',
    unicode: 'f114',
    created: 3,
    categories: ['Web Application Icons']
  },
  {
    name: 'Folder Open Outlined',
    id: 'folder-open-o',
    unicode: 'f115',
    created: 3,
    categories: ['Web Application Icons']
  },
  {
    name: 'Smile Outlined',
    id: 'smile-o',
    unicode: 'f118',
    created: 3.1,
    filter: ['emoticon', 'happy', 'approve', 'satisfied', 'rating'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Frown Outlined',
    id: 'frown-o',
    unicode: 'f119',
    created: 3.1,
    filter: ['emoticon', 'sad', 'disapprove', 'rating'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Meh Outlined',
    id: 'meh-o',
    unicode: 'f11a',
    created: 3.1,
    filter: ['emoticon', 'rating', 'neutral'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Gamepad',
    id: 'gamepad',
    unicode: 'f11b',
    created: 3.1,
    filter: ['controller'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Keyboard Outlined',
    id: 'keyboard-o',
    unicode: 'f11c',
    created: 3.1,
    filter: ['type', 'input'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Flag Outlined',
    id: 'flag-o',
    unicode: 'f11d',
    created: 3.1,
    filter: ['report', 'notification'],
    categories: ['Web Application Icons']
  },
  {
    name: 'flag-checkered',
    id: 'flag-checkered',
    unicode: 'f11e',
    created: 3.1,
    filter: ['report', 'notification', 'notify'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Terminal',
    id: 'terminal',
    unicode: 'f120',
    created: 3.1,
    filter: ['command', 'prompt', 'code'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Code',
    id: 'code',
    unicode: 'f121',
    created: 3.1,
    filter: ['html', 'brackets'],
    categories: ['Web Application Icons']
  },
  {
    name: 'reply-all',
    id: 'reply-all',
    unicode: 'f122',
    created: 3.1,
    aliases: ['mail-reply-all'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Star Half Outlined',
    id: 'star-half-o',
    unicode: 'f123',
    created: 3.1,
    filter: ['award', 'achievement', 'rating', 'score'],
    aliases: ['star-half-empty', 'star-half-full'],
    categories: ['Web Application Icons']
  },
  {
    name: 'location-arrow',
    id: 'location-arrow',
    unicode: 'f124',
    created: 3.1,
    filter: ['map', 'coordinates', 'location', 'address', 'place', 'where'],
    categories: ['Web Application Icons']
  },
  {
    name: 'crop',
    id: 'crop',
    unicode: 'f125',
    created: 3.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'code-fork',
    id: 'code-fork',
    unicode: 'f126',
    created: 3.1,
    filter: ['git',
      'fork',
      'vcs',
      'svn',
      'github',
      'rebase',
      'version',
      'merge'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Chain Broken',
    id: 'chain-broken',
    unicode: 'f127',
    created: 3.1,
    filter: ['remove'],
    aliases: ['unlink'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'Question',
    id: 'question',
    unicode: 'f128',
    created: 3.1,
    filter: ['help', 'information', 'unknown', 'support'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Info',
    id: 'info',
    unicode: 'f129',
    created: 3.1,
    filter: ['help', 'information', 'more', 'details'],
    categories: ['Web Application Icons']
  },
  {
    name: 'exclamation',
    id: 'exclamation',
    unicode: 'f12a',
    created: 3.1,
    filter: ['warning', 'error', 'problem', 'notification', 'notify', 'alert'],
    categories: ['Web Application Icons']
  },
  {
    name: 'superscript',
    id: 'superscript',
    unicode: 'f12b',
    created: 3.1,
    filter: ['exponential'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'subscript',
    id: 'subscript',
    unicode: 'f12c',
    created: 3.1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'eraser',
    id: 'eraser',
    unicode: 'f12d',
    created: 3.1,
    categories: ['Text Editor Icons', 'Web Application Icons']
  },
  {
    name: 'Puzzle Piece',
    id: 'puzzle-piece',
    unicode: 'f12e',
    created: 3.1,
    filter: ['addon', 'add-on', 'section'],
    categories: ['Web Application Icons']
  },
  {
    name: 'microphone',
    id: 'microphone',
    unicode: 'f130',
    created: 3.1,
    filter: ['record', 'voice', 'sound'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Microphone Slash',
    id: 'microphone-slash',
    unicode: 'f131',
    created: 3.1,
    filter: ['record', 'voice', 'sound', 'mute'],
    categories: ['Web Application Icons']
  },
  {
    name: 'shield',
    id: 'shield',
    unicode: 'f132',
    created: 3.1,
    filter: ['award', 'achievement', 'winner'],
    categories: ['Web Application Icons']
  },
  {
    name: 'calendar-o',
    id: 'calendar-o',
    unicode: 'f133',
    created: 3.1,
    filter: ['date', 'time', 'when', 'event'],
    categories: ['Web Application Icons']
  },
  {
    name: 'fire-extinguisher',
    id: 'fire-extinguisher',
    unicode: 'f134',
    created: 3.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'rocket',
    id: 'rocket',
    unicode: 'f135',
    created: 3.1,
    filter: ['app'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'MaxCDN',
    id: 'maxcdn',
    unicode: 'f136',
    created: 3.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Chevron Circle Left',
    id: 'chevron-circle-left',
    unicode: 'f137',
    created: 3.1,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'Chevron Circle Right',
    id: 'chevron-circle-right',
    unicode: 'f138',
    created: 3.1,
    filter: ['next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'Chevron Circle Up',
    id: 'chevron-circle-up',
    unicode: 'f139',
    created: 3.1,
    categories: ['Directional Icons']
  },
  {
    name: 'Chevron Circle Down',
    id: 'chevron-circle-down',
    unicode: 'f13a',
    created: 3.1,
    filter: ['more', 'dropdown', 'menu'],
    categories: ['Directional Icons']
  },
  {
    name: 'HTML 5 Logo',
    id: 'html5',
    unicode: 'f13b',
    created: 3.1,
    code: ['code', 'html5'],
    categories: ['Brand Icons']
  },
  {
    name: 'CSS 3 Logo',
    id: 'css3',
    unicode: 'f13c',
    created: 3.1,
    filter: ['code'],
    categories: ['Brand Icons']
  },
  {
    name: 'Anchor',
    id: 'anchor',
    unicode: 'f13d',
    created: 3.1,
    filter: ['link'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Unlock Alt',
    id: 'unlock-alt',
    unicode: 'f13e',
    created: 3.1,
    filter: ['protect', 'admin', 'password', 'lock'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Bullseye',
    id: 'bullseye',
    unicode: 'f140',
    created: 3.1,
    filter: ['target'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Ellipsis Horizontal',
    id: 'ellipsis-h',
    unicode: 'f141',
    created: 3.1,
    filter: ['dots'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Ellipsis Vertical',
    id: 'ellipsis-v',
    unicode: 'f142',
    created: 3.1,
    filter: ['dots'],
    categories: ['Web Application Icons']
  },
  {
    name: 'RSS Square',
    id: 'rss-square',
    unicode: 'f143',
    created: 3.1,
    filter: ['feed', 'blog'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Play Circle',
    id: 'play-circle',
    unicode: 'f144',
    created: 3.1,
    filter: ['start', 'playing'],
    categories: ['Video Player Icons']
  },
  {
    name: 'Ticket',
    id: 'ticket',
    unicode: 'f145',
    created: 3.1,
    filter: ['movie', 'pass', 'support'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Minus Square',
    id: 'minus-square',
    unicode: 'f146',
    created: 3.1,
    filter: ['hide', 'minify', 'delete', 'remove', 'trash', 'hide', 'collapse'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Minus Square Outlined',
    id: 'minus-square-o',
    unicode: 'f147',
    created: 3.1,
    filter: ['hide', 'minify', 'delete', 'remove', 'trash', 'hide', 'collapse'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Level Up',
    id: 'level-up',
    unicode: 'f148',
    created: 3.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Level Down',
    id: 'level-down',
    unicode: 'f149',
    created: 3.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Check Square',
    id: 'check-square',
    unicode: 'f14a',
    created: 3.1,
    filter: ['checkmark', 'done', 'todo', 'agree', 'accept', 'confirm', 'ok'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Pencil Square',
    id: 'pencil-square',
    unicode: 'f14b',
    created: 3.1,
    filter: ['write', 'edit', 'update'],
    categories: ['Web Application Icons']
  },
  {
    name: 'External Link Square',
    id: 'external-link-square',
    unicode: 'f14c',
    created: 3.1,
    filter: ['open', 'new'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Share Square',
    id: 'share-square',
    unicode: 'f14d',
    created: 3.1,
    filter: ['social', 'send'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Compass',
    id: 'compass',
    unicode: 'f14e',
    created: 3.2,
    filter: ['safari', 'directory', 'menu', 'location'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Caret Square Outlined Down',
    id: 'caret-square-o-down',
    unicode: 'f150',
    created: 3.2,
    aliases: ['toggle-down'],
    filter: ['more', 'dropdown', 'menu'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Caret Square Outlined Up',
    id: 'caret-square-o-up',
    unicode: 'f151',
    created: 3.2,
    aliases: ['toggle-up'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Caret Square Outlined Right',
    id: 'caret-square-o-right',
    unicode: 'f152',
    created: 3.2,
    filter: ['next', 'forward'],
    aliases: ['toggle-right'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Euro (EUR)',
    id: 'eur',
    unicode: 'f153',
    created: 3.2,
    aliases: ['euro'],
    categories: ['Currency Icons']
  },
  {
    name: 'GBP',
    id: 'gbp',
    unicode: 'f154',
    created: 3.2,
    categories: ['Currency Icons']
  },
  {
    name: 'US Dollar',
    id: 'usd',
    unicode: 'f155',
    created: 3.2,
    aliases: ['dollar'],
    categories: ['Currency Icons']
  },
  {
    name: 'Indian Rupee (INR)',
    id: 'inr',
    unicode: 'f156',
    created: 3.2,
    aliases: ['rupee'],
    categories: ['Currency Icons']
  },
  {
    name: 'Japanese Yen (JPY)',
    id: 'jpy',
    unicode: 'f157',
    created: 3.2,
    aliases: ['cny', 'rmb', 'yen'],
    categories: ['Currency Icons']
  },
  {
    name: 'Russian Ruble (RUB)',
    id: 'rub',
    unicode: 'f158',
    created: 4,
    aliases: ['ruble', 'rouble'],
    categories: ['Currency Icons']
  },
  {
    name: 'Korean Won (KRW)',
    id: 'krw',
    unicode: 'f159',
    created: 3.2,
    aliases: ['won'],
    categories: ['Currency Icons']
  },
  {
    name: 'Bitcoin (BTC)',
    id: 'btc',
    unicode: 'f15a',
    created: 3.2,
    aliases: ['bitcoin'],
    categories: ['Currency Icons', 'Brand Icons']
  },
  {
    name: 'File',
    id: 'file',
    unicode: 'f15b',
    created: 3.2,
    filter: ['new', 'page', 'pdf', 'document'],
    categories: ['Text Editor Icons', 'File Type Icons']
  },
  {
    name: 'File Text',
    id: 'file-text',
    unicode: 'f15c',
    created: 3.2,
    filter: ['new', 'page', 'pdf', 'document'],
    categories: ['Text Editor Icons', 'File Type Icons']
  },
  {
    name: 'Sort Alpha Ascending',
    id: 'sort-alpha-asc',
    unicode: 'f15d',
    created: 3.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Alpha Descending',
    id: 'sort-alpha-desc',
    unicode: 'f15e',
    created: 3.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Amount Ascending',
    id: 'sort-amount-asc',
    unicode: 'f160',
    created: 3.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Amount Descending',
    id: 'sort-amount-desc',
    unicode: 'f161',
    created: 3.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Numeric Ascending',
    id: 'sort-numeric-asc',
    unicode: 'f162',
    created: 3.2,
    filter: ['numbers'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Sort Numeric Descending',
    id: 'sort-numeric-desc',
    unicode: 'f163',
    created: 3.2,
    filter: ['numbers'],
    categories: ['Web Application Icons']
  },
  {
    name: 'thumbs-up',
    id: 'thumbs-up',
    unicode: 'f164',
    created: 3.2,
    filter: ['like', 'favorite', 'approve', 'agree', 'hand'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'thumbs-down',
    id: 'thumbs-down',
    unicode: 'f165',
    created: 3.2,
    filter: ['dislike', 'disapprove', 'disagree', 'hand'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'YouTube Square',
    id: 'youtube-square',
    unicode: 'f166',
    created: 3.2,
    filter: ['video', 'film'],
    categories: ['Brand Icons']
  },
  {
    name: 'YouTube',
    id: 'youtube',
    unicode: 'f167',
    created: 3.2,
    filter: ['video', 'film'],
    categories: ['Brand Icons']
  },
  {
    name: 'Xing',
    id: 'xing',
    unicode: 'f168',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Xing Square',
    id: 'xing-square',
    unicode: 'f169',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'YouTube Play',
    id: 'youtube-play',
    unicode: 'f16a',
    created: 3.2,
    filter: ['start', 'playing'],
    categories: ['Brand Icons', 'Video Player Icons']
  },
  {
    name: 'Dropbox',
    id: 'dropbox',
    unicode: 'f16b',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Stack Overflow',
    id: 'stack-overflow',
    unicode: 'f16c',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Instagram',
    id: 'instagram',
    unicode: 'f16d',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Flickr',
    id: 'flickr',
    unicode: 'f16e',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'App.net',
    id: 'adn',
    unicode: 'f170',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Bitbucket',
    id: 'bitbucket',
    unicode: 'f171',
    created: 3.2,
    filter: ['git'],
    categories: ['Brand Icons']
  },
  {
    name: 'Bitbucket Square',
    id: 'bitbucket-square',
    unicode: 'f172',
    created: 3.2,
    filter: ['git'],
    categories: ['Brand Icons']
  },
  {
    name: 'Tumblr',
    id: 'tumblr',
    unicode: 'f173',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Tumblr Square',
    id: 'tumblr-square',
    unicode: 'f174',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Long Arrow Down',
    id: 'long-arrow-down',
    unicode: 'f175',
    created: 3.2,
    categories: ['Directional Icons']
  },
  {
    name: 'Long Arrow Up',
    id: 'long-arrow-up',
    unicode: 'f176',
    created: 3.2,
    categories: ['Directional Icons']
  },
  {
    name: 'Long Arrow Left',
    id: 'long-arrow-left',
    unicode: 'f177',
    created: 3.2,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'Long Arrow Right',
    id: 'long-arrow-right',
    unicode: 'f178',
    created: 3.2,
    categories: ['Directional Icons']
  },
  {
    name: 'Apple',
    id: 'apple',
    unicode: 'f179',
    created: 3.2,
    filter: ['osx'],
    categories: ['Brand Icons']
  },
  {
    name: 'Windows',
    id: 'windows',
    unicode: 'f17a',
    created: 3.2,
    filter: ['microsoft'],
    categories: ['Brand Icons']
  },
  {
    name: 'Android',
    id: 'android',
    unicode: 'f17b',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Linux',
    id: 'linux',
    unicode: 'f17c',
    created: 3.2,
    filter: ['tux'],
    categories: ['Brand Icons']
  },
  {
    name: 'Dribbble',
    id: 'dribbble',
    unicode: 'f17d',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Skype',
    id: 'skype',
    unicode: 'f17e',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Foursquare',
    id: 'foursquare',
    unicode: 'f180',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Trello',
    id: 'trello',
    unicode: 'f181',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Female',
    id: 'female',
    unicode: 'f182',
    created: 3.2,
    filter: ['woman', 'user', 'person', 'profile'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Male',
    id: 'male',
    unicode: 'f183',
    created: 3.2,
    filter: ['man', 'user', 'person', 'profile'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Gratipay (Gittip)',
    id: 'gratipay',
    unicode: 'f184',
    created: 3.2,
    aliases: ['gittip'],
    filter: ['heart', 'like', 'favorite', 'love'],
    categories: ['Brand Icons']
  },
  {
    name: 'Sun Outlined',
    id: 'sun-o',
    unicode: 'f185',
    created: 3.2,
    filter: ['weather', 'contrast', 'lighter', 'brighten', 'day'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Moon Outlined',
    id: 'moon-o',
    unicode: 'f186',
    created: 3.2,
    filter: ['night', 'darker', 'contrast'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Archive',
    id: 'archive',
    unicode: 'f187',
    created: 3.2,
    filter: ['box', 'storage'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Bug',
    id: 'bug',
    unicode: 'f188',
    created: 3.2,
    filter: ['report', 'insect'],
    categories: ['Web Application Icons']
  },
  {
    name: 'VK',
    id: 'vk',
    unicode: 'f189',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Weibo',
    id: 'weibo',
    unicode: 'f18a',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Renren',
    id: 'renren',
    unicode: 'f18b',
    created: 3.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Pagelines',
    id: 'pagelines',
    unicode: 'f18c',
    created: 4,
    filter: ['leaf', 'leaves', 'tree', 'plant', 'eco', 'nature'],
    categories: ['Brand Icons']
  },
  {
    name: 'Stack Exchange',
    id: 'stack-exchange',
    unicode: 'f18d',
    created: 4,
    categories: ['Brand Icons']
  },
  {
    name: 'Arrow Circle Outlined Right',
    id: 'arrow-circle-o-right',
    unicode: 'f18e',
    created: 4,
    filter: ['next', 'forward'],
    categories: ['Directional Icons']
  },
  {
    name: 'Arrow Circle Outlined Left',
    id: 'arrow-circle-o-left',
    unicode: 'f190',
    created: 4,
    filter: ['previous', 'back'],
    categories: ['Directional Icons']
  },
  {
    name: 'Caret Square Outlined Left',
    id: 'caret-square-o-left',
    unicode: 'f191',
    created: 4,
    filter: ['previous', 'back'],
    aliases: ['toggle-left'],
    categories: ['Web Application Icons', 'Directional Icons']
  },
  {
    name: 'Dot Circle Outlined',
    id: 'dot-circle-o',
    unicode: 'f192',
    created: 4,
    filter: ['target', 'bullseye', 'notification'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Wheelchair',
    id: 'wheelchair',
    unicode: 'f193',
    created: 4,
    filter: ['handicap', 'person', 'accessibility', 'accessibile'],
    categories: ['Web Application Icons',
      'Medical Icons',
      'Transportation Icons',
      'Accessibility Icons']
  },
  {
    name: 'Vimeo Square',
    id: 'vimeo-square',
    unicode: 'f194',
    created: 4,
    categories: ['Brand Icons']
  },
  {
    name: 'Turkish Lira (TRY)',
    id: 'try',
    unicode: 'f195',
    created: 4,
    aliases: ['turkish-lira'],
    categories: ['Currency Icons']
  },
  {
    name: 'Plus Square Outlined',
    id: 'plus-square-o',
    unicode: 'f196',
    created: 4,
    filter: ['add', 'new', 'create', 'expand'],
    categories: ['Web Application Icons', 'Form Control Icons']
  },
  {
    name: 'Space Shuttle',
    id: 'space-shuttle',
    unicode: 'f197',
    created: 4.1,
    filter: null,
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Slack Logo',
    id: 'slack',
    unicode: 'f198',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Envelope Square',
    id: 'envelope-square',
    unicode: 'f199',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'WordPress Logo',
    id: 'wordpress',
    unicode: 'f19a',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'OpenID',
    id: 'openid',
    unicode: 'f19b',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'University',
    id: 'university',
    unicode: 'f19c',
    created: 4.1,
    aliases: ['institution', 'bank'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Graduation Cap',
    id: 'graduation-cap',
    unicode: 'f19d',
    created: 4.1,
    aliases: ['mortar-board'],
    filter: ['learning', 'school', 'student'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Yahoo Logo',
    id: 'yahoo',
    unicode: 'f19e',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Google Logo',
    id: 'google',
    unicode: 'f1a0',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'reddit Logo',
    id: 'reddit',
    unicode: 'f1a1',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'reddit Square',
    id: 'reddit-square',
    unicode: 'f1a2',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'StumbleUpon Circle',
    id: 'stumbleupon-circle',
    unicode: 'f1a3',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'StumbleUpon Logo',
    id: 'stumbleupon',
    unicode: 'f1a4',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Delicious Logo',
    id: 'delicious',
    unicode: 'f1a5',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Digg Logo',
    id: 'digg',
    unicode: 'f1a6',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Pied Piper Logo',
    id: 'pied-piper',
    unicode: 'f1a7',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Pied Piper Alternate Logo',
    id: 'pied-piper-alt',
    unicode: 'f1a8',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Drupal Logo',
    id: 'drupal',
    unicode: 'f1a9',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Joomla Logo',
    id: 'joomla',
    unicode: 'f1aa',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Language',
    id: 'language',
    unicode: 'f1ab',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Fax',
    id: 'fax',
    unicode: 'f1ac',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Building',
    id: 'building',
    unicode: 'f1ad',
    created: 4.1,
    filter: ['work', 'business', 'apartment', 'office', 'company'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Child',
    id: 'child',
    unicode: 'f1ae',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Paw',
    id: 'paw',
    unicode: 'f1b0',
    created: 4.1,
    filter: ['pet'],
    categories: ['Web Application Icons']
  },
  {
    name: 'spoon',
    id: 'spoon',
    unicode: 'f1b1',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Cube',
    id: 'cube',
    unicode: 'f1b2',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Cubes',
    id: 'cubes',
    unicode: 'f1b3',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Behance',
    id: 'behance',
    unicode: 'f1b4',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Behance Square',
    id: 'behance-square',
    unicode: 'f1b5',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Steam',
    id: 'steam',
    unicode: 'f1b6',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Steam Square',
    id: 'steam-square',
    unicode: 'f1b7',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Recycle',
    id: 'recycle',
    unicode: 'f1b8',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Car',
    id: 'car',
    unicode: 'f1b9',
    created: 4.1,
    aliases: ['automobile'],
    filter: ['vehicle'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Taxi',
    id: 'taxi',
    unicode: 'f1ba',
    created: 4.1,
    aliases: ['cab'],
    filter: ['vehicle'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Tree',
    id: 'tree',
    unicode: 'f1bb',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Spotify',
    id: 'spotify',
    unicode: 'f1bc',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'deviantART',
    id: 'deviantart',
    unicode: 'f1bd',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'SoundCloud',
    id: 'soundcloud',
    unicode: 'f1be',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Database',
    id: 'database',
    unicode: 'f1c0',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'PDF File Outlined',
    id: 'file-pdf-o',
    unicode: 'f1c1',
    created: 4.1,
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Word File Outlined',
    id: 'file-word-o',
    unicode: 'f1c2',
    created: 4.1,
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Excel File Outlined',
    id: 'file-excel-o',
    unicode: 'f1c3',
    created: 4.1,
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Powerpoint File Outlined',
    id: 'file-powerpoint-o',
    unicode: 'f1c4',
    created: 4.1,
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Image File Outlined',
    id: 'file-image-o',
    unicode: 'f1c5',
    created: 4.1,
    aliases: ['file-photo-o', 'file-picture-o'],
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Archive File Outlined',
    id: 'file-archive-o',
    unicode: 'f1c6',
    created: 4.1,
    aliases: ['file-zip-o'],
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Audio File Outlined',
    id: 'file-audio-o',
    unicode: 'f1c7',
    created: 4.1,
    aliases: ['file-sound-o'],
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Video File Outlined',
    id: 'file-video-o',
    unicode: 'f1c8',
    created: 4.1,
    aliases: ['file-movie-o'],
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Code File Outlined',
    id: 'file-code-o',
    unicode: 'f1c9',
    created: 4.1,
    categories: ['Web Application Icons', 'File Type Icons']
  },
  {
    name: 'Vine',
    id: 'vine',
    unicode: 'f1ca',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Codepen',
    id: 'codepen',
    unicode: 'f1cb',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'jsFiddle',
    id: 'jsfiddle',
    unicode: 'f1cc',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Life Ring',
    id: 'life-ring',
    unicode: 'f1cd',
    created: 4.1,
    aliases: ['life-bouy', 'life-buoy', 'life-saver', 'support'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Circle Outlined Notched',
    id: 'circle-o-notch',
    unicode: 'f1ce',
    created: 4.1,
    categories: ['Web Application Icons', 'Spinner Icons']
  },
  {
    name: 'Rebel Alliance',
    id: 'rebel',
    unicode: 'f1d0',
    created: 4.1,
    aliases: ['ra'],
    categories: ['Brand Icons']
  },
  {
    name: 'Galactic Empire',
    id: 'empire',
    unicode: 'f1d1',
    created: 4.1,
    aliases: ['ge'],
    categories: ['Brand Icons']
  },
  {
    name: 'Git Square',
    id: 'git-square',
    unicode: 'f1d2',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Git',
    id: 'git',
    unicode: 'f1d3',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Hacker News',
    id: 'hacker-news',
    unicode: 'f1d4',
    created: 4.1,
    aliases: ['y-combinator-square', 'yc-square'],
    categories: ['Brand Icons']
  },
  {
    name: 'Tencent Weibo',
    id: 'tencent-weibo',
    unicode: 'f1d5',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'QQ',
    id: 'qq',
    unicode: 'f1d6',
    created: 4.1,
    categories: ['Brand Icons']
  },
  {
    name: 'Weixin (WeChat)',
    id: 'weixin',
    unicode: 'f1d7',
    created: 4.1,
    aliases: ['wechat'],
    categories: ['Brand Icons']
  },
  {
    name: 'Paper Plane',
    id: 'paper-plane',
    unicode: 'f1d8',
    created: 4.1,
    aliases: ['send'],
    filter: null,
    categories: ['Web Application Icons']
  },
  {
    name: 'Paper Plane Outlined',
    id: 'paper-plane-o',
    unicode: 'f1d9',
    created: 4.1,
    aliases: ['send-o'],
    categories: ['Web Application Icons']
  },
  {
    name: 'History',
    id: 'history',
    unicode: 'f1da',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Circle Outlined Thin',
    id: 'circle-thin',
    unicode: 'f1db',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'header',
    id: 'header',
    unicode: 'f1dc',
    created: 4.1,
    filter: ['heading'],
    categories: ['Text Editor Icons']
  },
  {
    name: 'paragraph',
    id: 'paragraph',
    unicode: 'f1dd',
    created: 4.1,
    categories: ['Text Editor Icons']
  },
  {
    name: 'Sliders',
    id: 'sliders',
    unicode: 'f1de',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Share Alt',
    id: 'share-alt',
    unicode: 'f1e0',
    created: 4.1,
    categories: ['Web Application Icons', 'Brand Icons']
  },
  {
    name: 'Share Alt Square',
    id: 'share-alt-square',
    unicode: 'f1e1',
    created: 4.1,
    categories: ['Web Application Icons', 'Brand Icons']
  },
  {
    name: 'Bomb',
    id: 'bomb',
    unicode: 'f1e2',
    created: 4.1,
    categories: ['Web Application Icons']
  },
  {
    name: 'Futbol Outlined',
    id: 'futbol-o',
    unicode: 'f1e3',
    created: 4.2,
    aliases: ['soccer-ball-o'],
    categories: ['Web Application Icons']
  },
  {
    name: 'TTY',
    id: 'tty',
    unicode: 'f1e4',
    created: 4.2,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Binoculars',
    id: 'binoculars',
    unicode: 'f1e5',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Plug',
    id: 'plug',
    unicode: 'f1e6',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Slideshare',
    id: 'slideshare',
    unicode: 'f1e7',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Twitch',
    id: 'twitch',
    unicode: 'f1e8',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Yelp',
    id: 'yelp',
    unicode: 'f1e9',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Newspaper Outlined',
    id: 'newspaper-o',
    unicode: 'f1ea',
    created: 4.2,
    filter: ['press'],
    categories: ['Web Application Icons']
  },
  {
    name: 'WiFi',
    id: 'wifi',
    unicode: 'f1eb',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Calculator',
    id: 'calculator',
    unicode: 'f1ec',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Paypal',
    id: 'paypal',
    unicode: 'f1ed',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Google Wallet',
    id: 'google-wallet',
    unicode: 'f1ee',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Visa Credit Card',
    id: 'cc-visa',
    unicode: 'f1f0',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'MasterCard Credit Card',
    id: 'cc-mastercard',
    unicode: 'f1f1',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Discover Credit Card',
    id: 'cc-discover',
    unicode: 'f1f2',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'American Express Credit Card',
    id: 'cc-amex',
    unicode: 'f1f3',
    created: 4.2,
    filter: ['amex'],
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Paypal Credit Card',
    id: 'cc-paypal',
    unicode: 'f1f4',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Stripe Credit Card',
    id: 'cc-stripe',
    unicode: 'f1f5',
    created: 4.2,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Bell Slash',
    id: 'bell-slash',
    unicode: 'f1f6',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Bell Slash Outlined',
    id: 'bell-slash-o',
    unicode: 'f1f7',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Trash',
    id: 'trash',
    unicode: 'f1f8',
    created: 4.2,
    filter: ['garbage', 'delete', 'remove', 'hide'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Copyright',
    id: 'copyright',
    unicode: 'f1f9',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'At',
    id: 'at',
    unicode: 'f1fa',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Eyedropper',
    id: 'eyedropper',
    unicode: 'f1fb',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Paint Brush',
    id: 'paint-brush',
    unicode: 'f1fc',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Birthday Cake',
    id: 'birthday-cake',
    unicode: 'f1fd',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Area Chart',
    id: 'area-chart',
    unicode: 'f1fe',
    created: 4.2,
    categories: ['Web Application Icons', 'Chart Icons'],
    filter: ['graph', 'analytics']
  },
  {
    name: 'Pie Chart',
    id: 'pie-chart',
    unicode: 'f200',
    created: 4.2,
    categories: ['Web Application Icons', 'Chart Icons'],
    filter: ['graph', 'analytics']
  },
  {
    name: 'Line Chart',
    id: 'line-chart',
    unicode: 'f201',
    created: 4.2,
    categories: ['Web Application Icons', 'Chart Icons'],
    filter: ['graph', 'analytics']
  },
  {
    name: 'last.fm',
    id: 'lastfm',
    unicode: 'f202',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'last.fm Square',
    id: 'lastfm-square',
    unicode: 'f203',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Toggle Off',
    id: 'toggle-off',
    unicode: 'f204',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Toggle On',
    id: 'toggle-on',
    unicode: 'f205',
    created: 4.2,
    categories: ['Web Application Icons']
  },
  {
    name: 'Bicycle',
    id: 'bicycle',
    unicode: 'f206',
    created: 4.2,
    filter: ['vehicle', 'bike'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Bus',
    id: 'bus',
    unicode: 'f207',
    created: 4.2,
    filter: ['vehicle'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'ioxhost',
    id: 'ioxhost',
    unicode: 'f208',
    created: 4.2,
    url: 'ioxhost.co.uk',
    categories: ['Brand Icons']
  },
  {
    name: 'AngelList',
    id: 'angellist',
    unicode: 'f209',
    created: 4.2,
    categories: ['Brand Icons']
  },
  {
    name: 'Closed Captions',
    id: 'cc',
    unicode: 'f20a',
    created: 4.2,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Shekel (ILS)',
    id: 'ils',
    unicode: 'f20b',
    created: 4.2,
    aliases: ['shekel', 'sheqel'],
    categories: ['Currency Icons']
  },
  {
    name: 'meanpath',
    id: 'meanpath',
    unicode: 'f20c',
    created: 4.2,
    url: 'meanpath.com',
    categories: ['Brand Icons']
  },
  {
    name: 'BuySellAds',
    id: 'buysellads',
    unicode: 'f20d',
    created: 4.3,
    url: 'buysellads.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Connect Develop',
    id: 'connectdevelop',
    unicode: 'f20e',
    created: 4.3,
    url: 'connectdevelop.com',
    categories: ['Brand Icons']
  },
  {
    name: 'DashCube',
    id: 'dashcube',
    unicode: 'f210',
    created: 4.3,
    url: 'dashcube.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Forumbee',
    id: 'forumbee',
    unicode: 'f211',
    created: 4.3,
    url: 'forumbee.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Leanpub',
    id: 'leanpub',
    unicode: 'f212',
    created: 4.3,
    url: 'leanpub.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Sellsy',
    id: 'sellsy',
    unicode: 'f213',
    created: 4.3,
    url: 'sellsy.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Shirts in Bulk',
    id: 'shirtsinbulk',
    unicode: 'f214',
    created: 4.3,
    url: 'shirtsinbulk.com',
    categories: ['Brand Icons']
  },
  {
    name: 'SimplyBuilt',
    id: 'simplybuilt',
    unicode: 'f215',
    created: 4.3,
    url: 'simplybuilt.com',
    categories: ['Brand Icons']
  },
  {
    name: 'skyatlas',
    id: 'skyatlas',
    unicode: 'f216',
    created: 4.3,
    url: 'skyatlas.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Add to Shopping Cart',
    id: 'cart-plus',
    unicode: 'f217',
    created: 4.3,
    filter: ['add', 'shopping'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Shopping Cart Arrow Down',
    id: 'cart-arrow-down',
    unicode: 'f218',
    created: 4.3,
    filter: ['shopping'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Diamond',
    id: 'diamond',
    unicode: 'f219',
    created: 4.3,
    filter: ['gem', 'gemstone'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Ship',
    id: 'ship',
    unicode: 'f21a',
    created: 4.3,
    filter: ['boat', 'sea'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'User Secret',
    id: 'user-secret',
    unicode: 'f21b',
    created: 4.3,
    filter: ['whisper', 'spy', 'incognito', 'privacy'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Motorcycle',
    id: 'motorcycle',
    unicode: 'f21c',
    created: 4.3,
    filter: ['vehicle', 'bike'],
    categories: ['Web Application Icons', 'Transportation Icons']
  },
  {
    name: 'Street View',
    id: 'street-view',
    unicode: 'f21d',
    created: 4.3,
    filter: ['map'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Heartbeat',
    id: 'heartbeat',
    unicode: 'f21e',
    created: 4.3,
    filter: ['ekg'],
    categories: ['Web Application Icons', 'Medical Icons']
  },
  {
    name: 'Venus',
    id: 'venus',
    unicode: 'f221',
    created: 4.3,
    filter: ['female'],
    categories: ['Gender Icons']
  },
  {
    name: 'Mars',
    id: 'mars',
    unicode: 'f222',
    created: 4.3,
    filter: ['male'],
    categories: ['Gender Icons']
  },
  {
    name: 'Mercury',
    id: 'mercury',
    unicode: 'f223',
    created: 4.3,
    filter: ['transgender'],
    categories: ['Gender Icons']
  },
  {
    name: 'Transgender',
    id: 'transgender',
    unicode: 'f224',
    created: 4.3,
    aliases: ['intersex'],
    categories: ['Gender Icons']
  },
  {
    name: 'Transgender Alt',
    id: 'transgender-alt',
    unicode: 'f225',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Venus Double',
    id: 'venus-double',
    unicode: 'f226',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Mars Double',
    id: 'mars-double',
    unicode: 'f227',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Venus Mars',
    id: 'venus-mars',
    unicode: 'f228',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Mars Stroke',
    id: 'mars-stroke',
    unicode: 'f229',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Mars Stroke Vertical',
    id: 'mars-stroke-v',
    unicode: 'f22a',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Mars Stroke Horizontal',
    id: 'mars-stroke-h',
    unicode: 'f22b',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Neuter',
    id: 'neuter',
    unicode: 'f22c',
    created: 4.3,
    categories: ['Gender Icons']
  },
  {
    name: 'Genderless',
    id: 'genderless',
    unicode: 'f22d',
    created: 4.4,
    categories: ['Gender Icons']
  },
  {
    name: 'Facebook Official',
    id: 'facebook-official',
    unicode: 'f230',
    created: 4.3,
    categories: ['Brand Icons']
  },
  {
    name: 'Pinterest P',
    id: 'pinterest-p',
    unicode: 'f231',
    created: 4.3,
    categories: ['Brand Icons']
  },
  {
    name: 'What\'s App',
    id: 'whatsapp',
    unicode: 'f232',
    created: 4.3,
    categories: ['Brand Icons']
  },
  {
    name: 'Server',
    id: 'server',
    unicode: 'f233',
    created: 4.3,
    categories: ['Web Application Icons']
  },
  {
    name: 'Add User',
    id: 'user-plus',
    unicode: 'f234',
    created: 4.3,
    filter: ['sign up', 'signup'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Remove User',
    id: 'user-times',
    unicode: 'f235',
    created: 4.3,
    categories: ['Web Application Icons']
  },
  {
    name: 'Bed',
    id: 'bed',
    unicode: 'f236',
    created: 4.3,
    filter: ['travel'],
    aliases: ['hotel'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Viacoin',
    id: 'viacoin',
    unicode: 'f237',
    created: 4.3,
    url: 'viacoin.org',
    categories: ['Brand Icons']
  },
  {
    name: 'Train',
    id: 'train',
    unicode: 'f238',
    created: 4.3,
    categories: ['Transportation Icons']
  },
  {
    name: 'Subway',
    id: 'subway',
    unicode: 'f239',
    created: 4.3,
    categories: ['Transportation Icons']
  },
  {
    name: 'Medium',
    id: 'medium',
    unicode: 'f23a',
    created: 4.3,
    categories: ['Brand Icons']
  },
  {
    name: 'Y Combinator',
    id: 'y-combinator',
    unicode: 'f23b',
    created: 4.4,
    aliases: ['yc'],
    categories: ['Brand Icons']
  },
  {
    name: 'Optin Monster',
    id: 'optin-monster',
    unicode: 'f23c',
    created: 4.4,
    url: 'optinmonster.com',
    categories: ['Brand Icons']
  },
  {
    name: 'OpenCart',
    id: 'opencart',
    unicode: 'f23d',
    created: 4.4,
    url: 'opencart.com',
    categories: ['Brand Icons']
  },
  {
    name: 'ExpeditedSSL',
    id: 'expeditedssl',
    unicode: 'f23e',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Battery Full',
    id: 'battery-full',
    unicode: 'f240',
    created: 4.4,
    aliases: ['battery-4'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Battery 3/4 Full',
    id: 'battery-three-quarters',
    unicode: 'f241',
    created: 4.4,
    aliases: ['battery-3'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Battery 1/2 Full',
    id: 'battery-half',
    unicode: 'f242',
    created: 4.4,
    aliases: ['battery-2'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Battery 1/4 Full',
    id: 'battery-quarter',
    unicode: 'f243',
    created: 4.4,
    aliases: ['battery-1'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Battery Empty',
    id: 'battery-empty',
    unicode: 'f244',
    created: 4.4,
    aliases: ['battery-0'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Mouse Pointer',
    id: 'mouse-pointer',
    unicode: 'f245',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'I Beam Cursor',
    id: 'i-cursor',
    unicode: 'f246',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Object Group',
    id: 'object-group',
    unicode: 'f247',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Object Ungroup',
    id: 'object-ungroup',
    unicode: 'f248',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sticky Note',
    id: 'sticky-note',
    unicode: 'f249',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Sticky Note Outlined',
    id: 'sticky-note-o',
    unicode: 'f24a',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'JCB Credit Card',
    id: 'cc-jcb',
    unicode: 'f24b',
    created: 4.4,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Diner\'s Club Credit Card',
    id: 'cc-diners-club',
    unicode: 'f24c',
    created: 4.4,
    categories: ['Brand Icons', 'Payment Icons']
  },
  {
    name: 'Clone',
    id: 'clone',
    unicode: 'f24d',
    created: 4.4,
    filter: ['copy'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Balance Scale',
    id: 'balance-scale',
    unicode: 'f24e',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Hourglass Outlined',
    id: 'hourglass-o',
    unicode: 'f250',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Hourglass Start',
    id: 'hourglass-start',
    unicode: 'f251',
    created: 4.4,
    aliases: ['hourglass-1'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Hourglass Half',
    id: 'hourglass-half',
    unicode: 'f252',
    created: 4.4,
    aliases: ['hourglass-2'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Hourglass End',
    id: 'hourglass-end',
    unicode: 'f253',
    created: 4.4,
    aliases: ['hourglass-3'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Hourglass',
    id: 'hourglass',
    unicode: 'f254',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Rock (Hand)',
    id: 'hand-rock-o',
    unicode: 'f255',
    created: 4.4,
    aliases: ['hand-grab-o'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Paper (Hand)',
    id: 'hand-paper-o',
    unicode: 'f256',
    created: 4.4,
    aliases: ['hand-stop-o'],
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Scissors (Hand)',
    id: 'hand-scissors-o',
    unicode: 'f257',
    created: 4.4,
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Lizard (Hand)',
    id: 'hand-lizard-o',
    unicode: 'f258',
    created: 4.4,
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Spock (Hand)',
    id: 'hand-spock-o',
    unicode: 'f259',
    created: 4.4,
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Hand Pointer',
    id: 'hand-pointer-o',
    unicode: 'f25a',
    created: 4.4,
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Hand Peace',
    id: 'hand-peace-o',
    unicode: 'f25b',
    created: 4.4,
    categories: ['Web Application Icons', 'Hand Icons']
  },
  {
    name: 'Trademark',
    id: 'trademark',
    unicode: 'f25c',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Registered Trademark',
    id: 'registered',
    unicode: 'f25d',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Creative Commons',
    id: 'creative-commons',
    unicode: 'f25e',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'GG Currency',
    id: 'gg',
    unicode: 'f260',
    created: 4.4,
    categories: ['Currency Icons', 'Brand Icons']
  },
  {
    name: 'GG Currency Circle',
    id: 'gg-circle',
    unicode: 'f261',
    created: 4.4,
    categories: ['Currency Icons', 'Brand Icons']
  },
  {
    name: 'TripAdvisor',
    id: 'tripadvisor',
    unicode: 'f262',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Odnoklassniki',
    id: 'odnoklassniki',
    unicode: 'f263',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Odnoklassniki Square',
    id: 'odnoklassniki-square',
    unicode: 'f264',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Get Pocket',
    id: 'get-pocket',
    unicode: 'f265',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Wikipedia W',
    id: 'wikipedia-w',
    unicode: 'f266',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Safari',
    id: 'safari',
    unicode: 'f267',
    created: 4.4,
    filter: ['browser'],
    categories: ['Brand Icons']
  },
  {
    name: 'Chrome',
    id: 'chrome',
    unicode: 'f268',
    created: 4.4,
    filter: ['browser'],
    categories: ['Brand Icons']
  },
  {
    name: 'Firefox',
    id: 'firefox',
    unicode: 'f269',
    created: 4.4,
    filter: ['browser'],
    categories: ['Brand Icons']
  },
  {
    name: 'Opera',
    id: 'opera',
    unicode: 'f26a',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Internet-explorer',
    id: 'internet-explorer',
    unicode: 'f26b',
    created: 4.4,
    filter: ['browser', 'ie'],
    categories: ['Brand Icons']
  },
  {
    name: 'Television',
    id: 'television',
    unicode: 'f26c',
    created: 4.4,
    aliases: ['tv'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Contao',
    id: 'contao',
    unicode: 'f26d',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: '500px',
    id: '500px',
    unicode: 'f26e',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Amazon',
    id: 'amazon',
    unicode: 'f270',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Calendar Plus Outlined',
    id: 'calendar-plus-o',
    unicode: 'f271',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Calendar Minus Outlined',
    id: 'calendar-minus-o',
    unicode: 'f272',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Calendar Times Outlined',
    id: 'calendar-times-o',
    unicode: 'f273',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Calendar Check Outlined',
    id: 'calendar-check-o',
    unicode: 'f274',
    created: 4.4,
    filter: ['ok'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Industry',
    id: 'industry',
    unicode: 'f275',
    created: 4.4,
    filter: ['factory'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Map Pin',
    id: 'map-pin',
    unicode: 'f276',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Map Signs',
    id: 'map-signs',
    unicode: 'f277',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Map Outline',
    id: 'map-o',
    unicode: 'f278',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Map',
    id: 'map',
    unicode: 'f279',
    created: 4.4,
    categories: ['Web Application Icons']
  },
  {
    name: 'Commenting',
    id: 'commenting',
    unicode: 'f27a',
    created: 4.4,
    filter: ['message'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Commenting Outlined',
    id: 'commenting-o',
    unicode: 'f27b',
    created: 4.4,
    filter: ['message'],
    categories: ['Web Application Icons']
  },
  {
    name: 'Houzz',
    id: 'houzz',
    unicode: 'f27c',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Vimeo',
    id: 'vimeo',
    unicode: 'f27d',
    created: 4.4,
    categories: ['Brand Icons']
  },
  {
    name: 'Font Awesome Black Tie',
    id: 'black-tie',
    unicode: 'f27e',
    created: 4.4,
    url: 'blacktie.io',
    categories: ['Brand Icons']
  },
  {
    name: 'Fonticons',
    id: 'fonticons',
    unicode: 'f280',
    created: 4.4,
    url: 'fonticons.com',
    categories: ['Brand Icons']
  },
  {
    name: 'reddit Alien',
    id: 'reddit-alien',
    unicode: 'f281',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Edge Browser',
    id: 'edge',
    unicode: 'f282',
    created: 4.5,
    filter: ['browser', 'ie'],
    categories: ['Brand Icons']
  },
  {
    name: 'Credit Card',
    id: 'credit-card-alt',
    unicode: 'f283',
    created: 4.5,
    filter: ['money',
      'buy',
      'debit',
      'checkout',
      'purchase',
      'payment',
      'credit card'],
    categories: ['Payment Icons', 'Web Application Icons']
  },
  {
    name: 'Codie Pie',
    id: 'codiepie',
    unicode: 'f284',
    created: 4.5,
    url: 'codiepie.com',
    categories: ['Brand Icons']
  },
  {
    name: 'MODX',
    id: 'modx',
    unicode: 'f285',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Fort Awesome',
    id: 'fort-awesome',
    unicode: 'f286',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'USB',
    id: 'usb',
    unicode: 'f287',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Product Hunt',
    id: 'product-hunt',
    unicode: 'f288',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Mixcloud',
    id: 'mixcloud',
    unicode: 'f289',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Scribd',
    id: 'scribd',
    unicode: 'f28a',
    created: 4.5,
    categories: ['Brand Icons']
  },
  {
    name: 'Pause Circle',
    id: 'pause-circle',
    unicode: 'f28b',
    created: 4.5,
    categories: ['Video Player Icons']
  },
  {
    name: 'Pause Circle Outlined',
    id: 'pause-circle-o',
    unicode: 'f28c',
    created: 4.5,
    categories: ['Video Player Icons']
  },
  {
    name: 'Stop Circle',
    id: 'stop-circle',
    unicode: 'f28d',
    created: 4.5,
    categories: ['Video Player Icons']
  },
  {
    name: 'Stop Circle Outlined',
    id: 'stop-circle-o',
    unicode: 'f28e',
    created: 4.5,
    categories: ['Video Player Icons']
  },
  {
    name: 'Shopping Bag',
    id: 'shopping-bag',
    unicode: 'f290',
    created: 4.5,
    categories: ['Web Application Icons']
  },
  {
    name: 'Shopping Basket',
    id: 'shopping-basket',
    unicode: 'f291',
    created: 4.5,
    categories: ['Web Application Icons']
  },
  {
    name: 'Hashtag',
    id: 'hashtag',
    unicode: 'f292',
    created: 4.5,
    categories: ['Web Application Icons']
  },
  {
    name: 'Bluetooth',
    id: 'bluetooth',
    unicode: 'f293',
    created: 4.5,
    categories: ['Web Application Icons', 'Brand Icons']
  },
  {
    name: 'Bluetooth',
    id: 'bluetooth-b',
    unicode: 'f294',
    created: 4.5,
    categories: ['Web Application Icons', 'Brand Icons']
  },
  {
    name: 'Percent',
    id: 'percent',
    unicode: 'f295',
    created: 4.5,
    categories: ['Web Application Icons']
  },
  {
    name: 'GitLab',
    id: 'gitlab',
    unicode: 'f296',
    created: 4.6,
    url: 'gitlab.com',
    categories: ['Brand Icons']
  },
  {
    name: 'WPBeginner',
    id: 'wpbeginner',
    unicode: 'f297',
    created: 4.6,
    url: 'wpbeginner.com',
    categories: ['Brand Icons']
  },
  {
    name: 'WPForms',
    id: 'wpforms',
    unicode: 'f298',
    created: 4.6,
    url: 'wpforms.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Envira Gallery',
    id: 'envira',
    unicode: 'f299',
    created: 4.6,
    url: 'enviragallery.com',
    categories: ['Brand Icons']
  },
  {
    name: 'Universal Access',
    id: 'universal-access',
    unicode: 'f29a',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Wheelchair Alt',
    id: 'wheelchair-alt',
    unicode: 'f29b',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Question Circle Outlined',
    id: 'question-circle-o',
    unicode: 'f29c',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Blind',
    id: 'blind',
    unicode: 'f29d',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Audio Description',
    id: 'audio-description',
    unicode: 'f29e',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Volume Control Phone',
    id: 'volume-control-phone',
    unicode: 'f2a0',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Braille',
    id: 'braille',
    unicode: 'f2a1',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Assistive Listening Systems',
    id: 'assistive-listening-systems',
    unicode: 'f2a2',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'American Sign Language Interpreting',
    id: 'american-sign-language-interpreting',
    unicode: 'f2a3',
    created: 4.6,
    aliases: ['asl-interpreting'],
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Deaf',
    id: 'deaf',
    unicode: 'f2a4',
    created: 4.6,
    aliases: ['deafness', 'hard-of-hearing'],
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Glide',
    id: 'glide',
    unicode: 'f2a5',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Glide G',
    id: 'glide-g',
    unicode: 'f2a6',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Sign Language',
    id: 'sign-language',
    unicode: 'f2a7',
    created: 4.6,
    aliases: ['signing'],
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Low Vision',
    id: 'low-vision',
    unicode: 'f2a8',
    created: 4.6,
    categories: ['Web Application Icons', 'Accessibility Icons']
  },
  {
    name: 'Viadeo',
    id: 'viadeo',
    unicode: 'f2a9',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Viadeo Square',
    id: 'viadeo-square',
    unicode: 'f2aa',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Snapchat',
    id: 'snapchat',
    unicode: 'f2ab',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Snapchat Ghost',
    id: 'snapchat-ghost',
    unicode: 'f2ac',
    created: 4.6,
    categories: ['Brand Icons']
  },
  {
    name: 'Snapchat Square',
    id: 'snapchat-square',
    unicode: 'f2ad',
    created: 4.6,
    categories: ['Brand Icons']
  }
];
