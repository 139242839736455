import React from 'react';
import moment from 'moment';
import { Icon } from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';
import { fill, range, each, slice, isEmpty } from 'lodash';

import { favoritesCount, uniqueJarsCount } from '../../modules/memory/helpers';

function dayClasses (day) {
    const cls = ['date'];
    
    if (day.selected) {
        cls.push('selected primary-text');
    } else if (day.today) {
        cls.push('today favorite-text');
    }

    if (day.memories && day.memories.length === 0 && !day.selected)
        cls.push('item-desc-text');

    return cls.join(" ");
};

function buildCalendar ({date, memories, moods, isPicker}) {
    const weekdays = moment.weekdaysShort();

    const firstDayOfMonth = date.clone().startOf('month');
    const firstWeekDayOfMonth = firstDayOfMonth.weekday();
    const endOfMonth = date.clone().endOf('month');

    // weird hack for now
    const daysInMonth = date.daysInMonth() + 1;
    const weeksInMonth = moment(endOfMonth.diff(firstDayOfMonth)).weeks();

    let calendarDates = fill(range(1, weeksInMonth * 7 + 1), {});

    each(range(1, daysInMonth), (_date) => {
        let data = {
            date: _date,
            memories: [],
            moods: [],
            today: false,
            currentDate: date.clone().date(_date),
        };

        if (!isPicker) {
            each(memories, (memory) => {
                let memoryDate = moment(memory.timestamp);
                if (memoryDate.isSame(data.currentDate, 'day')) {
                    data.memories.push(memory);
                }
            });

            each(moods, (mood) => {
                if (moment(mood.created_at).isSame(data.currentDate, 'day')) {
                    data.moods.push(mood);
                }
            });
        }
            
        if (moment().isSame(data.currentDate, 'day'))
            data.today = true;

        calendarDates[_date + firstWeekDayOfMonth - 1] = data;
    });

    const weeks = range(0, weeksInMonth);
    return {weeks, calendarDates, weekdays};
};

function daysOfWeek(calendarDates, weekNum) {
    let start = weekNum * 7;
    let days = slice(calendarDates, start, start + 7);
    return days;
};

const entryDots = (entries) => {
    if (entries.length < 0)
        return null;
    
    // console.log('rendering dots', entries);
    return (
        <div className="dots">
            {entries.map(entry => {
                const { id, color } = entry,
                    dotColor = color ? `${(color.name || color)}-text` : 'default';

                return (
                    <span
                        key={id}
                        className={["dot", dotColor].join(" ")}>
                        .
                    </span>
                );
            })}
        </div>
    );
};

const renderDates = (props, day, i) => {
    const classes = ["date-day bp-5"],
        date = day.date ? day.currentDate.clone().date(day.date) : null,
        entries = (day.memories && day.memories.length > 0) ? day.memories : (day.moods && day.moods.length > 0 ? day.moods : []);
    
    if (props.isPicker && props.date.isSame(date)) {
        day.selected = true;
    }

    return (
        <Col
            xs
            key={ i }
            className="clickable date-col"
            onClick={() => day.date && props.onDateClick ? props.onDateClick(date) : null}>
            {day.date && (
                <div className={dayClasses(day)}>
                    <span className={classes.join(" ")}>{day.date}</span>
                    { entryDots(entries) }
                </div>
            )}
        </Col>
    );
}

const buildStats = (memories) => {
    return {
        memories: memories.length,
        favorites: isEmpty(memories) ? 0 : favoritesCount(memories),
        jars: isEmpty(memories) ? 0 : uniqueJarsCount(memories),
    }
};

function changeMonth (dir, { date, onChange }) {
    const method = dir === 'next' ? 'add' : 'subtract';
    if (typeof onChange === 'function')
        onChange(date.clone()[method](1, 'months'));
};

const Stats = ({memories}) => {
    const stats = buildStats(memories);

    return (
        <Row about="xs" className="stats-row text-center tp-30 bp-10">
            <Col xs>
                <div className="stats-count bp-10">
                    <Icon name="book" /> &nbsp;
                        {stats.memories}
                </div>
                <div>
                    Memories
                </div>
            </Col>
            <Col xs>
                <div className="stats-count bp-10">
                    <Icon name="heart text-favorite" /> &nbsp;
                        {stats.favorites}
                </div>
                <div>
                    Favorites
                </div>
            </Col>
            <Col xs>
                <div className="stats-count bp-10">
                    <Icon name="bitbucket" /> &nbsp;
                        {stats.jars}
                </div>
                <div>
                    Jars
                </div>
            </Col>
        </Row>
    );
};

// props = {date, memories, onChange, isPicker, onDateClick}

export default (props) => {
    const { weeks, calendarDates, weekdays } = buildCalendar(props);

    const month = props.date.format('MMMM'),
        year = props.date.format('YYYY');

    return (
        <Row>
            <Col xs={12}>
                <Row center="xs" className="text-center hp-20 month-row">
                    <Col 
                        xs 
                        className="favorite-text clickable"
                        onClick={ () => changeMonth('previous', props) }>
                        <Icon name="chevron-left tp-25"/>
                    </Col>
                    <Col xs>
                        <h3 className="primary-text tm-0">{ month }</h3>
                        <h4>{ year }</h4>
                    </Col>
                    <Col 
                        xs 
                        className="favorite-text clickable"
                        onClick={ () => changeMonth('next', props) }>
                        <Icon name="chevron-right tp-25"/>
                    </Col>
                </Row>

                <Row className="text-center days-row bp-15 bm-10" center="xs">
                    { weekdays.map(dayName => (
                        <Col 
                            xs 
                            key={dayName}> {dayName} </Col>
                    )) }
                </Row>

                { weeks.map((week) => (
                    <Row className="text-center dates-row tp-25 bp-20" key={ week }>
                        { daysOfWeek(calendarDates, week).map(renderDates.bind(null, props)) }
                    </Row>
                )) }
            </Col>
            
            {!props.isPicker && props.memories && <Stats memories={props.memories} />}
        </Row>
    );
};

