import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../../modules/auth/actions';

class Login extends React.Component {
	state = {
		creds: {
			email: '',
			password: ''
		}
	};

	handleInput = (field) => (e) => {
		const { creds } = this.state;
		this.setState({ creds: {...creds, [field]: e.target.value } });
	};

	handleSubmit = (e) => {
		e.preventDefault();

		if (!this.props.isLoggingIn)
			this.props.login(this.state.creds);
	};

	render () {
		return (
			<form action="#" onSubmit={ this.handleSubmit }>
				<div className="input-row">
					<input 
						type="email" 
						placeholder="email address"
						onChange={ this.handleInput('email') } />
				</div>

				<div className="input-row">
					<input 
						type="password" 
						placeholder="password"
						onChange={ this.handleInput('password') } />
				</div>

				<div className="input-row">
					<button 
						onClick={ this.handleSubmit }
						className="btn block bordered tertiary-bg light-text primary-border clickable">
						{ this.props.isLoggingIn ? (
							<Icon name='spinner fa-spin'></Icon>
						) : 'SIGN IN' }
					</button>
				</div>

				<div className="errors">
					{ this.props.errors.map((err, i) => (
						<div className="error" key={i}>{ err }</div>
					)) }
				</div>
			</form>
		);
	};
};

const mapStateToProps = state => ({
	isLoggingIn: state.auth.isLoggingIn,
	user: state.auth.user,
	errors: state.auth.errors
});

const mapDispatchToProps = dispatch => bindActionCreators({
	login,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);