import { purchasePremium, getCurrentPremium } from '../api';

export const PREMIUM_ERROR = 'premium/ERROR';
export const PREMIUM_LOADING = 'premium/LOADING';
export const PREMIUM_CURRENT = 'premium/CURRENT';

const initialState = {
    current: {},
    error: null,
    isLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PREMIUM_LOADING:
            return {
                ...state,
                isLoading: action.data
            };

        case PREMIUM_CURRENT:
            return {
                ...state,
                isLoading: false,
                current: action.data
            };

        case PREMIUM_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.err
            };

        default:
            return state;
    }
};

export const fetchCurrentPremium = () => {
    return dispatch => {
        dispatch({
            type: PREMIUM_LOADING,
            data: true,
        });

        return getCurrentPremium().then((current) => {
            dispatch({
                type: PREMIUM_CURRENT,
                data: current
            });
        }).catch(err => {
            dispatch({
                type: PREMIUM_ERROR,
                error: err.message,
            });
        });
    };
};

export const purchase = (stripe_token) => {
    return dispatch => {
        dispatch({
            type: PREMIUM_LOADING,
            data: true,
        });

        return purchasePremium(stripe_token).then((res) => {
            dispatch({
                type: PREMIUM_CURRENT, data: res.data
            });
        }).catch(err => {
            dispatch({
                type: PREMIUM_ERROR,
                error: err.message,
            });
        });
    };
};
