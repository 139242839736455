import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';

import { fetchColors } from '../../modules/color/reducers';
import { colorClasses } from '../../modules/color/helpers';

class Picker extends React.Component {
	componentWillMount() {
		this.props.fetchColors();
	};

	render () {
		const { onUpdate, data, isLoading } = this.props;

		return (
			<Row around="xs" className="color-picker">
				{ isLoading ? (
					<Icon name='spinner fa-spin'></Icon>
				) : this.props.colors.map(color => (
					<Col 
						xs={3} 
						key={ color.id }
						className='clickable'
						onClick={ () => onUpdate({color_id: color.id, color}) }>
						<div className={ colorClasses(color, 'text-center') }>
							{ data.color && data.color.id === color.id && (
								<Icon name="check" style={{
									color: '#ffffff',
									fontSize: '1.2em',
									lineHeight: '35px'
								}}/>
							) }
						</div>
					</Col>
				)) }
			</Row>
		);
	}
};

const mapStateToProps = state => ({
	colors: state.colors.data,
	isLoading: state.colors.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchColors
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Picker);