import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import JarPicker from '../../containers/jar/picker';
import IconPicker from '../../containers/icon/picker';
import DatePicker from '../../containers/date/picker';
import ColorPicker from '../../containers/color/picker';
import MemoryEditor from '../../containers/memory/editor';
import LocationPicker from '../../containers/location/picker';
import PicturePicker from '../../containers/picture/internet';
import { createMemory, editMemory, loadInitialMemory } from '../../modules/memory/reducers/editor';

class Creator extends React.Component {
	componentDidMount() {
		this.props.loadInitialMemory(this.getCurrentMemoryId());	
	};

	getCurrentMemoryId () {
		return get(this.props, 'match.params.id');
	};

	getCurrentSection () {
		return get(this.props, 'match.params.section');
	};

	showSection (section) {
		const id = this.getCurrentMemoryId(),
			url = id ? `/memories/edit/${id}/${section}` : `/memories/create/${section}`;
		
		return this.props.history.push(url);
	};

	onClose () {
		if (this.getCurrentMemoryId())
			this.props.loadInitialMemory(null);
		
		return this.props.history.push('/');
	};

	renderSectionComponent () {
		const section = this.getCurrentSection();
		const comps = {
			'jars': JarPicker,
			'date': DatePicker,
			'icons': IconPicker,
			'colors': ColorPicker,
			'pictures': PicturePicker,
			'location': LocationPicker,
		};
	
		let Section = (!section) ? comps['icons'] : comps[section];
		return (<Section 
			data={ this.props.memory }
			onUpdate = { this.props.editMemory }/>);
	};

	render () {
		return (
			<Row>
				<Col md={7} className='primary-container'>
					<MemoryEditor 
						memory = { this.props.memory } 
						isSaving = { this.props.isSaving }
						currentSection ={ this.getCurrentSection() }
						showSection ={ this.showSection.bind(this) }
						onClose = { this.onClose.bind(this) }
						createMemory ={ this.props.createMemory }
						editMemory = { this.props.editMemory }/>
				</Col>
							
				<Col md={5} className='secondary-bg'>
					<Scrollbars style={{ height: '80vh', paddingBottom: '20px' }}>
						{ this.renderSectionComponent() }
					</Scrollbars>
				</Col>
			</Row>
		);
	};
};

const mapStateToProps = state => ({
	isSaving: state.memoryEditor.isSaving,
	memory: state.memoryEditor.memory,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	createMemory, editMemory, loadInitialMemory,
	showSection: (section) => push(`/memories/create/${section}`)
}, dispatch);

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Creator));