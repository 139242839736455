import { push } from 'connected-react-router';

export const POPUP_SHOWN = 'popups/SHOW';
export const POPUP_HIDDEN = 'popup/HIDE';

const initialState = {
    confirmButtonText: '',
    cancelButtonText: '',
    show: false,
    title: '',
    name: '',
    text: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POPUP_SHOWN:
            return {
                ...state,
                show: true,
                ...action.data,
            };

        case POPUP_HIDDEN:
            return {
                ...state,
                name: '',
                show: false,
            };

        default:
            return state;
    }
};

export const showPremiumPopup = (feature='') => {
    const cancelButtonText = "Not Now";
    const confirmButtonText = "Go Premium";
    const title = "Premium Feature";
    const name = 'premium';
    const text = `Sorry ${feature} is only available for premium users. Subscribe to a premium plan to access this and many more amazing features like this.`;

    return dispatch => {
        dispatch({ type: POPUP_SHOWN, data: {cancelButtonText, confirmButtonText, title, text, name} });
    };
};

export const popupConfirmed = () => {
    return (dispatch, getState) => {
        const { popups: { name } } = getState();

        if (name === 'premium')
            dispatch(push('/settings/premium'));

        dispatch({ type: POPUP_HIDDEN });

    };
};

export const popupCancelled = () => {
    return dispatch => {
        dispatch({ type: POPUP_HIDDEN });
    };
};
