import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';

import List from './list';
import MemoryMap from './map';
import { fetchLocations } from '../../modules/location/reducers';

const emptyLocation = {
	title: '',
	lat: 0,
	lng: 0,
	place_id: null,
	place_name: null,
};

class Picker extends React.Component {
	state = {
		tab: 1,
		location: emptyLocation 
	};
	
	searchBox = null;

	componentWillMount() {
		this.props.fetchLocations();
	};

	setLatLng = ({lat, lng}) => {
		this.updateLocation({ lat: lat(), lng: lng() });
	};

	updateLocation = (location) => {
		this.setState({ 
			location: { ...this.state.location, ...location } 
		}, () => this.props.onUpdate({ location: this.state.location }));
	};

	setTitle = (e) => {
		this.updateLocation({ title: e.target.value });
	};

	onSearchBoxMounted = (ref) => {
		this.searchBox = ref;
	};

	onPlacesChanged = () => {
		const places = this.searchBox.getPlaces();
		if (places && places[0]) {
			const { geometry: { location }, place_id, formatted_address } = places[0];
			this.updateLocation({
				lat: location.lat(),
				lng: location.lng(),
				title: this.state.location.title.length > 0 ? this.state.location.title : formatted_address,
				place_id, place_name: formatted_address
			});
		}
	};

	removeLocation = () => {
		this.updateLocation(emptyLocation);
		setTimeout(() => {
			this.props.onUpdate({ location: null });
		}, 100);
	};

	render () {
		const {data} = this.props;

		return (
			<div className="location-picker-container">
				<h4 className="tp-10 lp-10">
					{this.state.tab === 1 ? 'Pick From Map' : 'Your Places'}
					<span className="fr">
						<i
							className={`fa fa-map clickable sp-10 ${this.state.tab === 1 ? 'primary-text' : ''}`}
							onClick={() => this.setState({ tab: 1 })}></i>
						<i
							className={`fa fa-map-marker clickable sp-10 ${this.state.tab === 0 ? 'primary-text' : ''}`}
							onClick={() => this.setState({ tab: 0 })}></i>
						<i
							className={`fa fa-times clickable sp-10`}
							onClick={this.removeLocation}></i>
					</span>
				</h4>

				{ this.state.tab === 1 ? (
					<div>
						<div className="input-row bm-20">
							<input 
								type="text" 
								placeholder="Name your place"
								onChange={this.setTitle}/>
						</div>

						<div className="input-row bm-20">
							<StandaloneSearchBox
								ref={this.onSearchBoxMounted}
								onPlacesChanged={this.onPlacesChanged}>
								<input
									type="text"
									placeholder="Street/Place/Location etc." />
							</StandaloneSearchBox>
						</div>

						<MemoryMap 
							loadingElement={ <Icon name="spinner fa-spin"></Icon> }
							containerElement={
								<div style={{ height: `40vh` }} />
							}
							mapElement={
								<div style={{ height: `40vh` }} />
							}
							onMapClick={ ({latLng}) => this.setLatLng(latLng) }
							markers={ data.location ? [data.location] : [] }/>
					</div>
				) : (
					<List 
						locations={this.props.locations} 
						onSelect={this.props.onUpdate}/>
				) }
			</div>
		);
	};
};

const mapStateToProps = state => ({
	locations: state.locations.data,
	isLoading: state.locations.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchLocations
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Picker);