import { getNotifications } from '../api';

export const NOTIFICATIONS = 'memories/NOTIFICATIONS';
export const NOTIFICATIONS_REQUESTED = 'memories/NOTIFICATIONS_REQUESTED';

// const perPage = 10;

const initialState = {
    page: 0,
    data: [],
    hasEnded: true,
    isLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_REQUESTED:
            return {
                ...state,
                isLoading: true
            };

        case NOTIFICATIONS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            };

        default:
            return state;
    }
};

export const fetchNotifications = () => {
    return (dispatch) => {
        dispatch({
            type: NOTIFICATIONS_REQUESTED
        });

        return getNotifications().then(data => {
            dispatch({
                type: NOTIFICATIONS, data
            });
        });
    };
};