import { purchaseSubscription, cancelSubscription, getPlans, getCurrentSubscription } from '../api';

export const SUBSCRIPTION_CANCELLED = 'subscription/CANCELLED';
export const SUBSCRIPTION_LOADING = 'subscription/LOADING';
export const SUBSCRIPTION_CURRENT = 'subscription/CURRENT';
export const SUBSCRIPTION_PLANS = 'subscription/PLANS';

const initialState = {
    plans: [],
    current: {},
    isLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_LOADING:
            return {
                ...state,
                isLoading: action.data
            };

        case SUBSCRIPTION_PLANS:
            return {
                ...state,
                isLoading: false,
                plans: action.data
            };

        case SUBSCRIPTION_CANCELLED:
            return {
                ...state,
                isLoading: false,
                current: { ...state.current, cancelled_at: new Date() }
            };

        case SUBSCRIPTION_CURRENT:
            return {
                ...state,
                isLoading: false,
                current: action.data
            };

        default:
            return state;
    }
};

export const fetchPlans = () => {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTION_LOADING,
            data: true,
        });

        return getPlans().then((plans) => {
            dispatch({
                type: SUBSCRIPTION_PLANS,
                data: plans
            });
        });
    };
};

export const fetchCurrentSubscription = () => {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTION_LOADING,
            data: true,
        });

        return getCurrentSubscription().then((current) => {
            dispatch({
                type: SUBSCRIPTION_CURRENT,
                data: current
            });
        });
    };
};

export const cancel = (id) => {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTION_LOADING,
            data: true,
        });

        cancelSubscription(id).then(res => {
            dispatch({type: SUBSCRIPTION_CANCELLED});
        });
    };
};

export const purchase = (plan, card_brand, card_digits, stripe_token) => {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTION_LOADING,
            data: true,
        });

        return purchaseSubscription(plan.id, card_brand, card_digits, stripe_token).then((res) => {
            dispatch({
                type: SUBSCRIPTION_CURRENT, data: res.data
            });
        });
    };
};
