import Api from '../api';

export function purchasePremium(stripe_token) {
    const api = new Api();
    const data = { premium: { processor: 'stripe' }, stripe_token };
    return api.post('/premiums', data);
};

export function getCurrentPremium () {
    const api = new Api();
    return api.get('/premiums').then(res => res.data || {});
};