import React from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

import theme from './theme';
import MarkerIcon from '../../images/map_marker.png';

const parseMarker = (marker) => {
	return { 
		lat: parseFloat(marker.lat), 
		lng: parseFloat(marker.lng) 
	};
};

function getCenter ({markers, selected}) {
	if (selected)
		return parseMarker(selected);
	
	if (markers.length > 0)
		return parseMarker(markers[0]);
	
	return { lat: -25.363882, lng: 131.044922 };
};

export default withGoogleMap(props => (
	<GoogleMap
		ref={props.onMapLoad}
		defaultZoom={7}
		center={ getCenter(props) }
		defaultOptions={{ styles: theme, mapTypeControl: false, streetViewControl: false }}
		defaultCenter={ getCenter(props) }
		onClick={props.onMapClick} >
			{props.markers && props.markers.map((marker, index) => (
				<Marker 
					key={ index }
					icon={ MarkerIcon }
					animation={ props.selected && props.selected.id === marker.id ? 1 : 0 }
					position={ parseMarker(marker) }
					onRightClick={() => props.onMarkerRightClick(index)}>
					{ ((props.selected && props.selected.id === marker.id) || props.markers.length === 1) && (
						<InfoWindow>
							<div style={{ color: '#000000' }}>
								<p>
								<strong>{marker.title}</strong>
								{ marker.place_name && (<i><br/>{marker.place_name}</i>)}
								</p>
							</div>
						</InfoWindow>
					) } 
				</Marker>
			))}
	</GoogleMap>
));