import Api from '../api';

export const getJars = () => {
    const api = new Api();
    return api.get('/jars').then(res => {
        return res.data;
    });
};

export const getJar = (id) => {
    const api = new Api();
    return api.get(`/jars/${id}`).then(res => {
        return res.data;
    });
};

export const removeJar = (id) => {
    const api = new Api();
    return api.remove(`/jars/${id}`).then(res => {
        return res.data;
    });
};

export const saveJar = (jar) => {
    const api = new Api();

    if (jar.id) {
        return api.put(`/jars/${jar.id}`, { jar });
    } else {
        return api.post('/jars', { jar });
    }
};

export const leaveJar = (id) => {
    const api = new Api();
    return api.remove(`/jars/${id}/leave`)
        .then(res => res);
};

export const toggleMute = (id, access) => {
    const api = new Api();
    return api.put(`/jars/${id}/mute`, { access })
        .then(res => res.data);
};

export function findByKeyword(jars, keyword = '') {
    if (!keyword || keyword.length < 1) {
        return jars;
    }

    keyword = keyword.toLowerCase();
    return jars.filter(j => {
        if (j.name && j.name.toLowerCase().indexOf(keyword) >= 0)
            return true;

        if (j.description && j.description.toLowerCase().indexOf(keyword) >= 0)
            return true;

        return false;
    });
};

export function isOwner (jar, user) {
    return jar.owner.id === user.id;
};

export function isMuted(jar, user) {
    // if there is no config found that means there are no members in the jar and the current user is the owner of the jar
    const config = jar.members_config.find(m => m.user_id === user.id);
    return config ? !config.access : false;
};