import Api from '../api';

export const getMoods = (params={}) => {
    const api = new Api();
    return api.get('/moods', params).then(res => {
        return res.data;
    });
};

export const getMood = (id) => {
    const api = new Api();
    return api.get(`/moods/${id}`).then(res => {
        return res.data;
    });
};

export const save = (mood={}) => {
    const api = new Api();
    return api.post('/moods', { mood });
};

export const remove = (mood={}) => {
    const api = new Api();
    return api.remove(`/moods/${mood.id}`);
};
