import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import SweetAlert from 'sweetalert-react';
import { bindActionCreators } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars';

import Loader from '../../components/utils/loader';
import MemorySingle from '../../components/memory/single';
import { fetchMemory, deleteMemory } from '../../modules/memory/reducers/single';

class Single extends Component {
	state = {
		showDeleteConfirmation: false
	};

	toggleDeleteConfirmation = () => {
		this.setState({showDeleteConfirmation: !this.state.showDeleteConfirmation});
	};

	loadMemory (props) {
		const id = this.getMemoryId(props);

		if (id) this.props.fetchMemory(parseInt(id));
	};

	getMemoryId (props) {
		return get(props, 'match.params.id');
	};

	onDelete = () => {
		this.toggleDeleteConfirmation();
		this.props.deleteMemory(this.getMemoryId(this.props));
		this.props.history.push('/');
	};

	componentWillReceiveProps (nextProps) {
		if (this.getMemoryId(this.props) !== this.getMemoryId(nextProps))
			this.loadMemory(nextProps);
	};

	componentWillMount() {
		this.loadMemory(this.props);
	};

	render () {
		if (this.props.isLoading || !this.props.memory)
			return <Loader />;

		return (
			<Scrollbars style={{ height: '80vh' }}>
				<SweetAlert
					show={this.state.showDeleteConfirmation}
					showCancelButton
					cancelButtonText="Keep It"
					confirmButtonText="Delete"
					title="Sure you want to delete this memory?"
					text="Deleted memories are no recoverable. Are you sure you want to lose this memory?"
					onConfirm={this.onDelete}
					onCancel={this.toggleDeleteConfirmation}
				/>

				<MemorySingle 
					memory={this.props.memory}
					onDelete={this.toggleDeleteConfirmation}
					onClose={ () => this.props.history.push('/') }
					onEdit={() => this.props.history.push(`/memories/edit/${this.props.memory.id}`)} />
			</Scrollbars>
		);
	};
};

const mapStateToProps = state => ({
	memory: state.memory.memory,
	isLoading: state.memory.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchMemory, deleteMemory
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Single);