import moment from 'moment';
import Api from '../api';

export function memoryClasses (data, initial = 'card memory-card') {
	let cls = [initial];

	if (data.color)
		cls.push(`color-${data.color.name}`);
	else
		cls.push('default');

	if (data.description && data.description.length > 1) {
		cls.push('has-description');
	}

	if (data.favorite) {
		cls.push('is-favorite');
	}

	if (data.jar || data.jar_id) {
		cls.push('has-jar');
	}

	if (data.location) {
		cls.push('has-location');
	}

	return cls.join(" ");
};

export function memoryDate (data, format='DD') {
	const date = moment(data.timestamp);
	return date.format(format);
};

export function hasDescription (data) {
	return data.description;	
};

export function resizeTitle	(data) {
	const length = data.description || data.location ? 30 : 32;
	const title = data.title.charAt(0).toUpperCase() + data.title.slice(1);
	if (title.length <= length) return title;
	return title.substring(0, length)+ '....';
};

export function resizeDescription (data) {
	const length = 200;
	const description = data.description.charAt(0).toUpperCase() + data.description.slice(1);
	if (description.length <= length) return description;
	return description.substring(0, length)+ '....';
};

export function textColorClass (color, others) {
	if (!color)
		return others;
	
	return `${others} ${color.name}-text`;
};

export function bgColorClass (color, others) {
	if (!color)
		return others;
	
	return `${others} ${color.name}-bg`;
};

export function hasPictures (data) {
	if (!data) return false;

	const {pictures} = data;

	if (!pictures || pictures.length <= 0)
		return false;

	if (pictures[0].url || pictures[0].image || pictures[0].picture_original)
		return true;
};

export function firstPicture (data) {
	if (!hasPictures(data))
		return null;
	
	if (data.pictures && data.pictures.length > 0) 
		return data.pictures[0];
};

export function recordingUrl (recording={}){
	const api = new Api(),
		url = api.url + recording.audio_url;
	
	console.log(url);
	return url;
};

export function pictureUrl (data, type, size='medium'){
	if (!hasPictures(data))
		return null;

	const picture = firstPicture(data);
	if (!picture.id) {
		if (type !== 'bg')
			return picture.url || picture.image;
		
		if (picture.url)
			return `url("${picture.url}")`;
		
		return `url("${picture.image}")`;
	}

	let pic = size ? picture['picture_'+size] : picture.picture_original;
	
	if (!pic && picture.url) {
		pic = picture.url;
	}

	let url = pic.indexOf('http') >= 0 ? pic : new Api().url + pic;
	
	if (type === 'bg')
		return `url("${url}")`;
	
	return url;
};

export function favoritesCount(events) {
	return events.filter(e => !!e.favorite).length;
};

export function uniqueJarsCount(events) {
	const jars = [];
	events.forEach(e => {
		if (e.jar && jars.indexOf(e.jar.id) < 0)
			jars.push(e.jar.id);
	});

	return jars.length;
};