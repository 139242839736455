import React from 'react';
import { Icon } from 'react-fa';
import Ionicon from 'react-ionicons';

export default ({ name='disc', color = {}, fontSize = '100px' }) => {
    if (name.indexOf('fa-') === 0) {
        return (<Icon name={name.replace('fa-', '')}></Icon>);
    }

    return (<Ionicon 
        icon={`md-${name}`} 
        color={ color.value || 'default' }
        fontSize={fontSize} />);
};