import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import Calendar from '../../components/calendar';
import Loader from '../../components/utils/loader';
import MemoryCard from '../../components/memory/card';
import { loadCalendar } from '../../modules/memory/reducers/calendar';

class CalendarPage extends React.Component {
    componentDidMount() {
        this.props.loadCalendar(moment());
    };

    render() {
        const month = this.props.date.format('MMMM'),
            year = this.props.date.format('YYYY');

        return (
            <Row>
                <Col md={5} className='primary-container'>

                    {this.props.isLoading && (
                        <Loader />
                    )}

                    {!this.props.isLoading && this.props.memories.length < 1 && (
                        <div className="card sp-15 hp-10">
                            <h4>No memories found.</h4>
                            <p>You have no memories in {month}, {year}. Try changing the calendar month. </p>
                        </div>
                    )}

                    {!this.props.isLoading && this.props.memories.length > 0 && (
                        <Scrollbars style={{ height: '80vh' }}>
                            <div className="memory-list">
                                {this.props.memories.map(memory => (
                                    <MemoryCard
                                        onClick={(m) => console.log(m, 'clicked')}
                                        key={memory.id}
                                        memory={memory}>
                                    </MemoryCard>
                                ))}
                            </div>
                        </Scrollbars>
                    )}
                </Col>
                
                <Col md={7} className='secondary-bg calendar-container'>
                    { this.props.date && (
                        <Calendar 
                            date={ this.props.date } 
                            memories={ this.props.memories } 
                            onChange={ (newMonth) => this.props.loadCalendar(newMonth) }/>
                    ) }
                </Col>
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    date: state.calendar.date,
    memories: state.calendar.memories,
    isLoading: state.calendar.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadCalendar
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarPage);