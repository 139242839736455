import Api from '../api';

export const getMemories = (filters={}) => {
	const api = new Api();
	return api.get('/events', filters).then(res => {
		return res.data;
	});
};

export const getMemory = (id) => {
	const api = new Api();
	return api.get(`/events/${id}`).then(res => {
		return res.data;
	});
};

export const saveMemory = (memory) => {
	const api = new Api();

	if (memory.id) {
		return api.put(`/events/${memory.id}`, {event: memory});
	} else {
		return api.post('/events', {event: memory});
	}
};

export const removeMemory = (id) => {
	const api = new Api();
	return api.remove(`/events/${id}`);
};