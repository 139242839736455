import { getAllLocations, findByKeyword } from '../api';

export const LOCATIONS_RECEIVED = 'memories/LOCATIONS_RECEIVED';
export const LOCATIONS_SEARCHED = 'memories/LOCATIONS_SEARCHED';
export const LOCATIONS_REQUESTED = 'memories/LOCATIONS_REQUESTED';
export const LOCATIONS_SEARCH_CLEARED = 'memories/LOCATIONS_SEARCH_CLEARED';

const initialState = {
	// contains the entries that will be sent to the components
	data: [],
	// contains all the locations from server
	entries: [],
	keyword: '',
	isLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOCATIONS_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case LOCATIONS_RECEIVED:
			return {
				...state,
				isLoading: false,
				entries: action.data,
				data: findByKeyword(action.data, state.keyword),
			};

		case LOCATIONS_SEARCHED:
			return {
				...state,
				keyword: action.data,
				data: findByKeyword(state.entries, action.data)
			};

		case LOCATIONS_SEARCH_CLEARED:
			return {
				...state,
				keyword: '',
				data: state.entries
			};

		default:
			return state;
	}
};

export const fetchLocations = (force=false) => {
	return dispatch => {
		dispatch({
			type: LOCATIONS_REQUESTED
		});

		return getAllLocations(force).then((locations) => {
			dispatch({
				type: LOCATIONS_RECEIVED,
				data: locations
			});
		});
	};
};

export const searchLocations = (keyword) => {
	return {
		type: LOCATIONS_SEARCHED,
		data: keyword
	};
};

export const clearSearch = () => {
	return {
		type: LOCATIONS_SEARCH_CLEARED,
	};
};