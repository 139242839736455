import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import JarEditor from '../../containers/jar/editor';
import IconPicker from '../../containers/icon/picker';
import UserPicker from '../../containers/user/picker';
import PicturePicker from '../../containers/picture/internet';
import { createJar, editJar, loadInitialJar } from '../../modules/jar/reducers/editor';

class Creator extends React.Component {
    componentDidMount() {
        this.props.loadInitialJar(this.getCurrentJarId());
    };

    getCurrentJarId() {
        return get(this.props, 'match.params.id');
    };

    getCurrentSection() {
        return get(this.props, 'match.params.section');
    };

    showSection(section) {
        const id = this.getCurrentJarId(),
            url = id ? `/jars/edit/${id}/${section}` : `/jars/create/${section}`;

        return this.props.history.push(url);
    };

    onClose() {
        if (this.getCurrentJarId())
            this.props.loadInitialJar(null);

        return this.props.history.push('/jars');
    };

    renderSectionComponent() {
        const section = this.getCurrentSection();
        const comps = {
            'icons': IconPicker,
            'members': UserPicker,
            'pictures': PicturePicker,
        };

        let Section = (!section) ? comps['pictures'] : comps[section];
        return (<Section
            data={this.props.jar}
            onUpdate={this.props.editJar} />);
    };

    render() {
        return (
            <Row>
                <Col md={7} className='primary-container'>
                    <JarEditor
                        jar={this.props.jar}
                        isSaving={this.props.isSaving}
                        currentSection={this.getCurrentSection()}
                        showSection={this.showSection.bind(this)}
                        onClose={this.onClose.bind(this)}
                        createJar={this.props.createJar}
                        editJar={this.props.editJar} />
                </Col>

                <Col md={5} className='secondary-bg'>
                    <Scrollbars style={{ height: '80vh', paddingBottom: '20px' }}>
                        {this.renderSectionComponent()}
                    </Scrollbars>
                </Col>
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    isSaving: state.jarEditor.isSaving,
    jar: state.jarEditor.jar,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createJar, editJar, loadInitialJar,
    showSection: (section) => push(`/jars/create/${section}`)
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Creator));