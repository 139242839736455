import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from 'react-fa';
import React from 'react';

import { 
	fetchMemories, filterMemories, 
	clearFilters, toggleSearchMode 
} from '../../modules/memory/reducers/list';

const MenuTopbar = ({
	filters, isLoggedIn, fetchMemories, 
	clearFilters, filterMemories, searchActive, toggleSearchMode
}) => isLoggedIn ? (
// class MenuTopbar extends React.Component { render () { const { props } = this; console.log('re-rendering topbar'); return (
	<Row className="menu-topbar" end="xs">
		{ searchActive && (
			<Col xs={9}>
				<Row>
					<Col xs={7}>
						<div className="input-row">
							<input 
								type="text" 
								value={ filters.keyword }
								placeholder="Search your memories"
								onChange={ (e) => {
									if (e.keycode === 13)
										fetchMemories();
									
									filterMemories({keyword: e.target.value}); 
								}}/>
						</div>
					</Col>
					<Col xs={1}>
						<Link to="#" 
							onClick={() => filterMemories({ keyword: applyKeywordFilter('favorite', filters) }) }>
							<Icon 
								name="heart" 
								className={ hasKeywordFilter('favorite', filters) ? 'favorite-text' : ''}>
							</Icon>
						</Link>
					</Col>
					<Col xs={1}>
						<Link 
							to="#"
							onClick={() => filterMemories({ keyword: applyKeywordFilter('location', filters) }) }>
							<Icon 
								name="map-marker"
								className={ hasKeywordFilter('location', filters) ? 'favorite-text' : ''}></Icon>
						</Link>
					</Col>
					<Col xs={1}>
						<Link 
							to="#"
							onClick={() => filterMemories({ keyword: applyKeywordFilter('picture', filters) }) }>
							<Icon 
								name="camera"
								className={ hasKeywordFilter('picture', filters) ? 'favorite-text' : ''}></Icon>
						</Link>
					</Col>
					<Col xs={1}>
						<Link to="#" onClick={ clearFilters }>
							<Icon name="remove"></Icon>
						</Link>
					</Col>
				</Row>
			</Col>
		) }
		<Col sm={1} xs={2}>
			<Link to="#" onClick={ searchActive ? fetchMemories : toggleSearchMode }>
				<Icon name="search"></Icon>
			</Link>
		</Col>
		<Col sm={1} xs={2}>
			<Link to="/memories/create">
				<Icon name="plus"></Icon>
			</Link>
		</Col>
		<Col sm={1} xs={2}>
			<Link to="/settings/notification">
				<Icon name="bell"></Icon>
			</Link>
		</Col>
		<Col sm={1} xs={2} className="hidden-sm hidden-md hidden-lg hidden-xl">
			<Icon name="bars" className="opacity-50"></Icon>
		</Col>
	</Row>
) : null;
	// };};

const keywordMap = {
	location: 'has:location',
	picture: 'has:picture',
	favorite: 'is:favorite',
};

function hasKeywordFilter(name, { keyword }) {
	const current = keywordMap[name];

	return (keyword.indexOf(current) >= 0);
}

function applyKeywordFilter(name, {keyword}) {
	const current = keywordMap[name];

	if (hasKeywordFilter(name, {keyword})) {
		return keyword.replace(`${current} `, '');
	} else {
		return `${current} ${keyword}`;
	}
};

const mapStateToProps = state => ({
	filters: state.memories.filters,
	isLoggedIn: state.auth.isLoggedIn,
	searchActive: state.memories.searchActive
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchMemories, filterMemories, clearFilters, toggleSearchMode
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuTopbar);