import { push } from 'connected-react-router';

import { 
	login as doLogin, 
	signup as doSignup, 
	logout as doLogout, 
	validateToken 
} from '../api/login'; 
import { 
	AUTH_SIGNING_UP, AUTH_SIGNUP_ERROR, AUTH_LOGGED_OUT,
	AUTH_LOGGING_IN, AUTH_LOGGED_IN, AUTH_LOGIN_ERROR,
	AUTH_VALIDATING, AUTH_VALIDATED,
} from '../constants';

export const login = (creds) => {
	return dispatch => {
		dispatch({
			type: AUTH_LOGGING_IN
		});

		return doLogin(creds).then(data => {
			dispatch({
				type: AUTH_LOGGED_IN,
				data
			});

			dispatch(push('/'));
		}).catch(err => {
			dispatch({
				type: AUTH_LOGIN_ERROR,
				data: err.response.data.errors
			});
		});
	};
};

export const logout = () => {
	return dispatch => {
		return doLogout().then(data => {
			dispatch({type: AUTH_LOGGED_OUT});
			dispatch(push('/auth'));
		}).catch(err => {
			console.log('err loggin out', err);
		});
	};
};

export const signup = (creds) => {
	return dispatch => {
		dispatch({
			type: AUTH_SIGNING_UP
		});

		return doSignup(creds).then(data => {
			dispatch({
				type: AUTH_LOGGED_IN,
				data
			});

			dispatch(push('/'));
		}).catch(err => {
			dispatch({
				type: AUTH_SIGNUP_ERROR,
				data: err.response.data.errors.full_messages
			});
		});
	};
};

export const validateUser = () => {
	return dispatch => {
		dispatch({
			type: AUTH_VALIDATING
		});

		return validateToken().then(data => {
			dispatch({
				type: AUTH_VALIDATED,
				data
			});
		}).catch(err => {
			if (err.response) {
				dispatch({
					type: AUTH_LOGIN_ERROR,
					data: err.response.data.errors
				});
			} else {
				dispatch({
					type: AUTH_LOGGED_OUT
				});
			}

			dispatch(push('/auth'));
		});
	};
};
