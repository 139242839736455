import { Icon } from 'react-fa';
import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';

import MemoryMap from '../location/map';
import SingleIcon from '../../components/icon/single';
import MemoryPictures from '../../components/memory/pictures';
import { googleMapURL } from '../../modules/location/helpers';
import { textColorClass, hasPictures } from '../../modules/memory/helpers';

export default class Editor extends Component {
	handleInput = (field) => (e) => {
		this.props.editMemory({[field]: e.target.value});
	};

	isCurrentSection (section) {
		return this.props.currentSection === section;
	};

	render () {
		const { memory, onClose } = this.props;

		return (
			<Row className="memory-editor secondary-bg">
				<Col md={12}>
					<Row className='top-actions'>
						<Col 
							xs={1} 
							className='text-left clickable'
							onClick={ onClose }>
							<Icon name="close" />
						</Col>
						<Col xs={10}></Col>
						<Col 
							xs={1} 
							className='text-right clickable'
							onClick={ () => this.props.isSaving ? null : this.props.createMemory( memory ) }>
							<Icon name={this.props.isSaving ? "spinner fa-spin" : "check"} />
						</Col>
					</Row>

					<Row>
						<form action="#">
							<div className="input-row">
								<input 
									type="text" 
									value={ memory.title || '' }
									placeholder="your memory"
									onChange={ this.handleInput('title') }/>
							</div>

							<div className="input-row">
								<textarea 
									rows="10"
									placeholder="note" 
									value={ memory.description || '' }
									onChange={ this.handleInput('description') }>
								</textarea>
							</div>
						</form>
					</Row>

					{ memory.location && (
						<Row className="memory-location bottom-padded">
							<Col md={12}>
								{memory.location.lat && memory.location.lng ? (
									<MemoryMap
										markers={[memory.location]} 
										googleMapURL={googleMapURL}
										loadingElement={ <Icon name="spinner fa-spin"></Icon> }
										containerElement={
											<div style={{ height: `20vh` }} />
										}
										mapElement={
											<div style={{ height: `20vh` }} />
											} />
								) : (
									<p>
										<Icon name="map-marker" />
										&nbsp;&nbsp;
										{memory.location.title}
										<br />
										<i>{memory.location.place_name}</i>
									</p>
								) }
							</Col>
						</Row>
					) }
			
					<MemoryPictures pictures={memory.pictures}/>

					<Row className='text-center top-padded'>
						<Col 
							xs 
							className={ textColorClass(memory.favorite ? memory.color : null, 'clickable') }
							onClick={() => this.props.editMemory({favorite: !memory.favorite})} >
							<Icon name='heart' />
						</Col>
						<Col 
							xs 
							className={ textColorClass(memory.timestamp ? memory.color : null, 'clickable') }
							onClick={() => !this.isCurrentSection('date') && this.props.showSection('date')} >
							<Icon name='calendar' />
						</Col>
						<Col 
							xs 
							className={ textColorClass(hasPictures(memory) ? memory.color : null, 'clickable') }
							onClick={() => !this.isCurrentSection('pictures') && this.props.showSection('pictures')} >
							<Icon name='camera' />
						</Col>
						<Col 
							xs
							className={textColorClass((memory.location && memory.location.title) ? memory.color : null, 'clickable')}
							onClick={() => !this.isCurrentSection('location') && this.props.showSection('location')} >
							<Icon name='map-marker' />
						</Col>
						<Col 
							xs 
							className={ textColorClass(memory.color, 'clickable') }
							onClick={() => !this.isCurrentSection('colors') && this.props.showSection('colors')} >
							<Icon name='paint-brush' />
						</Col>
						<Col 
							xs 
							className='clickable'
							onClick={() => !this.isCurrentSection('icons') && this.props.showSection('icons')} >
							<SingleIcon name={ memory.icon || 'globe' } fontSize="18" color={ memory.color } />
						</Col>
						<Col 
							xs 
							className='clickable'
							onClick={() => !this.isCurrentSection('jars') && this.props.showSection('jars')} >
							<Icon 
								name='bitbucket' 
								className={textColorClass((memory.jar) ? memory.color : null)} />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};
};