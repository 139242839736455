import React from 'react';
import moment from 'moment';
import { Row, Col } from 'react-flexbox-grid';

import Calendar from '../../components/calendar';

export default class Picker extends React.Component {
	handleChange = (date) => {
		this.props.onUpdate({timestamp: date.toDate()});
	};

	render () {
		const { timestamp } = this.props.data;
		const momstamp = moment(timestamp);
		console.log('date changed', momstamp.toDate());

		return (
			<Row around="xs" className="date-picker">
				<Col xs={12}>
					<Calendar 
						isPicker={true} 
						date={momstamp} 
						onChange={this.handleChange}
						onDateClick={this.handleChange}/>
				</Col>
			</Row>
		);
	}
};