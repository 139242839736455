import React from 'react';
import Icon from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import MoodCountChart from '../../containers/mood/count-chart';
import MoodCreator from '../../containers/mood/creator';
import MoodList from '../../containers/mood/list';
import Calendar from '../../components/calendar';

import { fetchMoods, changeViewType } from '../../modules/mood/reducers';
import { showPremiumPopup } from '../../modules/popup/reducers';
import { changeMood } from '../../modules/mood/reducers/editor';

class MoodHomePage extends React.Component {
    componentDidMount() {
        if (!this.props.isPremium) {
            return this.props.showPremiumPopup();
        }

        this.props.fetchMoods(
            this.props.startDate.clone().startOf('day').startOf('month'), 
            this.props.endDate.clone().endOf('day').endOf('month')
        );
    };

    handleMonthChange = (newMonth) => {
        const start = newMonth.clone().startOf('day').startOf('month'),
            end = newMonth.clone().endOf('day').endOf('month');

        this.props.fetchMoods(start, end);
    };

    render () {
        const { startDate, viewType } = this.props;

        return (
            <Row>
                <Col md={6} className='primary-container'>
                    <Row>
                        <Col xs={8}>
                            <h3 className="tm-5">Your Mood in { startDate.format('MMMM, YYYY') }</h3>
                        </Col>
                        <Col xs={4} className="text-right">
                            <div className="tm-5">
                                <Icon 
                                    name="pie-chart"
                                    className={`clickable lp-15 ${viewType === 'chart' ? 'favorite-text' : ''}`}
                                    onClick={() => this.props.changeViewType('chart')} />
                                <Icon 
                                    name="list"
                                    className={`clickable lp-15 ${viewType === 'list' ? 'favorite-text' : ''}`}
                                    onClick={() => this.props.changeViewType('list')} />
                            </div>
                        </Col>
                    </Row>

                    {(!this.props.isLoading && this.props.allMoods.length < 1) ? (
                        <Row className="card sp-15 hp-10">
                            <Col xs={12}>
                                <h4>No moods found.</h4>
                                <p>You have no moods in {startDate.format('MMM, YYYY')}. Try changing the calendar month. </p>
                            </Col>
                        </Row>
                    ) : viewType === 'list' 
                        ? (<Scrollbars style={{ height: '75vh' }}><MoodList /></Scrollbars>) 
                        : <MoodCountChart /> }
                </Col>

                <Col md={6} className='calendar-container'>
                    <Row>
                        <Col xs={12} className="bm-15">
                            <MoodCreator />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="secondary-bg bp-15">
                            <Calendar
                                date={this.props.startDate}
                                moods={this.props.allMoods}
                                onChange={this.handleMonthChange}
                                onDateClick={(created_at) => this.props.changeMood({ created_at })} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    user: state.auth.user,
    allMoods: state.moods.all,
    endDate: state.moods.endDate,
    viewType: state.moods.viewType,
    isPremium: state.auth.isPremium,
    isLoading: state.moods.isLoading,
    startDate: state.moods.startDate,
    availableMoods: state.moods.availableMoods,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMoods, showPremiumPopup, changeViewType, changeMood,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MoodHomePage);