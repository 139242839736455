import { push } from 'connected-react-router';

import { ADD_MEMORY } from './list';
import { saveMemory, getMemory } from '../api';
import { fetchLocations } from '../../location/reducers';

export const MEMORY_SAVED = 'memories/MEMORY_SAVED';
export const MEMORY_SAVING = 'memories/MEMORY_SAVING';
export const MEMORY_EDITING = 'memories/MEMORY_EDITING';

const initialState = {
	memory: {},
	isSaving: true
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MEMORY_SAVING:
			return {
				...state,
				isSaving: true
			};

		case MEMORY_SAVED:
			return {
				...state,
				memory: {},
				isSaving: false
			};

		case MEMORY_EDITING:
			return {
				...state,
				memory: {...state.memory, ...action.data},
				isSaving: false
			};

		default:
			return state;
	}
};

export const editMemory = (data) => {
	return dispatch => {
		dispatch({
			type: MEMORY_EDITING,
			data
		});
	};
};

export const loadInitialMemory = (id) => {
	return (dispatch, getState) => {
		if (id === null) {
			dispatch({ type: MEMORY_SAVED });
		}

		if (id) {
			return getMemory(id).then((data) => {
				dispatch({ type: MEMORY_EDITING, data });
			});
		}

		const {auth: {user}} = getState(),
			data = { timestamp: new Date() };

		if (user.color) {
			data.color = user.color;
			data.color_id = user.color.id;
		}

		if (user.icon) {
			data.icon = user.icon;
		}

		dispatch({ type: MEMORY_EDITING, data });
	};
};

export const createMemory = (memory) => {
	return dispatch => {
		if (!memory.title || memory.title.length < 3) {
			return alert('add memory title');
		}

		if (memory.location) {
			if (!memory.location.title || memory.location.title < 3)
				return alert('add location title');
		}

		dispatch({
			type: MEMORY_SAVING
		});

		return saveMemory(memory).then((res) => {
			dispatch({
				type: MEMORY_SAVED
			});

			dispatch({
				type: ADD_MEMORY,
				data: memory
			});

			if (memory.location) {
				dispatch(fetchLocations(true));
			}

			dispatch(push('/'));
		});
	};
};