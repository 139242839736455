import { getMemories } from '../api';
import { MEMORY_DELETED } from './single';

export const MEMORIES = 'memories/MEMORIES';
export const ADD_MEMORY = 'memories/ADD_MEMORY';
export const END_MEMORIES = 'memories/END_MEMORIES';
export const FILTER_MEMORY = 'memories/FILTER_MEMORY';
export const MORE_MEMORIES = 'memories/MORE_MEMORIES';
export const MEMORIES_REQUESTED = 'memories/MEMORIES_REQUESTED';
export const CLEAR_MEMORY_FILTER = 'memories/CLEAR_MEMORY_FILTER';
export const TOGGLE_MEMORY_SEARCH = 'memories/TOGGLE_MEMORY_SEARCH';

const defaultFilters = {
	keyword: ''
};

const initialState = {
	data: [],
	filters: defaultFilters,
	isLoading: true,
	hasEnded: false,
	searchActive: false
};

export default (state = initialState, action) => {
	// console.log('state, action', state, action);
	switch (action.type) {
		case MEMORIES_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case MEMORIES:
			return {
				...state,
				data: action.data,
				isLoading: false,
				hasEnded: false
			};

		case MORE_MEMORIES:
			return {
				...state,
				data: [...state.data, ...action.data],
				isLoading: false
			};

		case END_MEMORIES:
			return {
				...state,
				hasEnded: true
			};

		case ADD_MEMORY:
			return {
				...state,
				data: [...state.data, action.data],
			};

		case MEMORY_DELETED:
			// console.log(action, state.data);
			return {
				...state,
				data: state.data.filter(mem => mem.id !== parseInt(action.data)),
			};

		case FILTER_MEMORY:
			return {
				...state,
				filters: {...state.filters, ...action.data, hasFilters: true}
			};

		case CLEAR_MEMORY_FILTER:
			return {
				...state,
				searchActive: false,
				filters: defaultFilters
			};

		case TOGGLE_MEMORY_SEARCH:
			return {
				...state,
				searchActive: !state.searchActive
			};

		default:
			return state;
	}
};

export const fetchMemories = () => {
	return (dispatch, getState) => {
		dispatch({
			type: MEMORIES_REQUESTED
		});

		const { memories } = getState();

		return getMemories(memories.filters).then(data => {
			dispatch({
				type: MEMORIES,
				data
			});
		});
	};
};

export const loadMoreMemories = () => {
	return (dispatch, getState) => {
		const { memories } = getState();
		let { filters } = memories;
		
		return getMemories({...filters, offset: memories.data.length}).then(data => {
			if (data.length < 1) {
				return dispatch({type: END_MEMORIES});
			}

			dispatch({
				type: MORE_MEMORIES,
				data
			});
		});
	};
};

export const filterMemories = (filters) => {
	return dispatch => {
		return dispatch({
			type: FILTER_MEMORY,
			data: filters
		});
	};
};

export const clearFilters = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_MEMORY_FILTER,
		});

		return dispatch(fetchMemories());
	};
};

export const toggleSearchMode = () => {
	return dispatch => {
		dispatch({
			type: TOGGLE_MEMORY_SEARCH
		});
	};
};