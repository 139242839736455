import React, { Component } from 'react';
import Icon from 'react-fa';

class Loader extends Component {
    render() {
        return (
            <div className="text-center" style={{ width: '100%', height: '100%' }}>
                <Icon name="spinner fa-spin" style={{ fontSize: '3em' }} />
            </div>
        );
    };
};

export default Loader;