import { getMoods, remove } from '../api';
import moment from 'moment';

export const MOODS = 'memories/MOODS';
export const MOOD_SAVED = 'memories/MOOD_SAVED';
export const MOOD_REMOVED = 'memories/MOOD_REMOVED';
export const MOOD_REMOVING = 'memories/MOOD_REMOVING';
export const MOODS_REQUESTED = 'memories/MOODS_REQUESTED';
export const MOODS_VIEW_TYPE = 'memories/MOODS_VIEW_TYPE';

const DEFAULT_VIEW_TYPE = 'chart';

const initialState = {
    all: [],
    viewType: DEFAULT_VIEW_TYPE,
    startDate: moment(),
    endDate: moment(),
    isLoading: true,
    historyByDay: {},
    daysWithMood: [],
    availableMoods: [
        { name: 'happy', color: 'l' },
        { name: 'loved', color: 'p' },
        { name: 'meh', color: 'u' },
        { name: 'sad', color: 'm' },
        { name: 'angry', color: 'r' },
    ],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MOODS_REQUESTED:
            const { startDate, endDate } = action.data;

            return {
                ...state,
                isLoading: true,
                endDate, startDate,
            };

        case MOOD_REMOVING:
            return {
                ...state,
                isLoading: true,
            };
    
        case MOOD_REMOVED:
            return {
                ...state,
                isLoading: false,
                all: state.all.filter(m => m.id !== action.data.id),
                ...buildHistory(state.all.filter(m => m.id !== action.data.id))
            };

        case MOODS_VIEW_TYPE:
            return {
                ...state,
                viewType: action.data
            };

        case MOODS:
            return {
                ...state,
                all: action.data,
                isLoading: false,
                ...buildHistory(action.data),
            };

        case MOOD_SAVED:
            let updatedLocal = false;
            const all = state.all.map(m => {
                if (m.id === action.data.id) {
                    m = action.data;
                    updatedLocal = true;
                }

                return m;
            });

            if (!updatedLocal) {
                all.push(action.data);
            }

            return {
                ...state,
                all,
                ...buildHistory(all)
            };
            

        default:
            return state;
    }
};

function buildHistory(moods) {
    const historyByDay = {},
        daysWithMood = [];

    moods.forEach(mood => {
        const date = moment(mood.created_at),
            dateKey = date.format("DD MMM, YY | dddd");

        if (historyByDay[dateKey]) {
            // before adding it, make sure we don't already have it in local cache
            if (!historyByDay[dateKey].find(m => m.id === mood.id)) {
                historyByDay[dateKey].push(mood);
            }
        } else {
            daysWithMood.push(dateKey);
            historyByDay[dateKey] = [mood];
        }
    });

    return {historyByDay, daysWithMood};
};

export const fetchMoods = (startDate=moment(), endDate=moment()) => {
    return (dispatch) => {
        dispatch({
            type: MOODS_REQUESTED,
            data: { startDate, endDate }
        });

        const dateFormat = "YYYY-MM-DD HH:mm:ss",
            filters = { 
                from: startDate.format(dateFormat),
                to: endDate.format(dateFormat),
            };

        return getMoods(filters).then(data => {
            dispatch({
                type: MOODS, data
            });
        });
    };
};

export const removeMood = (mood={}) => {
    return (dispatch) => {
        dispatch({
            type: MOOD_REMOVING
        });

        return remove(mood).then(() => {
            dispatch({
                type: MOOD_REMOVED, data: mood
            });
        });
    };
};

// viewType can be chart or list
export const changeViewType = (type = DEFAULT_VIEW_TYPE) => {
    return dispatch => dispatch({
        type: MOODS_VIEW_TYPE,
        data: type,
    });
};