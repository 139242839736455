import axios from 'axios';
import store from 'store';

export default class Api {
	url = process.env.REACT_APP_JARMEWEB_API_URL;
	headerKey = 'auth.headers';
	userKey = 'auth.user';
	headers = null;
	user = null;

	constructor () {
		this.headers = store.get(this.headerKey);
		this.user = store.get(this.userKey);
	};

	setAuth (user, headers) {
		store.set(this.headerKey, headers);
		store.set(this.userKey, user);

		this.headers = headers;
		this.user = user;
	};

	get (endpoint, params={}) {
		const url = `${this.url}${endpoint}`;

		return axios.get(url, {
			params,
			responseType: 'json',
			headers: this.headers,
		});
	};

	getWithoutAuth (endpoint) {
		const url = `${this.url}${endpoint}`;

		return axios.get(url, {
			responseType: 'json'
		});
	};

	post (endpoint, data) {
		const url = `${this.url}${endpoint}`;

		return axios.post(url, data, {
			responseType: 'json',
			headers: this.headers
		});
	};

	postWithoutAuth (endpoint, data) {
		const url = `${this.url}${endpoint}`;

		return axios.post(url, data, {
			responseType: 'json'
		});
	};

	put (endpoint, data) {
		const url = `${this.url}${endpoint}`;

		return axios.put(url, data, {
			responseType: 'json',
			headers: this.headers
		});
	};

	putWithoutAuth (endpoint, data) {
		const url = `${this.url}${endpoint}`;

		return axios.put(url, data, {
			responseType: 'json'
		});
	};

	remove (endpoint) {
		const url = `${this.url}${endpoint}`;

		return axios.delete(url, {
			responseType: 'json',
			headers: this.headers
		});
	}
};