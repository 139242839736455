import React from 'react';
import {Icon} from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';
import AudioPlayer from "react-h5-audio-player";

import Jar from '../jar/single';
import TagList from '../tag/list';
import MemoryPictures from './pictures';
import SingleIcon from '../icon/single';
import WeatherIcon from '../weather/icon';
import MemoryMap from '../../containers/location/map';
import { googleMapURL } from '../../modules/location/helpers';
import { textColorClass, memoryClasses, hasDescription, resizeTitle, recordingUrl } from '../../modules/memory/helpers';

export default ({ memory, onDelete, onEdit, onClose }) => (
	<div className={ memoryClasses(memory, "memory-single") }>
		<Row className='top-actions'>
			<Col xs={6} className="text-left">
				<Icon  
					name="close"
					className="clickable"
					onClick={ onClose } />
			</Col>
			<Col xs={6} className="text-right">
				<Row 
					end='xs'
					className={ textColorClass(memory.color) }>
					<Col xs={2}>
						<Icon 
							name="trash"
							className="clickable"
							onClick={ onDelete } />
					</Col>
					<Col xs={2}>
						<Icon 
							name="pencil"
							className="clickable"
							onClick={ onEdit } />
					</Col>
				</Row>
			</Col>
		</Row>
		
		<Row className="text-center">
			<Col 
				md={12} 
				className={ textColorClass(memory.color, "icon-col") }>
				<SingleIcon name={memory.icon} color={memory.color} />
			</Col>
			<Col md={12}>
				<div className="memory-title">{ memory.title }</div>

				{ hasDescription(memory) && (
					<div className="memory-description text-left">
						<p>{ memory.description }</p>
					</div>
				) }
			</Col>
		</Row>
		
		<MemoryPictures pictures={memory.pictures} size="original"/>
			
		{memory.tags.length > 0 && (
			<div className="hp-25">
				<TagList tags={memory.tags}/>
			</div>
		)}

		{memory.recordings.length > 0 && (	
			<div className="hp-20">
				{memory.recordings.map(rec => (
					<AudioPlayer key={rec.id} src={recordingUrl(rec)} />
				))}
			</div>
		)}

		{ memory.jar && (
			<Jar jar={memory.jar} isSelected={false} onClick={() => null}/>
		) }

		{ memory.location && (
			<Row className="memory-location">
				<Col md={12}>
					{ memory.location.lat && memory.location.lng ? (
						<MemoryMap
							markers={[memory.location]} 
							googleMapURL={googleMapURL}
							loadingElement={ <Icon name="spinner fa-spin"></Icon> }
							containerElement={
								<div style={{ height: `220px` }} />
							}
							mapElement={
								<div style={{ height: `220px` }} />
							}/>
					) : (
						<p>
							<Icon name="map-marker"/>
							&nbsp;&nbsp;
							{ memory.location.title }
							<br />
							<i>{ memory.location.place_name }</i>
						</p>
					) }
				</Col>

				<Col className="hm-10 text-capitalize card" md={12}>
					<Row>
						<Col md={2} className="text-center">
							<Icon className="tm-15" name="map-marker" />
						</Col>
						<Col md={10}>
							<p className="hm-10">
								{memory.location.title}
								<br/>
								{memory.location.place_name || ''}
							</p>
						</Col>
					</Row>
					{ memory.weather && (
						<Row>
							<Col md={2} className="text-center">
								<WeatherIcon className="tm-15" name={memory.weather.name} />
							</Col>
							<Col md={10}>
								<p className="hm-10">
									{memory.weather.description || memory.weather.name}
									<br/>
									{ memory.weather.celsius }°C | Wind { memory.weather.wind } Km/h
								</p>
							</Col>
						</Row>
					) }
				</Col>
			</Row>
		) }
	</div>
);