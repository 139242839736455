import { getAllPictures } from '../api';

export const PICTURES = 'memories/PICTURES';
export const PICTURES_KEYWORD = 'memories/PICTURES_KEYWORD';
export const PICTURES_REQUESTED = 'memories/PICTURES_REQUESTED';

const initialState = {
	data: [],
	keyword: '',
	isLoading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PICTURES_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case PICTURES_KEYWORD:
			return {
				...state,
				keyword: action.data
			};

		case PICTURES:
			return {
				...state,
				isLoading: false,
				data: action.data,
			};

		default:
			return state;
	}
};

export const fetchPictures = (keyword) => {
	return dispatch => {
		dispatch({ type: PICTURES_REQUESTED });

		return getAllPictures(keyword).then((pictures) => {
			dispatch({
				type: PICTURES,
				data: pictures
			});
		});
	};
};

export const setPicturesKeyword = (keyword) => {
	return dispatch => {
		dispatch({
			type: PICTURES_KEYWORD,
			data: keyword
		});
	};
};
