import Api from '../api';

export function updateUser (user) {
	const api = new Api();

	return api.put('/auth', user).then((res) => {
		return res.data;
	});
};

export function userAvatar (user) {
	if (user) {
		if (user.picture) {
			return user.picture;
		} else if (user.picture_thumb) {
			const api = new Api();
			return api.url + user.picture_thumb;
		}
	}

	return null;
};