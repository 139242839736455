import React from 'react';
import { Icon } from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';

import JarmeIcon from "../icon/single";
import { pictureUrl } from '../../modules/memory/helpers';

export default ({jar, onClick, isSelected}) => {
    return (
        <div 
            onClick={ onClick }
            className={`${isSelected ? 'highlighted' : ''} bm-15 card jar-card`}
            style={{ backgroundImage: `url(${pictureUrl(jar)})` }}>
            <Row className="bp-5 tp-20 sp-15">
                <Col xs={10}>
                    <div>{jar.name}</div>
                    {jar.owner && (
                        <div className="jar-owner">by <span className="favorite-text">{jar.owner.name}</span></div>
                    )}
                </Col>

                <Col xs={2} className="text-right light-text">
                    <JarmeIcon name={jar.icon} fontSize='25px' color={{ value: '#E9EBEB' }} />
                </Col>
                
                {jar.description && (
                    <Col xs={12}>
                        <p className="jar-description pt-15">{jar.description}</p>
                    </Col>
                )}
            </Row>
            
            {jar.events && (
                <Row center="xs" className="hp-15 jar-stats">
                    <Col xs>
                        <Icon name="bookmark" />
                        &nbsp;&nbsp;{ jar.events.length }
                    </Col>
                    <Col xs>
                        <Icon name="user" />
                        &nbsp;&nbsp;{ jar.members.length }
                    </Col>
                    <Col xs>
                        <Icon name="heart" />
                        &nbsp;&nbsp;{ jar.events.length }
                    </Col>
                </Row>
            )}
        </div>
    );
};