import React from 'react';

export default ({ tags, onClick = () => null }) => {
    return (
        <div className="tag-list">
            {tags.map(tag => (
                <span 
                    className="tag-item tertiary-bg sp-20 hp-5 rm-10 bm-5 dib border-radius-15"
                    onClick={() => onClick(tag)}
                    key={tag.id}>
                    #{tag.name}
                </span>
            ))}
        </div>
    );
};