import React from 'react';
import { Icon } from 'react-fa';

export default (props) => {
    let { name } = props,
        iconName = 'sun-o';

    if (name.match(/snow|sleet/ig)) {
        iconName = 'snowflake-o';
    }

    if (name.match(/clouds/ig)) {
        iconName = 'cloud';
    }

    if (name.match(/thunderstorm/ig)) {
        iconName = 'flash';
    }

    if (name.match(/rain|drizzle/ig)) {
        iconName = 'umbrella';
    }

    return (
        <Icon {...props} name={iconName} />
    );
};