import { bindActionCreators } from 'redux';
import { Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Icon} from 'react-fa';
import React from 'react';

import { update, updateLocal } from '../../modules/user/reducers';
import { changeMenu } from "../../modules/app/reducers/menu";
import { userAvatar } from "../../modules/user/api";
import FileBase64 from '../picture/uploader';
import Avatar from '../../images/girl.png';
import Jar from '../../images/jar.png';

class Sidebar extends React.Component {
	state = {
		avatarChosen: null,
		avatarUploading: false,
		avatarPreview: null,
	};

	items = [{
		icon: 'book',
		text: 'Memories',
		link: '/'
	}, {
		icon: 'bitbucket',
		text: 'Jars',
		link: '/jars'
	}, {
		icon: 'calendar',
		text: 'Calendar',
		link: '/calendar'
	}, {
		icon: 'map-marker',
		text: 'Places',
		link: '/places'
	}, {
		icon: 'smile-o',
		text: 'Mood Tracker',
		link: '/moods',
	}, {
		icon: 'cog',
		text: 'Settings',
		link: '/settings'
	}];

	itemClass(item) {
		const cls = ['menu-sidebar-item'];

		if (this.props.currentMenu === item.link) {
			cls.push('active');
		}

		return cls.join(' ');
	};

	avatarChosen = (file) => {
		this.setState({ avatarChosen: file }, () => this.createPicturePreview());
	};

	updateAvatar = () => {
		this.setState({ avatarUploading: true });
		this.props.update({ ...this.props.user, picture: this.state.avatarChosen.base64 });
		setTimeout(() => {
			this.setState({ avatarUploading: false, avatarChosen: null, avatarPreview: null });
		}, 2500);
	};

	createPicturePreview = () => {
		if (!this.state.avatarChosen)
			return null;

		const width = 256,
			height = 256,
			sourceImage = new Image();

		sourceImage.onload = () => {
			// Create a canvas with the desired dimensions
			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;

			// Scale and draw the source image to the canvas
			canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height);

			// Convert the canvas to a data URL in PNG format
			this.setState({avatarPreview: canvas.toDataURL()});
		}

		sourceImage.src = this.state.avatarChosen.base64;
	};

	render() {
		const { isLoggedIn, user } = this.props;
		
		return (
			<Col className="menu-sidebar hidden-xs" sm={4} md={2}>
				<aside>
					<div className="menu-sidebar-top">
						<div className="menu-sidebar-top-avatar">
							{ isLoggedIn ? (
								<img 
									alt="user avatar" 
									src={ this.state.avatarPreview ? this.state.avatarPreview : userAvatar(user) || Avatar } 
									onClick={() => this._fileUploadBtn.inputBtn.click()}
									className="menu-sidebar-top-avatar-img clickable"/>
							) : (
								<img src={Jar} alt="logo" className="menu-sidebar-top-logo"/>
							)}
							<div className="menu-sidebar-top-avatar-upload-container text-center tp-10">
								{!this.state.avatarUploading &&
									<FileBase64
										ref={(btn) => this._fileUploadBtn = btn}
										multiple={false}
										onDone={this.avatarChosen}
									/>}

								{this.state.avatarChosen && !this.state.avatarUploading && <Icon
									name="check"
									className="clickable sp-10"
									onClick={this.updateAvatar}
								/>}

								{this.state.avatarChosen && !this.state.avatarUploading && <Icon
									name="times"
									className="clickable sp-10"
									onClick={() => this.setState({ avatarChosen: null, avatarPreview: null })}
								/>}
								{ this.state.avatarUploading && <Icon
									name="spin fa-spinner"
									className="clickable sp-5"
								/> }
							</div>

						</div>
						
						{ isLoggedIn && user ? (
							<div className="text-center">
								{user.name && <div>{ user.name }</div>}
								<div className="favorite-text">@{ user.nickname }</div>
							</div>
						) : (
							<div className="text-center"> JarMe </div>
						) }
					</div>
					
					{ isLoggedIn && user && (
					<ul>
						{this.items.map(i => (
							<li 
								key={ i.link }
								className={ this.itemClass(i) } 
								onClick={() => this.props.changeMenu(i.link)}>
								<Link to={i.link}>
									<span className="menu-sidebar-item-text">{i.text}</span>

									<Icon name={i.icon}></Icon>
								</Link>
							</li>
						))}
					</ul>
					) }
				</aside>
			</Col>
		);
	};
};

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,
	currentMenu: state.menu.current,
	user: state.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	changeMenu, update,
	updateLocal: (user) => dispatch(updateLocal(user)),
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sidebar);