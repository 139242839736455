import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { StripeProvider, Elements, CardElement, injectStripe } from 'react-stripe-elements';

import { purchase, fetchCurrentPremium } from '../../modules/premium/reducers';
import { hasPurchasedPremium } from '../../modules/premium/utils';
import PremiumFeatures from "../../components/premium/features";

class Premium extends React.Component {
    componentDidMount() {
        this.props.fetchCurrentPremium();
    };

    render () {
        const { isLoading, current } = this.props,
            hasPremium = hasPurchasedPremium(current);

        return (
            <Row className="setting-premium padded">
                <Col xs={8}>
                    <div>Premium Membership</div>
                </Col>
                <Col xs={4} className="text-right">
                    <div className="left-padded">
                        {isLoading ? (
                            <Icon name="spinner fa-spin" />
                        ) : (
                            <Icon name="shopping-cart" />
                        )}
                    </div>
                </Col>

                {isLoading ? (
                    <Col xs={12}>
                        <p className="text-bold tp-10">Loading Premium Experience...</p>
                    </Col>
                ) : (hasPremium ? (
                    <Col xs={12}>
                        <p className="text-bold tp-10">Congratulations!</p>

                        <p className="lh-15">
                            Thank you for being a premium member of jarme. 
                            We are super glad to have you onboard and working tirelessly to give you a smooth sailing experience.
                            Feel free to send us any feedback/comment you may have. 
                        </p>
                    </Col>
                ) : (
                    <StripeProvider apiKey={process.env.REACT_APP_JARMEWEB_STRIPE_KEY}>
                        <Elements>
                            <StripeForm 
                                onPurchase={this.props.purchase}
                                isLoading={isLoading}/>
                        </Elements>
                    </StripeProvider>
                ))}

                <Col xs={12}>
                    <h4 className="tp-15">Premium Features</h4>
                </Col>
                
                <PremiumFeatures />
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    current: state.premium.current,
    isLoading: state.premium.isLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({
    purchase, fetchCurrentPremium
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Premium);

class StripeFormComp extends React.Component {
    state = {
        confirmingPurchase: false,
        error: null
    };

    handleSubmit = (ev) => {
        ev.preventDefault();

        this.setState({ confirmingPurchase: true });
    };

    buy = () => {
        this.setState({confirmingPurchase: false});

        this.props.stripe.createToken().then(({ token: { card, id } }) => {
            if (!id || !card) {
                this.setState({ error: 'Please fill in your card details' });
                return;
            }

            this.setState({ error: null });
            this.props.onPurchase(id);
        });
    };

    render() {
        const { error } = this.state;

        return (
            <Col xs={12} className="height-padded">
                <p className="text-bold tm-0">Go Premium for <span className="favorite-text">only $29.99</span></p>

                <p className="lh-15">
                    With jarme premium membership, for a once off payment you get <span className="text-bold">Lifetime</span> access to amazing set of features and new features released after your purchase will automatically be available for you.
                </p>

                <div className="tp-10 bp-20">
                    <CardElement style={{
                        base: {
                            iconColor: '#E9EBEB',
                            color: '#E9EBEB',
                            lineHeight: '30px',
                            fontSize: '18px',
                            '::placeholder': { color: '#adadad' }
                        }
                    }} hidePostalCode={true} />
                </div>
                
                <button
                    onClick={this.props.isLoading ? () => null : this.handleSubmit}
                    className="btn block bordered tertiary-bg light-text primary-border clickable">
                    {this.props.isLoading ? (
                        <Icon name="spinner fa-spin" />
                    ) : (
                        <Icon name="shopping-cart" />
                    )}
                    &nbsp; Purchase For <span className="primary-text">$29.99</span> 
                </button>

                {error && (<div className="hp-15 favorite-text">{error}</div>)}

                <SweetAlert
                    show={this.state.confirmingPurchase}
                    showCancelButton
                    cancelButtonText="Never Mind"
                    confirmButtonText="Confirm"
                    title="Confirm Purchase"
                    text={`We are super happy to have you onboard. Just to make sure that you're 100% sure about the purchase, please press the confirm button below.`}
                    onConfirm={() => this.buy()}
                    onCancel={() => this.setState({ confirmingPurchase: false })}
                />
            </Col>
        );
    }
}

const StripeForm =  injectStripe(StripeFormComp);