import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';

import { ADD_JAR } from './list';
import { saveJar, getJar } from '../api';

export const JAR_SAVED = 'memories/JAR_SAVED';
export const JAR_SAVING = 'memories/JAR_SAVING';
export const JAR_EDITING = 'memories/JAR_EDITING';

const initialState = {
    jar: {},
    isSaving: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case JAR_SAVING:
            return {
                ...state,
                isSaving: true
            };

        case JAR_SAVED:
            return {
                ...state,
                jar: {},
                isSaving: false
            };

        case JAR_EDITING:
            console.log(state.jar, action.data);
            return {
                ...state,
                jar: { ...state.jar, ...action.data },
                isSaving: false
            };

        default:
            return state;
    }
};

export const editJar = (data) => {
    return dispatch => {
        dispatch({
            type: JAR_EDITING,
            data
        });
    };
};

export const loadInitialJar = (id) => {
    return (dispatch, getState) => {
        if (id === null) {
            dispatch({ type: JAR_SAVED });
        }

        if (id) {
            return getJar(id).then((data) => {
                dispatch({ type: JAR_EDITING, data });
            });
        }

        const { auth: { user } } = getState(),
            data = { icon: 'bitbucket', name: '', description: '' };

        if (user.icon) {
            data.icon = user.icon;
        }

        dispatch({ type: JAR_EDITING, data });
    };
};

export const createJar = (jar) => {
    return dispatch => {
        if (!jar.name || jar.name.length < 3 || jar.name.length > 30) {
            return alert('Please give a short name to your jar. Min: 3 | Max: ');
        }

        if (!jar.pictures || isEmpty(jar.pictures))
            return alert("every jar needs a picture. if you don't have one on your device, feel free to pick a nice on from the web");

        dispatch({
            type: JAR_SAVING
        });

        return saveJar(jar).then((res) => {
            dispatch({
                type: JAR_SAVED
            });

            dispatch({
                type: ADD_JAR,
                data: res.data
            });

            dispatch(push('/jars'));
        });
    };
};