import React from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Scrollbars } from 'react-custom-scrollbars';

import Loader from '../../components/utils/loader';
import MemoryCard from '../../components/memory/card';
import EmptyJarImage from '../../images/jar_empty.png';
import { fetchMemories, loadMoreMemories } from '../../modules/memory/reducers/list';

class List extends React.Component {
	componentWillMount() {
		this.props.fetchMemories();
	};

	componentDidMount() {
		if (this.props.memories.length > 0)
			this.props.showMemory(this.props.memories[0].id);
	};

	handleScroll = (values) => {
		const { scrollTop, scrollHeight, clientHeight } = values;
		const pad = 100; // 100px of the bottom
		// t will be greater than 1 if we are about to reach the bottom
		const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
		if (t > 1) {
			if (!this.props.hasEnded)
				this.props.loadMoreMemories();
		};
	};

	render () {
		if (this.props.isLoading) {
			return <Loader />;
		}

		if (this.props.memories.length < 1) {
			return (
				<div className="text-center memory-list-empty">
					<img className="memory-list-empty-image" src={EmptyJarImage} alt="jarme logo"/>
					<h2>Your jar is empty.</h2>
					<h3>
						<Link to="/memories/create" className="favorite-text">
							Create Memory
						</Link>
					</h3>
				</div>
			);
		}

		return (
			<Scrollbars
				style={{ height: '80vh' }}
				onUpdate={debounce(this.handleScroll, 300)}>
				<div className="memory-list">
					{ this.props.memories.map(memory => (
						<MemoryCard 
							onClick={this.props.showMemory }
							key={memory.id}
							memory={memory}>
						</MemoryCard>
					)) }
				</div>

				{ this.props.hasEnded && this.props.memories.length > 0 && (
					<div className="padded card">
						End of the jar....
					</div>
				) }
			</Scrollbars>
		);
	}
};

const mapStateToProps = state => ({
	memories: state.memories.data,
	isLoading: state.memories.isLoading,
	hasEnded: state.memories.hasEnded,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchMemories, loadMoreMemories,
	showMemory: (id) => push(`/memories/show/${id}`)
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);