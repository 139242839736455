import React from 'react';
import Icon from 'react-fa';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';

import Loader from '../../components/utils/loader';
import MoodImage from "../../components/mood/image";
import { removeMood } from "../../modules/mood/reducers";
import { changeMood } from "../../modules/mood/reducers/editor";

class List extends React.Component {
    state = {
        showActions: false,
        removingMood: null,
    };

    toggleActions = (showActions) => {
        this.setState({ showActions });
    };

    handleRemoveConfirm = () => {
        this.props.removeMood(this.state.removingMood)
            .then(() => this.setState({ removingMood: null }));
    };

    render () {
        const { historyByDay, daysWithMood, startDate, isLoading } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        if (!isLoading && daysWithMood.length < 1) {
            return (
                <div className="card">
                    <p>Looks like you don't have any mood registered in { startDate.format('MMMM, YY') }</p>
                </div>
            );
        };

        const { removingMood } = this.state;

        return (
            <div>
                <SweetAlert
                    showCancelButton
                    show={!!removingMood}
                    cancelButtonText="Cancel"
                    showLoaderOnConfirm={true}
                    confirmButtonText="Remove Mood"
                    onConfirm={this.handleRemoveConfirm}
                    title={`Remove Mood: ${removingMood ? removingMood.name : ''}`}
                    onCancel={() => this.setState({ removingMood: null })}
                    text={`Sure you want to remove your mood from ${removingMood ? moment(removingMood.created_at).format('MMM, YY') : ''}?`}
                />

                {daysWithMood.length > 0 ? daysWithMood.map(day => (
                    <div className="card bm-15 hp-15" key={day}>
                        <div className="mood-list-day-row border-1 bp-15 bm-20 tertiary-border">
                            <Col xs={10} xsOffset={2} className="tp-10 text-bold text-small">
                                { day }
                            </Col>
                        </div>

                        {historyByDay[day].map(mood => (
                            <Col 
                                xs={12}
                                key={mood.id}
                                className="bp-20 mood-details"
                                onMouseLeave={() => this.toggleActions(null)}
                                onMouseEnter={() => this.toggleActions(mood.id)}>
                                <Row>
                                    <Col xs={2} middle="xs" className="mood-list-image">
                                        <img
                                            src={MoodImage[mood.name]}
                                            alt={mood.name} />
                                    </Col>
                                    <Col xs={10}>
                                        <div className="text-small lh-15 bp-10 mood-list-note">
                                            { mood.note || `You were feeling ${mood.name}` }
                                        </div>
                                        
                                        <div className={`${mood.color}-text`}>
                                            <span className="text-small text-bold fl">
                                                { moment(mood.created_at).format('hh:mm a') }
                                            </span>

                                            {this.state.showActions === mood.id && (
                                                <span 
                                                    className="fr lm-10 mood-list-action clickable"
                                                    onClick={() => this.setState({ removingMood: mood })}>
                                                    <Icon name="remove" />
                                                    &nbsp; Remove
                                                </span>
                                            )}

                                            {this.state.showActions === mood.id && (
                                                <span 
                                                    className="fr lm-10 mood-list-action clickable"
                                                    onClick={() => this.props.changeMood({...mood, created_at: moment(mood.created_at)})}>
                                                    <Icon name="pencil" />
                                                    &nbsp; Change
                                                </span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </div>
                )) : (
                    <div className="card">
                        Sorry no moods found.
                    </div>
                )}
            </div>
        );
    };
};

List.propTypes = { 
    historyByDay: PropTypes.object.isRequired, 
    daysWithMood: PropTypes.array.isRequired, 
    changeMood: PropTypes.func.isRequired,
    removeMood: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired, 
    startDate: PropTypes.any.isRequired, 
};

const mapStateToProps = state => ({
    isLoading: state.moods.isLoading,
    startDate: state.moods.startDate,
    historyByDay: state.moods.historyByDay,
    daysWithMood: state.moods.daysWithMood,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMood, removeMood
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);