import store from 'store';

import Api from '../api';

const PICTURE_LIST = 'picture.list';

export function getAllPictures (keyword='') {
	const api = new Api(),
		data = {};

	if (keyword.length > 2)
		data.keyword = keyword;

	const local = getLocal(keyword);

	if (local)
		return new Promise((resolve) => resolve(local));

	return api.get('/pictures/internet', data).then((res) => {
		setLocal(keyword, res.data);
		return res.data;
	});
};

function getLocal (keyword) {
	const pictures = store.get(PICTURE_LIST) || {};

	if (pictures[keyword])
		return pictures[keyword];
	
	return null;
};

function setLocal (keyword, pictures) {
	const stored = store.get(PICTURE_LIST) || {};
	stored[keyword] = pictures;
	return store.set(PICTURE_LIST, stored);
};