import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Switch, Route, HashRouter as Router } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Home from '../home';
import Jars from '../jar/list';
import Signin from '../auth/login';
import Settings from '../settings';
import Calendar from '../calendar';
import MoodHome from '../mood/home';
import Places from '../location/places';
import JarCreator from '../jar/creator';
import MemoryCreator from '../memory/creator';
import MenuTopbar from '../../containers/menu/topbar';
import MenuSidebar from '../../containers/menu/sidebar';
import PremiumPopup from '../../containers/popup/premium';
import { validateUser } from '../../modules/auth/actions';

class App extends Component {
	state = {
		hasSetAnalyticsUserId : false
	};

	componentDidMount() {
		ReactGA.initialize(process.env.REACT_APP_JARMEWEB_GOOGLE_ANALYTICS_KEY);
		this.props.validateUser();	
	};

	componentDidUpdate() {
		if (this.props.user && this.props.user.id && !this.state.hasSetAnalyticsUserId) {
			ReactGA.set({ userId: this.props.user.id });
			this.setState({hasSetAnalyticsUserId: true});
		}
	};

	render () {
		return (
			<Grid fluid className="app-container">
				<PremiumPopup />
				<Row>
					{this.props.isLoggedIn && <MenuSidebar />}
			
					<Col className="content" sm={8} md={this.props.isLoggedIn ? 10 : 12}>
						<MenuTopbar/>

						<Row>
							<Col sm={12}>
								{ this.props.isValidating ? (
									<div>Loading.....</div>
								) : (
									<Router>
									<Switch>
										<Route 
											exact
											path='/'
											component={Home} />
										<Route 
											path='/memories/show/:id'
											component={ Home } />
										<Route 
											path='/memories/create/:section?'
											component={MemoryCreator} />
										<Route 
											path='/memories/edit/:id/:section?'
											component={MemoryCreator} />
										<Route 
											path='/settings/:section?'
											render={routeProps => <Settings {...this.props}/>} />
										<Route 
											path='/places'
											render={routeProps => <Places {...this.props}/>} />
										<Route 
											path='/calendar'
											render={routeProps => <Calendar {...this.props}/>} />
										<Route 
											path='/jars/create/:section?'
											component={JarCreator} />
										<Route 
											path='/jars/edit/:id/:section?'
											component={JarCreator} />
										<Route 
											path='/jars'
											render={routeProps => <Jars {...this.props}/>} />
										<Route 
											path='/moods'
											render={routeProps => <MoodHome {...this.props}/>} />
										<Route 
											exact
											path='/auth/:page?'
											component={Signin} />
									</Switch>
									</Router>
								) }
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		);
	};
};

const mapStateToProps = state => ({
	user: state.auth.user,
	isValidating: state.auth.isValidating,
	isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	validateUser
}, dispatch);

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(App));