import store from "store";
import moment from "moment";

import Api from '../api';

const allKey = 'locations.all';
const tsKey = 'locations.timestamp';

export function getAllLocations (force) {
	const lastSynced = store.get(tsKey);

	if (force || !lastSynced || moment().diff(moment(lastSynced), 'hours') > 6) {
		return syncDown();
	}

	return getCached();
};

export function findByKeyword (locations, keyword='') {
	if (!keyword || keyword.length < 1) {
		return locations;
	}	

	keyword = keyword.toLowerCase();
	return locations.filter(l => {
		if (l.title && l.title.toLowerCase().indexOf(keyword) >= 0)
		return true;
		
		if (l.place_name && l.place_name.toLowerCase().indexOf(keyword) >= 0)
			return true;
		
		return false;
	});
};

function syncDown() {
	const api = new Api();
	cleanCache();
	return api.get('/locations').then((res) => {
		setCached(res.data);
		return res.data;
	});
};

function getCached() {
	const locations = store.get(allKey);
	return Promise.resolve(locations);
};

function cleanCache() {
	[allKey, tsKey].forEach(k => store.remove(k));
};

function setCached(locations) {
	store.set(allKey, locations);
	store.set(tsKey, new Date());
};