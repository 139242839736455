import React from 'react';
import moment from 'moment';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import { fetchNotifications } from '../../modules/notification/reducers';
import NotificationLogo from '../../images/jar_logo_dark_knight.png';

class NotificationList extends React.Component {
    componentDidMount = () => {
        this.props.fetchNotifications();
    };

    render () {
        const { notifications } = this.props;

        return (
            <Scrollbars
                style={{ height: '80vh' }}>
                <div className="notification-list sp-10">
                    {notifications.map((notification, i) => {
                        const colorClass = notification.read_at ? 'favorite' : 'default',
                            createdAt = moment(notification.created_at);

                        return (
                            <div
                                className={`hm-15 card notification-card ${colorClass}-border`}
                                key={notification.id}>
                                <Row>
                                    <Col xs={3} className="text-center">
                                        <img src={NotificationLogo} alt="logo" className="notification-logo" />
                                    </Col>

                                    <Col xs={9} className="hp-10">
                                        <div className="text-capitalize bp-10 text-bold rp-10">{notification.title}</div>
                                        <div className="item-desc-text notification-desc lh-15 rp-10">{notification.body}</div>
                                        
                                        <div className="tp-15">
                                            <span className="primary-text">{createdAt.format('DD MMM')}</span>
                                            <Icon name="clock-o" className="sm-10" />
                                            <span className="favorite-text">{createdAt.fromNow()}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </div>
            </Scrollbars>
        );
    };
};

const mapStateToProps = state => ({
    notifications: state.notifications.data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchNotifications
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationList);