import React from 'react';
import { Icon } from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';

const features = [{
    icon: 'eye-slash',
    title: 'No Ads',
    desc: 'We will never show you any kind of advertisement',
}, {
    icon: 'cloud-upload',
    title: 'Unlimited image upload',
    desc: 'You can upload as many pictures as you like'
}, {
    icon: 'paint-brush',
    title: 'Black & White Theme',
    desc: 'Tired of the same look? change things up with our minimalistic B&W theme.'
}, {
    icon: 'smile-o',
    title: 'Mood Tracker',
    desc: 'Track your mood on a daily basis.'
}, {
    icon: 'bar-chart',
    title: 'Statistics',
    desc: 'Get an overview of your jarme usage such as: memories, icons etc..'
}, {
    icon: 'search',
    title: 'Advanced search',
    desc: 'Quickly find your memories through advanced search filters.'
}, {
    icon: 'microphone',
    title: 'Voice recording',
    desc: 'Upload unlimited voice recording files.'
}, {
    icon: 'cloud',
    title: 'Weather',
    desc: 'Automatically get weather information for memories with location.'
}];

export default () => features.map(feat => (
    <Col xs={12} key={feat.icon}>
        <Row>
            <Col xs={1} className="text-center">
                <Icon name={feat.icon} className="tm-10" />
            </Col>
            <Col xs={11}>
                <h4 className="text-bold tm-10 bm-5">{feat.title}</h4>
                <p>{feat.desc}</p>
            </Col>
        </Row>
    </Col>
));