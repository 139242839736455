import { save } from '../api';
import moment from 'moment';

export const MOOD_CHANGED = 'memories/MOOD_CHANGED';
export const MOOD_SAVING = 'memories/MOOD_SAVING';
export const MOOD_SAVED = 'memories/MOOD_SAVED';

const DEFAULT_MOOD = {
    created_at: moment(),
    note: "",
};

const initialState = {
    current: DEFAULT_MOOD,
    isSaving: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MOOD_SAVING:
            return {
                ...state,
                isSaving: true
            };

        case MOOD_SAVED:
            return {
                ...state,
                isSaving: false,
                current: DEFAULT_MOOD,
            };

        case MOOD_CHANGED:
            return {
                ...state,
                current: {...state.current, ...action.data},
            };

        default:
            return state;
    }
};

export const changeMood = (mood) => {
    return dispatch => {
        return dispatch({
            type: MOOD_CHANGED,
            data: mood
        });
    };
};

export const saveMood = (mood) => {
    return dispatch => {
        dispatch({
            type: MOOD_SAVING,
        });

        return save(mood).then((res) => {
            dispatch({
                type: MOOD_SAVED,
                data: res.data
            });
        });
    };
};