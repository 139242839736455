import { Icon } from 'react-fa';
import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';

import allIcons from './data';

class Picker extends Component {
	state = {
		keyword: '',
		icons: allIcons
	};

	componentDidMount () {

	};

	setIconKeyword (keyword) {
		const icons = allIcons.filter(i => {
			if (i.name.toLowerCase().indexOf(keyword) >= 0)
				return true;
			
			if (i.id.toLowerCase().indexOf(keyword) >= 0)
				return true;
			
			if (i.filter && i.filter.filter(f => f.indexOf(keyword) >= 0).length > 0)
				return true;
			
			return false;
		});

		this.setState({keyword, icons});
	};

	render () {
		const { data, onUpdate } = this.props,
			{ icon } = data;

		return (
			<Row 
				around="xs" 
				style={{ paddingTop: '20px' }}
				className="icon-picker">
				
				<Col xs={12}>	
					<div className="input-row padded">
						<input
							type="text"
							name="keyword"
							value={this.state.keyword}
							placeholder="type a keyword: rain, bike etc."
							onChange={(e) => this.setIconKeyword(e.target.value)} />
					</div>
				</Col>

				<Col xs={12}>	
					<Row>
					{ this.state.icons.map(i => (
						<Col 
							xs={2} 
							key={ i.unicode }
							className='clickable'
							style={{
								height: '50px', 
								marginBottom: '15px', 
								textAlign: 'center', 
								fontSize: i.id === icon ? '2em' : '1.5em'
							}}
							onClick={ () => onUpdate({icon: `fa-${i.id}`}) }>
							<Icon name={ i.id }></Icon>
						</Col>
					)) }
					</Row>
				</Col>
			</Row>
		);
	};
};

export default Picker;