import { Row, Col } from 'react-flexbox-grid';
import { Icon } from 'react-fa';
import React from 'react';

import { pictureUrl, memoryClasses, memoryDate, hasDescription, resizeTitle, resizeDescription } from '../../modules/memory/helpers';
import WeatherIcon from '../weather/icon';
import SingleIcon from '../icon/single';

export default (props) => {
	const { memory, onClick } = props;

	const bgStyles = { 
		backgroundImage: pictureUrl(memory, 'bg'),
	};
	return (
		<div 
			className={ memoryClasses(memory) } 
			onClick={ () => onClick(memory.id) }>
			<div className="memory-bg" style={ bgStyles }></div>
			<Row style={{ zIndex: 99 }}>
				<Col md={3} lg={2} className="text-center icon-col">
					<div><SingleIcon 
						name={memory.icon} 
						fontSize='30px' 
						color={memory.color}/>
					</div>
					<div className="memory-card-date">
						<span className="memory-card-date-day">{ memoryDate(memory) }</span>
						&nbsp;{ memoryDate(memory, 'MMM') }
					</div>
				</Col>
				<Col md={9} lg={10}>
					<div className="memory-title">{ resizeTitle(memory) }</div>
					{ hasDescription(memory) && (
						<div className="memory-description">
							{ resizeDescription(memory) }
						</div>
					) }

					<div className="extra-row">
						{ memory.location && (
							<Row>
								<Col md={1} className="text-center"><Icon name="map-marker"></Icon></Col>
								<Col md={11}>
									{memory.location.title && <div>{memory.location.title}</div>}
									{memory.location.place_name && <div>{memory.location.place_name}</div>}
								</Col>
							</Row>
						) }

						{ memory.weather && (
							<Row>
								<Col md={1} className="text-center">
									<WeatherIcon className="tm-15" name={memory.weather.name} />
								</Col>
								<Col md={11}>
									<p className="hm-10">
										{memory.weather.description || memory.weather.name}
										<br />
										{memory.weather.celsius}°C | Wind {memory.weather.wind} Km/h
									</p>
								</Col>
							</Row>
						) }
						
						{ memory.jar && (
							<Row>
								<Col md={1} className="text-center">
									<SingleIcon 
										name={ memory.jar.icon }
										fontSize='15px'
										color={{value: '#e9ebeb'}}/>
								</Col>
								<Col md={11}>
									<div>{memory.jar.name}</div>
								</Col>
							</Row>
						) }
					</div>
				</Col>
			</Row>
		</div>
	);
};