const MENU_CHANGE = 'menu/MENU_CHANGE';

const initialState = {
    current: '/'
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MENU_CHANGE:
			return {
                ...state,
                current: action.data
			};

		default:
			return state;
	}
};

export function changeMenu (newPage) {
    return {
        type: MENU_CHANGE,
        data: newPage
    };
};