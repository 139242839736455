import { updateUser } from '../api';

export const UPDATE_LOCAL = 'user/UPDATE_LOCAL';
export const UPDATE_SERVER_REQUESTED = 'user/UPDATE_SERVER_REQUESTED';
export const UPDATE_SERVER_COMPLETED = 'user/UPDATE_SERVER_COMPLETED';

const initialState = {
	data: {},
	isLoading: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_LOCAL:
			return {
				...state,
				data: action.data
			};

		case UPDATE_SERVER_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case UPDATE_SERVER_COMPLETED:
			return {
				...state,
				data: action.data,
				isLoading: false
			};

		default:
			return state;
	}
};

export const updateLocal = (data) => {
	return {
		type: UPDATE_LOCAL,
		data
	};
};

export const update = (user) => {
	return dispatch => {
		dispatch({
			type: UPDATE_SERVER_REQUESTED
		});

		return updateUser(user).then((user) => {
			dispatch({
				type: UPDATE_SERVER_COMPLETED,
				data: user
			});
		});
	};
};
