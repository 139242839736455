export function colorClasses (color, initial='') {
	const cls = ['color-box', initial];

	cls.push(`${color.name}-bg`);

	return cls.join(" ");	
};

export function nameToHex(name) {
	const colors = {
		a: "#e84a5f",
		b: "#ff847c",
		c: "#fecea8",
		e: "#ff6100",
		f: "#dfe3cb",
		g: "#88dcd9",
		h: "#c8c564",
		i: "#3f51b5",
		j: "#c22326",
		k: "#3b69ff",
		l: "#239a6c",
		m: "#04a4ba",
		n: "#8bc34a",
		o: "#c22d41",
		p: "#c82b7c",
		q: "#93323f",
		r: "#eca639",
		s: "#388bc5",
		t: "#4e1abb",
		u: "#5f6dd4",
		v: "#e15b32",
		w: "#839f2f",
		x: "#56aaae",
	};

	return colors[name] || colors.a;
};