import moment from 'moment';

import { getMemories } from '../api';

export const CALENDAR_MONTH = 'memories/CALENDAR_MONTH';
export const CALENDAR_MEMORIES = 'memories/CALENDAR_MEMORIES';

const initialState = {
    date: moment(),
    memories: [],
    isLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CALENDAR_MONTH:
            return {
                ...state,
                date: action.data,
                isLoading: true
            };

        case CALENDAR_MEMORIES:
            return {
                ...state,
                memories: action.data,
                isLoading: false
            };

        default:
            return state;
    }
};

export const loadCalendar = (date) => {
    return (dispatch) => {
        dispatch({
            type: CALENDAR_MONTH,
            data: date
        });

        const filters = { 
            from: date.startOf('month').startOf('day').format("YYYY-MM-DD HH:mm:ss"), 
            to: date.endOf('month').endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            offset: 0 
        };

        return getMemories(filters).then(data => {
            setTimeout(() => dispatch({
                type: CALENDAR_MEMORIES,
                data
            }), 1000);
        });
    };
};