import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createHistory from 'history/createHashHistory';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';

import rootReducer from './modules';

export const history = createHistory()

history.listen((location, action) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

const initialState = {}
const enhancers = []
const middleware = [
	thunk,
	routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.devToolsExtension

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension())
	}
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
)

const store = createStore(
	connectRouter(history)(rootReducer),
	initialState,
	composedEnhancers
)

export default store