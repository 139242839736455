import React from 'react';
import { Icon } from 'react-fa';
import { Row, Col } from 'react-flexbox-grid';

import SingleIcon from '../../components/icon/single';
import { colorClasses } from '../../modules/color/helpers';

export default class Account extends React.Component {
	render () {
		const { data, updateLocal, update, isLoading } = this.props;

		return (
			<Row className="setting-account padded">
				<Col xs={8}>
					<div>Accounts</div>
				</Col>
				<Col xs={4} className="text-right">
					<div 
						className="left-padded clickable"
						onClick={ !isLoading ? () => update(data) : null }>
						{ isLoading ? (
							<Icon name="spinner fa-spin"/>
						) : (
							<Icon name="check"/>
						) }
					</div>
				</Col>

				<Col xs={12} className="height-padded">
					<Row className="height-padded">
						<Col xs={6}>
							Email
						</Col>
						<Col xs={6} className="text-right">
							<div>
								{ data ? data.email : '' }
							</div>
						</Col>
					</Row>

					<Row className="height-padded">
						<Col xs={6}>
							Username
						</Col>
						<Col xs={6}>
							<div className="input-row">
								<input 
									type="text" 
									className="text-right"
									value={ data.nickname }
									onChange={ (e) => updateLocal({ ...data, nickname: e.target.value }) }/>
							</div>
						</Col>
					</Row>

					<Row className="height-padded">
						<Col xs={6}>
							Name
						</Col>
						<Col xs={6}>
							<div className="input-row">
								<input 
									type="text" 
									className="text-right"
									value={ data.name }
									onChange={ (e) => updateLocal({ ...data, name: e.target.value }) }/>
							</div>
						</Col>
					</Row>

					<Row className="height-padded">
						<Col xs={6}>
							Default Icon
						</Col>
						<Col xs={6} className="text-right">
							<SingleIcon 
								name={ data.icon } 
								color={ data.color }
								fontSize="20px" />
						</Col>
					</Row>

					<Row className="height-padded">
						<Col xs={6}>
							Default Color
						</Col>
						{data && data.color && (
							<Col xs={6}>
								<div className={colorClasses(data.color, 'fr small')}>
									
								</div>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
		);
	};
};