import { getMemory, removeMemory } from '../api';

export const MEMORY_REQUESTED = 'memories/MEMORY_REQUESTED';
export const MEMORY_DELETED = 'memories/MEMORY_DELETED';
export const MEMORY = 'memories/MEMORY';

const initialState = {
	memory: {},
	isLoading: true
};

export default (state = initialState, action) => {
	switch (action.type) {
		case MEMORY_REQUESTED:
			return {
				...state,
				isLoading: true
			};

		case MEMORY:
			return {
				...state,
				memory: action.data,
				isLoading: false
			};

		default:
			return state;
	}
};

export const fetchMemory = (id) => {
	return dispatch => {
		dispatch({
			type: MEMORY_REQUESTED
		});

		return getMemory(id).then((memory) => {
			dispatch({
				type: MEMORY,
				data: memory
			});
		});
	};
};

export const deleteMemory = (id) => {
	return dispatch => {
		return removeMemory(id).then((memory) => {
			dispatch({ type: MEMORY_DELETED, data: id });
		});
	};
};