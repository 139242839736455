import React from 'react';

import { pictureUrl, hasPictures } from '../../modules/memory/helpers';

export default (props) => {
	if (!hasPictures(props)) {
		return null;
	}

	const { pictures, size } = props;

	return (
		<div className='memory-pictures bottom-padded'>
			{ pictures.map(picture => (
				<div 
					key={pictureUrl(props, null, size)}
					className="memory-picture">
					<img src={ pictureUrl(props, null, size) } alt=""/>
				</div>
			)) }				
		</div>
	);
};