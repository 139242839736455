import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import { Scrollbars } from 'react-custom-scrollbars';

import MemoryMap from '../../containers/location/map';
import LocationList from '../../containers/location/list';
import { googleMapURL } from '../../modules/location/helpers';
import { fetchLocations, searchLocations, clearSearch } from '../../modules/location/reducers';

class Places extends React.Component {
    state = {
        selected: null
    };

    componentWillMount() {
        this.props.fetchLocations();
    };

    render() {
        const { locations, isLoading, keyword, searchLocations, clearSearch } = this.props;

        return (
            <Row>
                {!isLoading && (
                    <Col md={7} className='primary-container'>
                        <MemoryMap
                            googleMapURL={googleMapURL}
                            loadingElement={<Icon name="spinner fa-spin"></Icon>}
                            containerElement={
                                <div style={{ height: `80vh` }} />
                            }
                            mapElement={
                                <div style={{ height: `80vh` }} />
                            }
                            onMapClick={({ latLng }) => console.log(latLng)}
                            selected={ this.state.selected }
                            markers={locations.filter(l => l.lat && l.lng)} />
                    </Col>
                )}

                <Col md={5} className='secondary-bg'>
                    <Row className="sp-15 hp-20">
                        <Col xs={ 11 }>
                            <div className="input-row">
                                <input
                                    type="text"
                                    name="keyword"
                                    value={ keyword }
                                    placeholder="type a keyword: Home, Work etc."
                                    onChange={ (e) => searchLocations(e.target.value) } />
                            </div>
                        </Col>
                        <Col xs={ 1 } className="text-right">
                            { keyword.length > 2 ? (
                                <Icon 
                                    name="remove"
                                    className="clickable tm-10" 
                                    onClick={ clearSearch }/>
                            ) : (
                                <Icon
                                    name="remove"
                                    className="tm-10 opacity-50"
                                    title="Needs at least 2 letters for searching" />  
                            ) }
                        </Col>
                    </Row>

                    { isLoading ? (
                        <div className="text-center">
                            <Icon name="spin fa-spinner" />
                        </div>
                    ) : locations.length < 1 ? (
                        <div className="hp-10 sp-10">
                            <h4>No saved places found.</h4>
                            { keyword.length > 2 ? (
                                <p>You have no saved place that matches your search keyword { keyword }.</p>
                            ) : (
                                <p>Create some memories and add location to them to see a map of the places you've been to.</p>
                            ) }
                        </div>
                    ) : (
                        <Scrollbars 
                            style={{ height: '70vh' }}
                            renderTrackHorizontal={props => <div style={{ display: 'none' }} />}>
                            <LocationList 
                                locations={ locations } 
                                onSelect={({ location }) => this.setState({ selected: location })}/> 
                        </Scrollbars>
                    ) }
                </Col>
            </Row>
        );
    };
};

const mapStateToProps = state => ({
    locations: state.locations.data,
    keyword: state.locations.keyword,
    isLoading: state.locations.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchLocations, searchLocations, clearSearch
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Places);