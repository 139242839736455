import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer, toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import React from 'react';

import 'normalize.css';
import 'sweetalert/dist/sweetalert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';

import './styles/index.scss';

import registerServiceWorker from './registerServiceWorker';
import store, { history } from './store';
import App from './pages/app';

if (window.require) {
	const electron = window.require('electron');
	const fs = electron.remote.require('fs');
	
	console.log('electron', electron);
	electron.ipcRenderer.on('update.status', function (event, text) {
		console.log(text, event);
		toast(`Jarme Update: ${text}`);
	});
}

registerServiceWorker();

const target = document.querySelector('#root');

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<div>
				<App />
				<ToastContainer />
			</div>
		</ConnectedRouter>
	</Provider>,
	target
);