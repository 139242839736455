import { combineReducers } from 'redux'
// import { routerReducer } from 'connected-react-router'

import auth from './auth/reducers';
import user from './user/reducers';
import moods from './mood/reducers';
import colors from './color/reducers';
import popups from './popup/reducers';
import menu from './app/reducers/menu';
import jars from './jar/reducers/list';
import premium from './premium/reducers';
import pictures from './picture/reducers';
import locations from './location/reducers';
import memories from './memory/reducers/list';
import memory from './memory/reducers/single';
import jarEditor from './jar/reducers/editor';
import moodEditor from './mood/reducers/editor';
import calendar from './memory/reducers/calendar';
import subscription from './subscription/reducers';
import notifications from './notification/reducers';
import memoryEditor from './memory/reducers/editor';

export default combineReducers({
	// routing: routerReducer, 
	auth, user,
	menu,
	jars, jarEditor,
	memories, memory, memoryEditor, calendar,
	colors, locations, pictures,
	subscription, premium,
	moods, moodEditor,
	notifications,
	popups,
});