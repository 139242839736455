import React from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { Row, Col } from 'react-flexbox-grid';

import { nameToHex } from "../../modules/color/helpers";

const Chart = ({ availableMoods, allMoods, startDate, isLoading }) => {
    const counts = {};

    availableMoods.forEach(mood => {
        counts[mood.name] = {
            value: 0,
            id: mood.name,
            label: mood.name,
            color: nameToHex(mood.color),
        };
    });

    allMoods.forEach(mood => {
        counts[mood.name].value += 1;
    });

    return (
        <Row className="card tp-10 bp-50" style={{ height: '50vh', minHeight: '300px' }}>
            <Col md={12}>
                <h4 className="text-center sp-15">
                    Slices of your mood count in <span className="favorite-text">{startDate.format('MMM, YYYY')}</span>
                </h4>
                <ResponsivePie
                    data={Object.values(counts)}
                    margin={{
                        "top": 30,
                        "right": 70,
                        "bottom": 80,
                        "left": 70
                    }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    colorBy={(m) => m.color}
                    borderWidth={1}
                    borderColor="inherit:darker(0.2)"
                    radialLabelsSkipAngle={10}
                    radialLabelsTextXOffset={6}
                    radialLabelsTextColor="#bfbfbf"
                    radialLabelsLinkOffset={0}
                    radialLabelsLinkDiagonalLength={16}
                    radialLabelsLinkHorizontalLength={24}
                    radialLabelsLinkStrokeWidth={1}
                    radialLabelsLinkColor="inherit"
                    slicesLabelsSkipAngle={10}
                    slicesLabelsTextColor="#333333"
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[
                        {
                            "anchor": "bottom",
                            "direction": "row",
                            "translateY": 56,
                            "itemWidth": 60,
                            "itemHeight": 18,
                            "itemTextColor": "#bfbfbf",
                            "symbolSize": 14,
                            "symbolShape": "circle"
                        }
                    ]}
                />
            </Col>
        </Row>
    );
};

const mapStateToProps = state => ({
    allMoods: state.moods.all,
    endDate: state.moods.endDate,
    isLoading: state.moods.isLoading,
    startDate: state.moods.startDate,
    availableMoods: state.moods.availableMoods,
});

export default connect(
    mapStateToProps
)(Chart);