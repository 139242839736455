import React from 'react';
import { Icon } from 'react-fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { signup } from '../../modules/auth/actions';

class Signup extends React.Component {

	state = {
		creds: {
			email: '',
			nickname: '',
			password: '',
			password_confirm: ''
		}
	};

	handleInput = (field) => (e) => {
		const { creds } = this.state;
		this.setState({ creds: {...creds, [field]: e.target.value } });
	};

	handleSubmit = (e) => {
		e.preventDefault();

		if (!this.props.isSigningUp)
			this.props.signup(this.state.creds);
	};

	render () {
		return (
			<form action="#">
				<div className="input-row">
					<input 
						type="text" 
						placeholder="Username"
						onChange={ this.handleInput('nickname') }/>
				</div>

				<div className="input-row">
					<input 
						type="email" 
						placeholder="email address"
						onChange={ this.handleInput('email') }/>
				</div>

				<div className="input-row">
					<input 
						type="password" 
						placeholder="password"
						onChange={ this.handleInput('password') }/>
				</div>

				<div className="input-row">
					<input 
						type="password" 
						placeholder="confirm password"
						onChange={ this.handleInput('password_confirm') }/>
				</div>

				<div className="input-row">
					<button 
						onClick={ this.handleSubmit }
						className="btn block bordered tertiary-bg light-text primary-border clickable">
						{ this.props.isSigningUp ? (
							<Icon name='spinner fa-spin'></Icon>
						) : 'SIGN UP' }
					</button>
				</div>

				<div className="errors">
					{ this.props.errors.map((err, i) => (
						<div className="error bm-5" key={i}>{ err }</div>
					)) }
				</div>
			</form>
		);
	};
};

const mapStateToProps = state => ({
	isSigningUp: state.auth.isSigningUp,
	user: state.auth.user,
	errors: state.auth.errors
});

const mapDispatchToProps = dispatch => bindActionCreators({
	signup,
}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Signup);