import React from 'react';
import moment from 'moment';
import { Row, Col } from 'react-flexbox-grid';

import UserAvatar from './avatar';
import SingleIcon from '../icon/single';

export default ({ user }) => {
    return (
        <div className="sp-15 hp-15 card">
            <Row>
                <Col xs={3} sm={2}>
                    <UserAvatar 
                        gender={user.gender} 
                        picture_thumb={user.picture_thumb}/>
                </Col>

                <Col xs={9} sm={10}>
                    {user.icon && (
                        <div className="fr">
                            <SingleIcon name={user.icon} fontSize="18" color={{ value: '#ffffff' }} />
                        </div>
                    )}
                    <div className="hp-5"><b>{user.name}</b></div>
                    <div className="hp-5 favorite-text">@{user.nickname}</div>
                    <div><i>Joined: {moment(user.created_at).fromNow()}</i></div>
                </Col>
            </Row>
        </div>
    );
};