import { getJars, findByKeyword, removeJar, toggleMute, leaveJar as leave } from '../api';

export const JARS = 'memories/JARS';
export const ADD_JAR = 'memories/ADD_JAR';
export const JAR_MUTE = 'memories/JAR_MUTE';
export const JAR_REMOVED = 'memories/JARS_REMOVED';
export const JARS_SEARCHED = 'memories/JARS_SEARCHED';
export const JARS_REQUESTED = 'memories/JARS_REQUESTED';
export const JARS_SEARCH_CLEARED = 'memories/JARS_SEARCH_CLEARED';

const initialState = {
    data: [],
    keyword: '',
    isLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case JARS_REQUESTED:
            return {
                ...state,
                isLoading: true
            };

        case JARS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
            };

        case JAR_REMOVED:
            return {
                ...state,
                data: state.data.filter(({id}) => id !== action.data),
                isLoading: false,
            };

        case ADD_JAR:
            return {
                ...state,
                data: [...state.data, action.data],
            };

        case JARS_SEARCHED:
            return {
                ...state,
                keyword: action.data,
                data: findByKeyword(state.data, action.data)
            };

        case JARS_SEARCH_CLEARED:
            return {
                ...state,
                keyword: '',
                data: state.data
            };

        default:
            return state;
    }
};

export const fetchJars = () => {
    return (dispatch) => {
        dispatch({
            type: JARS_REQUESTED
        });

        return getJars().then(data => {
            dispatch({
                type: JARS, data
            });
        });
    };
};

export const deleteJar = (id) => {
    return (dispatch) => {
        dispatch({
            type: JARS_REQUESTED
        });

        return removeJar(id).then(data => {
            dispatch({
                type: JAR_REMOVED, data: id
            });
        });
    };
};

export const leaveJar = (id) => {
    return (dispatch) => {
        dispatch({
            type: JARS_REQUESTED
        });

        return leave(id).then(data => {
            dispatch({
                type: JAR_REMOVED, data: id
            });
        });
    };
};

export const muteJar = (id, access) => {
    return (dispatch) => {
        return toggleMute(id, access);
    };
};

export const searchJars = (keyword) => {
    return {
        type: JARS_SEARCHED,
        data: keyword
    };
};

export const clearSearch = () => {
    return {
        type: JARS_SEARCH_CLEARED,
    };
};