import React from 'react';

import Boy from '../../images/boy.png';
import Girl from '../../images/girl.png';
import { userAvatar } from '../../modules/user/api';

export default ({ gender = 'f', picture, picture_thumb, picture_original }) => {
    const avatar = userAvatar({gender, picture, picture_thumb});

    return (
        <img 
            alt="user avatar"
            className="user-avatar-image"
            src={avatar || (gender === 'm' ? Boy : Girl)} />
    );
};