import React from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import { bindActionCreators } from 'redux';

import { popupCancelled, popupConfirmed } from '../../modules/popup/reducers';

class PremiumPopup extends React.Component {
    render () {
        return (
            <SweetAlert
                showCancelButton
                text={this.props.text}
                show={this.props.show}
                title={this.props.title}
                cancelButtonText={this.props.cancelButtonText}
                confirmButtonText={this.props.confirmButtonText}
                onConfirm={() => this.props.popupConfirmed()}
                onCancel={() => this.props.popupCancelled()}
            />
        );
    };
};

const mapStateToProps = state => ({
    confirmButtonText: state.popups.confirmButtonText,
    cancelButtonText: state.popups.cancelButtonText,
    title: state.popups.title,
    show: state.popups.show,
    text: state.popups.text,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    popupCancelled, popupConfirmed
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PremiumPopup);