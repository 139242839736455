import { Icon } from 'react-fa';
import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';

import SingleIcon from '../../components/icon/single';
import MemoryPictures from '../../components/memory/pictures';
import { textColorClass, hasPictures } from '../../modules/memory/helpers';

export default class Editor extends Component {
    handleInput = (field) => (e) => {
        this.props.editJar({ [field]: e.target.value });
    };

    isCurrentSection(section) {
        return this.props.currentSection === section;
    };

    render() {
        const { jar, onClose } = this.props;

        return (
            <Row className="jar-editor secondary-bg">
                <Col md={12}>
                    <Row className='top-actions tp-15 sp-10'>
                        <Col
                            xs={1}
                            className='text-left clickable'
                            onClick={onClose}>
                            <Icon name="close" />
                        </Col>
                        <Col xs={10}></Col>
                        <Col
                            xs={1}
                            className='text-right clickable'
                            onClick={() => this.props.isSaving ? null : this.props.createJar(jar)}>
                            <Icon name={this.props.isSaving ? "spinner fa-spin" : "check"} />
                        </Col>
                    </Row>

                    <Row>
                        <form action="#">
                            <div className="input-row mb-10">
                                <input
                                    type="text"
                                    value={jar.name || ''}
                                    placeholder="your jar"
                                    onChange={this.handleInput('name')} />
                            </div>

                            <div className="input-row">
                                <textarea
                                    rows="10"
                                    placeholder="note"
                                    value={jar.description || ''}
                                    onChange={this.handleInput('description')}>
                                </textarea>
                            </div>
                        </form>
                    </Row>

                    <MemoryPictures pictures={jar.pictures} />

                    <Row className='text-center tp-5 bp-15'>
                        <Col
                            xs
                            className={textColorClass(hasPictures(jar) ? 'primary' : null, 'clickable')}
                            onClick={() => !this.isCurrentSection('members') && this.props.showSection('members')} >
                            <Icon name='user' />
                        </Col>
                        <Col
                            xs
                            className={textColorClass(hasPictures(jar) ? 'primary' : null, 'clickable')}
                            onClick={() => !this.isCurrentSection('pictures') && this.props.showSection('pictures')} >
                            <Icon name='camera' />
                        </Col>
                        <Col
                            xs
                            className='clickable'
                            onClick={() => !this.isCurrentSection('icons') && this.props.showSection('icons')} >
                            <SingleIcon name={jar.icon || 'bitbucket'} fontSize="18" color={{value: '#ffffff'}} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };
};