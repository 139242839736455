import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';

import Jar from '../../components/jar/single';
import { fetchJars } from '../../modules/jar/reducers/list';

class Picker extends React.Component {
    componentDidMount() {
        this.props.fetchJars();
    };

    handleChange = (jar) => {
        this.props.onUpdate({ jar, jar_id: jar.id });
    };

    render() {
        const { jar } = this.props.data;

        return (
            <Row around="xs" className="jar-picker">
                <Col xs={12}>
                    <h4 className="tp-10 lp-10">
                        Pick A Jar
                        <span className="fr">
                            <i
                                className={`fa fa-times clickable sp-10`}
                                onClick={() => {
                                    this.props.onUpdate({jar: null, jar_id: null});
                                }}></i>
                        </span>
                    </h4>
                </Col>
                {this.props.jars.map(j => (
                    <Col xs={12} key={j.id}>
                        <Jar
                            jar={j}
                            isSelected={jar && jar.id === j.id}
                            onClick={() => this.handleChange(j)} />
                    </Col>
                ))}
            </Row>
        );
    }
};

const mapStateToProps = state => ({
    jars: state.jars.data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchJars
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Picker);