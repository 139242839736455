import { pick } from 'lodash';

import Api from '../../api';

const TokenFormat = [ 
	"access-token", 
	"token-type", 
	"client", 
	"expiry", 
	"uid"
];

export function validateToken () {
	const api = new Api();

	if (!api.headers) {
		return Promise.reject({});
	}

	return api.get('/auth/validate_token').then(({ data, headers }) => {
		if (data.success) {
			api.setAuth(data.data, pick(headers, TokenFormat));
		}

		return { token: headers, user: data.data };
	});
};

export function login (creds) {
	const api = new Api();
	return api.postWithoutAuth('/auth/sign_in', creds).then(({ data, headers }) => {
		api.setAuth(data.data, pick(headers, TokenFormat));
		return { token: headers, user: data.data };
	});
};

export function logout () {
	const api = new Api();
	return api.remove('/auth/sign_out');
};

export function signup (creds) {
	const api = new Api();
	return api.postWithoutAuth('/auth', creds).then(({ data, headers }) => {
		api.setAuth(data, pick(headers, TokenFormat));
		return { token: headers, user: data };
	});
};

